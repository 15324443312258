<template>
  <div :class="cGroupClass">
    <template v-if="editionMode">
      <div>
        <BaseEditLabel :label="label" :required="required" />
        <input
          :id="id"
          :type="type"
          :min="min"
          :max="max"
          :value="modelValue"
          :placeholder="placeholder"
          :name="name"
          :tabindex="tabindex"
          class="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
          @input="updateInput"
        />
      </div>
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="mt-1 text-red-600 text-xs font-light"
        v-html="error"
      />
    </template>

    <template v-else>
      <BaseShowLabel :label="label" :model-value="modelValue" />
    </template>
  </div>
</template>

<script>
import BaseShowLabel from '../BaseShowLabel'
import BaseEditLabel from '../BaseEditLabel'

export default {
  name: 'BaseShowEditInput',
  components: { BaseEditLabel, BaseShowLabel },
  props: {
    name: {
      type: String,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 999999
    },
    parseType: {
      type: String,
      required: false,
      default: 'text',
      validator: function (value) {
        const admittedTypes = ['text', 'int', 'float']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    tabindex: {
      type: Number,
      required: false,
      default: -1
    }
  },
  emits: ['update:modelValue'],
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? 'mt-3 w-full' : this.groupClass
    },
    cInputClass() {
      return this.inputClass === '' ? 'form-control' : this.inputClass
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
