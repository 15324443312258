<template>
  <div class="mt-3">
    <template v-if="editionMode">
      <BaseEditLabel
        :label="label"
        :required="required"
      />
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
          <input
            :value="modelValue"
            :name="name"
            :type="type"
            class="border border-gray-300 focus:border-green-500  block w-full rounded-none rounded-l-md pl-2 sm:text-sm focus:outline-none"
            :placeholder="placeholder"
            :max="max"
            @keydown="isNumber"
            @input="updateInput"
          >
        </div>
        <button
          v-if="isClickable"
          class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm rounded-r-md text-gray-500 bg-gray-50 cursor-pointer"
          @click="buttonFunction"
        >
          <span v-if="secondaryText">{{ secondaryText }}</span>
          <span v-else>
            <font-awesome-icon
              :icon="['fas', icon]"
              size="sm"
            />
          </span>
        </button>
        <button
          v-else
          tabindex="-1"
          class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm rounded-r-md text-gray-500 bg-gray-50 cursor-default"
        >
          <span v-if="secondaryText">{{ secondaryText }}</span>
          <span v-else>
            <font-awesome-icon
              :icon="['fas', icon]"
              size="sm"
            />
          </span>
        </button>
      </div>
    </template>

    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="modelValue"
      />
    </template>
    <p
      v-for="(error,index) in errors"
      :key="index"
      class="mt-1 text-red-600 text-xs font-light"
      v-html="error"
    />
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import BaseEditLabel from "../BaseEditLabel";
import BaseShowLabel from "../BaseShowLabel";
export default {
  name: 'BaseShowEditButtonInput',
  components: { BaseEditLabel, BaseShowLabel },
  props: {
    secondaryText: {type: String, required: false, default: null},
    modelValue: {type: [String, Number], required: true },
    editionMode: { type: Boolean, required: true },
    isClickable: { type: Boolean, required: false, default: false},
    buttonFunction: { type: Function, required: false, default: null },
    icon: { type: String, required: false, default: 'euro' },
    name: { type: String, required: true },
    value: { type: [String, Number], required: false, default: null },
    required: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    errors: { type: Object, required: false, default: null },
    type: { type: String, required: false, default: 'text', validator: function(value) {
        const admittedTypes = ['text', 'email', 'url', 'tel', 'search', 'password', 'textarea', 'number']
        return admittedTypes.indexOf(value) !== -1
      } },
    parseType: { type: String, required: false, default: 'text', validator: function (value) {
        const admittedTypes = ['text','int','float']
        return admittedTypes.indexOf(value) !== -1
      } },
    min: {type: Number, required: false, default: null },
    max: {type: Number, required: false, default: null },
  },
  emits:['update:modelValue'],
  methods:{
    isNumber(event){
      if (this.type === 'number') {
        if (!/\d/.test(event.key) && ![8, 9, 37, 38, 39, 40].includes(event.keyCode)) {
          return event.preventDefault();
        }
      }
    },
    updateInput(event){
      if(this.type === 'number'){
          switch (this.parseType) {
            case 'int':
              this.$emit("update:modelValue", event.target.value !== '' && !isNaN(event.target.value)  ? parseInt(event.target.value) : '');
              break
            case 'float':
              this.$emit("update:modelValue", event.target.value !== '' && !isNaN(event.target.value) ? parseFloat(event.target.value) : '');
              break
            default:
              this.$emit("update:modelValue", this.max && parseInt(this.max) < event.target.value ?  parseInt(this.max) : event.target.value);
          }
      } else {
        this.$emit("update:modelValue", event.target.value);
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
