<template>
  <div
    class="overflow-hidden shadow ring-1 ring-theme-faded ring-opacity-5 sm:mx-6 md:mx-0 md:rounded-md"
  >
    <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 capitalize-first"
          >
            {{ $t('weight') }}
          </th>
          <th
            scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell capitalize-first"
          >
            {{ $t('height') }}
          </th>
          <th
            scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell capitalize-first"
          >
            {{ $t('length') }}
          </th>
          <th
            scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell capitalize-first"
          >
            {{ $t('width') }}
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {{ $t('volume') }}
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-for="(item, index) in parcel" :key="index">
          <td
            class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6"
          >
            {{ `${item.weight} kg` }}
            <dl class="font-normal lg:hidden">
              <dt class="sr-only sm:hidden">
                {{ $t('height') }}
              </dt>
              <dd class="mt-1 truncate text-gray-500 sm:hidden">
                {{ `${item.height} cm (${$t('height')})` }}
              </dd>
              <dt class="sr-only sm:hidden">
                {{ $t('length') }}
              </dt>
              <dd class="mt-1 truncate text-gray-500 sm:hidden">
                {{ `${item.length} cm (${$t('length')})` }}
              </dd>
              <dt class="sr-only sm:hidden">
                {{ $t('width') }}
              </dt>
              <dd class="mt-1 truncate text-gray-500 sm:hidden">
                {{ `${item.width} cm (${$t('width')})` }}
              </dd>
            </dl>
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
            {{ `${item.height} cm` }}
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
            {{ `${item.length} cm` }}
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
            {{ `${item.width} cm` }}
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
            {{ `${item.volume} m3` }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ParcelsTable',
  components: {},
  props: {
    parcel: { type: Array, required: true }
  }
}
</script>

<style scoped></style>
