<template>
  <div class="text-lg font-medium flex-1 text-center sm:text-left">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BasePageTitle'

}
</script>
