<template>
  <nav
    class="flex flex-col items-center justify-center"
    aria-label="Progress"
  >
    <p class="text-sm font-medium text-gray-300 mb-3">
      {{ $t('stepXOnY', {x: $route.params.id, y: steps.length}) }}
    </p>
    <ol
      role="list"
      class="ml-8 flex items-center space-x-5"
    >
      <li
        v-for="(step, index) in steps"
        :key="index"
      >
        <div
          v-if="index+1 < $route.params.id"
          class="block w-2.5 h-2.5 bg-green-600 rounded-full"
        >
          <span class="sr-only">{{ step.name }}</span>
        </div>
        <div
          v-else-if="index+1 === parseInt($route.params.id)"
          class="relative flex items-center justify-center"
          aria-current="step"
        >
          <span
            class="absolute w-5 h-5 p-px flex"
            aria-hidden="true"
          >
            <span class="w-full h-full rounded-full bg-green-600" />
          </span>
          <span
            class="relative block w-2.5 h-2.5 bg-green-600 rounded-full"
            aria-hidden="true"
          />
          <span class="sr-only">{{ step.name }}</span>
        </div>
        <div
          v-else
          class="block w-2.5 h-2.5 bg-gray-200 rounded-full"
        >
          <span class="sr-only">{{ step.name }}</span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "WizardSmallerBeadCrumb",
  data() {
    return {
      steps: [
        {
          name:'pickup_type',
        },
        {
          name:'pickup_address',
        },
        {
          name:'delivery_address',
        },
        {
          name:'service_type',
        },
        {
          name:'parckages',
        },
        {
          name:'dates_options',
        },
        {
          name:'resume',
        },
        {
          name:'payment',
        },
        {
          name:'confirmation',
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>
