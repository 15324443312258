<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3
        class="text-xl leading-6 font-medium text-theme-secondary capitalize-first"
      >
        {{ $t('deliveryType') }}
      </h3>
      <p>
        {{ $t('whatDeliveryType') }}
      </p>
    </div>
    <div
      class="sm:w-3/4 mx-auto flex flex-col gap-6 sm:gap-0 sm:flex-row justify-around"
    >
      <div v-for="(service, index) in metadata.services_list" :key="index">
        <button
          class="bg-theme-primary rounded-lg text-white p-3 w-full sm:w-96 flex flex-row sm:flex-col"
          @click="selectDeliveryType(service.id)"
        >
          <div class="flex-shrink-0 flex justify-center gap-5 w-1/3 sm:w-full">
            <img
              v-if="service.id === 'LP_c2c'"
              alt="LP_c2c"
              :src="require('/src/assets/images/pasdeporte-white.svg')"
            />
            <img
              v-if="service.id === 'LSU_c2c'"
              alt="LSU_c2c"
              :src="require('/src/assets/images/room-white.svg')"
            />
          </div>
          <div class="w-2/3 sm:w-full mt-5 text-center">{{ service.name }}</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ShipmentStepDeliveryType',
  computed: {
    ...mapGetters({
      metadata: 'shipment/getMetadata',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber'
    }),
    userIsComingFromACorrectStep() {
      if (this.previousShipmentStepNumber !== null) {
        if (
          this.previousShipmentStepNumber !== parseInt(this.$route.params.id)
        ) {
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) - 1
          ) {
            return true
          }
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) + 1
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if (this.userIsComingFromACorrectStep) {
      this.processSetPreviousShipmentStepNumberMutation(
        parseInt(this.$route.params.id)
      )
    } else {
      if (this.previousShipmentStepNumber !== this.$route.params.id) {
        this.previousShipmentStepNumber === null
          ? this.$router.push({ name: 'create_shipment_route' })
          : this.$router.push({
              name: '',
              params: { id: this.previousShipmentStepNumber }
            })
      }
    }
  },
  methods: {
    ...mapMutations({
      processSetShipmentServiceCodeMutation: 'shipment/setShipmentServiceCode',
      processSetServiceCodeMutation: 'shipment/setServiceCode',
      processSetPreviousShipmentStepNumberMutation:
        'shipment/setPreviousShipmentStepNumber'
    }),
    selectDeliveryType(service_code) {
      this.processSetShipmentServiceCodeMutation(service_code)
      this.processSetServiceCodeMutation(service_code)
      this.$router.push({
        name: 'create_shipment_step_route',
        params: { id: 5 }
      })
    }
  }
}
</script>
