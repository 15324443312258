<template>
  <WizardFormStepComponent>
    <template #header>
      <!--      <WizardStepBreadCrumb-->
      <!--        v-if="innerWidth > 1040"-->
      <!--        :key="$route.params.id"-->
      <!--        :position="parseInt($route.params.id)"-->
      <!--      />-->
      <WizardSmallerBeadCrumb />
    </template>
    <template #body>
      <div v-if="$route.params.id === '1'">
        <ShipmentStepPickupType />
      </div>
      <div v-if="$route.params.id === '2'">
        <ShipmentStepPartnerPickUpForm v-if="pickupType === 'agency'" />
        <ShipmentStepOtherPickupForm
          v-if="pickupType === 'address'"
          :is-used-as-main-displayed-component="true"
        />
      </div>
      <div v-if="$route.params.id === '3'">
        <ShipmentStepDeliveryForm
          :edition-mode="true"
          :is-used-as-main-displayed-component="true"
        />
      </div>
      <div v-if="$route.params.id === '4'">
        <ShipmentStepDeliveryType />
      </div>
      <div v-if="$route.params.id === '5'">
        <ShipmentStepPackage />
      </div>
      <div v-if="$route.params.id === '6'">
        <ShipmentStepDateOptionForm />
      </div>
      <div v-if="$route.params.id === '7'">
        <ShipmentStepOrderRecap />
      </div>
      <div v-if="$route.params.id === '8'">
        <ShipmentStepPayment />
      </div>
      <div v-if="$route.params.id === '9'">
        <ShipmentStepMessage />
      </div>
    </template>
    <template #footer>
      <div class="flex flex-col sm:flex-row">
        <div class="hidden sm:block flex-shrink-0">
          <BaseButton
            v-if="$route.params.id !== '8'"
            :title="$t('cancel')"
            icon="times"
            color="dark"
            class="col-start-3"
            @click="$router.push({ name: 'homepage_route' })"
          />
        </div>
        <div
          class="flex-1 flex flex-col gap-3 sm:gap-4 sm:flex-row justify-end"
        >
          <span v-if="$route.params.id === '7'" class="flex items-center">
            <label>
              <input
                v-model="cgReadComputed"
                type="checkbox"
                class="focus:ring-0"
              />
              J'ai lu et accepter les
              <a
                href="https://www.my-home-delivery.com/cg/Conditions Générales-C2C-2023.pdf"
                target="_blank"
                class="text-blue-600"
                >conditions générales</a
              >
            </label>
          </span>
          <div class="hidden sm:block">
            <BaseButton
              v-if="!['1', '8'].includes($route.params.id)"
              color="danger"
              :title="$t('back')"
              @click="redirectToPreviousPage"
            />
          </div>

          <BaseButton
            v-if="showingNextButtonCondition"
            color="primary"
            :title="nextButtonTitle"
            :disabled="$route.params.id === '7' && !cgReadComputed"
            @click="redirectToNextPage"
          />
        </div>
        <div class="flex-shrink-0 sm:hidden mt-3 w-full">
          <BaseButton
            v-if="$route.params.id !== '8'"
            :title="$t('cancel')"
            icon="times"
            color="dark"
            scoped-classes="w-full"
            @click="$router.push({ name: 'homepage_route' })"
          />
        </div>
        <div class="sm:hidden w-full mt-3">
          <BaseButton
            v-if="!['1', '8'].includes($route.params.id)"
            color="danger"
            :title="$t('back')"
            scoped-classes="w-full"
            @click="redirectToPreviousPage"
          />
        </div>
      </div>
    </template>
  </WizardFormStepComponent>
</template>
<script>
import WizardFormStepComponent from './WizardFormStepComponent'
// import WizardStepBreadCrumb from "./WizardStepBreadCrumb";

import ShipmentStepPickupType from './steps/ShipmentStepPickupType'
import BaseButton from '../../../components/BaseButton'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ShipmentStepPartnerPickUpForm from './steps/ShipmentStepPartnerPickUpForm'
import ShipmentStepOtherPickupForm from './steps/ShipmentStepOtherPickupForm'
import ShipmentStepDeliveryForm from './steps/ShipmentStepDeliveryForm'
import ShipmentStepDeliveryType from './steps/ShipmentStepDeliveryType'
import ShipmentStepDateOptionForm from './steps/ShipmentStepDateOptionForm'
import ShipmentStepPackage from './steps/ShipmentStepPackage'
import ShipmentStepOrderRecap from './steps/ShipmentStepOrderRecap'
import ShipmentStepPayment from './steps/ShipmentStepPayment'
import ShipmentStepMessage from './steps/ShipmentStepMessage'
import WizardSmallerBeadCrumb from './WizardSmallerBeadCrumb'

export default {
  name: 'ShipmentWizard',
  components: {
    WizardSmallerBeadCrumb,
    ShipmentStepMessage,
    ShipmentStepPayment,
    ShipmentStepOrderRecap,
    ShipmentStepDeliveryType,
    ShipmentStepDeliveryForm,
    ShipmentStepOtherPickupForm,
    ShipmentStepPartnerPickUpForm,
    BaseButton,
    // WizardStepBreadCrumb,
    WizardFormStepComponent,
    ShipmentStepPickupType,
    ShipmentStepDateOptionForm,
    ShipmentStepPackage
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      pickupType: 'shipment/getPickupType',
      serviceCode: 'shipment/getServiceCode',
      dates: 'shipment/getDates',
      isSameDay: 'shipment/getIsSameDay',
      payment: 'payment/getPayment',
      cgReaded: 'shipment/getCgReaded'
    }),
    nextButtonTitle() {
      switch (this.$route.params.id) {
        case '7':
          return this.$t('confirmAndSend')
        case '9':
          return this.$t('backToIndex')
        default:
          return this.$t('next')
      }
    },
    innerWidth() {
      return window.innerWidth
    },
    cgReadComputed: {
      get() {
        return this.cgReaded
      },
      set(newValue) {
        this.processCgReadedMutation(newValue)
      }
    },
    showingNextButtonCondition() {
      switch (this.$route.params.id) {
        case '1':
          return this.pickupType ? 'true' : false
        case '2':
          if (this.shipment.pickupType === 'agency') {
            return (
              this.shipment.pickup_agency_id !== null &&
              this.shipment.brand_id !== null
            )
          } else {
            return true
          }
        case '3':
          return true
        case '4':
          return this.shipment.service_code ? 'true' : false
        case '5':
          if (this.shipment?.parcel?.length > 0) {
            return !!this.shipment.parcel.every(
              this.checkIfEveryValueOfEveryObjectInTheArrayIsNotNullCallBack
            )
          }
          return false
        case '6':
          if (this.dates.one_day) {
            if (this.isSameDay) {
              if (this.isSameDay === 'true') {
                return !!this.shipment.pickup_date
              }
              if (this.isSameDay === 'false') {
                return !!(
                  this.shipment.pickup_date && this.shipment.delivery_date
                )
              }
              return false
            } else {
              return !!(
                this.shipment.pickup_date && this.shipment.delivery_date
              )
            }
          } else {
            return !!(this.shipment.pickup_date && this.shipment.delivery_date)
          }
        case '7':
          return true
        case '8':
          return false
        case '9':
          return true
        default:
          return false
      }
    }
  },
  mounted() {
    // on redirige sur home page si on reload le wizard
    if (
      !this.shipment.type &&
      this.$route.params.id !== '1' &&
      this.$route.params.id !== '8'
    ) {
      this.$router.push({ name: 'homepage_route' })
    }
  },
  methods: {
    ...mapActions({
      processPostShipmentAction: 'shipment/postShipment',
      processPostFormForValidationAction: 'shipment/postFormForValidation',
      processParcelValidationAction: 'shipment/parcelValidation'
    }),
    ...mapMutations({
      processSetShipmentPickupTypeMutation: 'shipment/setShipmentPickupType',
      processSetShipmentServiceCodeMutation: 'shipment/setShipmentServiceCode',
      processResetErrorsMutation: 'auth/resetErrors',
      processCgReadedMutation: 'shipment/setCGReaded'
    }),
    checkIfEveryValueOfEveryObjectInTheArrayIsNotNullCallBack(currentValue) {
      return !Object.values(currentValue).includes(null)
    },
    redirectToPreviousPage() {
      this.$router.push({
        name: '',
        params: { id: parseInt(this.$route.params.id) - 1 }
      })
    },
    redirectToNextPage() {
      this.processResetErrorsMutation()

      if (
        this.$route.params.id === '2' &&
        this.shipment.pickupType === 'address'
      ) {
        return this.processPostFormForValidationAction({
          type: 'pickup',
          shipment: this.shipment,
          successCallback: () => {
            this.$router.push({
              name: '',
              params: { id: parseInt(this.$route.params.id) + 1 }
            })
          }
        })
      }
      if (this.$route.params.id === '3') {
        return this.processPostFormForValidationAction({
          type: 'delivery',
          shipment: this.shipment,
          successCallback: () => {
            this.$router.push({
              name: '',
              params: { id: parseInt(this.$route.params.id) + 1 }
            })
          }
        })
      }

      if (this.$route.params.id === '5') {
        // stepPackage
        return this.processParcelValidationAction({
          shipment: this.shipment,
          successCallback: () => {
            this.$router.push({
              name: '',
              params: { id: parseInt(this.$route.params.id) + 1 }
            })
          }
        })
      }

      if (this.$route.params.id === '7') {
        this.processSetShipmentServiceCodeMutation(this.serviceCode)
        this.processSetShipmentPickupTypeMutation(this.pickupType)
        return this.processPostShipmentAction({
          shipment: this.shipment,
          successCallback: () => {
            this.$router.push({
              name: '',
              params: { id: parseInt(this.$route.params.id) + 1 }
            })
          }
        })
      }
      if (this.$route.params.id === '9') {
        this.$router.push({ name: 'homepage_route' })
      } else {
        this.$router.push({
          name: '',
          params: { id: parseInt(this.$route.params.id) + 1 }
        })
      }
    }
  }
}
</script>
