import { createRouter, createWebHistory } from "vue-router";
import TheHomePage from "../views/homePage/TheHomePage";
import TheAddressPage from "../views/addressesPage/TheAddressPage";
import TheProfilePage from "../views/profilePage/TheProfilePage";
import TheCreateShipmentPage from "../views/createShipment/TheCreateShipmentPage";
import TheShipmentDetailPage from "../views/detailsShipment/TheShipmentDetailPage";
import TheLoginPage from "../views/auth/TheLoginPage";
import TheLayout from "../views/Layout/TheLayout";
import TheAuthLayout from "../views/auth/TheAuthLayout";
import TheRegisterPage from "../views/auth/TheRegisterPage";
import store from "../store/index";
import ShipmentFormStep1Component from "../views/createShipment/form_wizard/ShipmentWizard";
import TheFaqPage from "@/views/faq/TheFaqPage.vue";

const routes = [
  {
    path: "/connect",
    component: TheAuthLayout,
    children: [
      {
        path: "",
        name: "auth_login_route",
        component: TheLoginPage,
      },
      {
        path: "/register",
        name: "auth_register_route",
        component: TheRegisterPage,
        beforeEnter() {
          store.commit("auth/setErrors", {});
        },
      },
    ],
  },
  {
    path: "/",
    component: TheLayout,
    beforeEnter(from, to, next) {
      store.getters["auth/isAuthenticated"]
        ? next()
        : next({ name: "auth_login_route" });
    },
    redirect: "/shipments",
    children: [
      {
        path: "/shipments",
        name: "homepage_route",
        component: TheHomePage,
        // beforeEnter(from, to, next) {
        //   store.commit('shipment/setPreviousShipmentStepNumber', 6)
        //   next({name: 'create_shipment_step_route', params: {id: 7}})
        // },
      },
      {
        path: "/shipments-create",
        name: "create_shipment_route",
        component: TheCreateShipmentPage,
        redirect: { name: "create_shipment_step_route", params: { id: 1 } },
        children: [
          {
            path: "step/:id",
            props: true,
            name: "create_shipment_step_route",
            component: ShipmentFormStep1Component,
          },
        ],
      },
      {
        path: "/shipments/:slug",
        name: "shipment_item_route",
        component: TheShipmentDetailPage,
        children: [],
      },
      {
        path: "/addresses",
        name: "addresses_route",
        component: TheAddressPage,
      },
      {
        path: "/profile",
        name: "profile_route",
        component: TheProfilePage,
      },
      {
        path: "/faq",
        name: "faq_route",
        component: TheFaqPage,
      },
    ],
  },
  {
    path: "/hard_logout",
    component: TheLayout,
    beforeEnter() {
      localStorage.clear();
    },
    redirect: "/connect",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
