/* eslint-disable camelcase */
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            purchase: {
                user_id: null,
                payment_method_id: null,
                amount: null,
                cart: null,
            },
            payment: {},

        }
    },
    actions,
    mutations,
    getters,
}
