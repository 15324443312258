<!--eslint-disable-->
<template>
  <div class="overflow-hidden">    <!--#region top header -->
    <div class="flex justify-end items-center">
      <!--#region searchbar -->
      <BaseSearchInput
        v-if="search"
        @typeInput="filterList"
      />
      <!--#endregion -->

      <!--#region sync button -->
      <BaseButton
        v-if="refreshButton"
        color="secondary"
        :isoutline="true"
        icon="sync"
        :tooltip="$t('button.refresh')"
        scoped-classes="mx-2"
        :title="null"
        @click="$emit('refresh-click')"
      />
      <!--#endregion -->
    </div>

    <!--#endregion -->
      <table class="min-w-full text-sm">
      <!--#region header -->
        <thead class="h-9">
        <tr :class="theadRowClasses">
          <BaseThComponent
            v-for="column in columns"
            :key="column.field"
            :column="column"
            :th-classes="thClasses"
            :current-sort="currentSort"
            @sortColumn="handleSortColumn"
          />
        </tr>
      </thead>
      <!--#endregion -->
      <tbody>
        <tr
          v-for="(model,index) in filteredModels"
          :key="index"
          :class="[trClasses, generateStripedClass(index)]"
          @click="$emit('rowClick', model[redirectToField] )"
        >
          <td
            v-for="(column,index) in columns"
            :key="index"
            class="dark:border-dark-5 px-2"
            :class="tdClasses"
          >
            <DashboardTaskComponent
              v-if="column.type === 'task' && model.work_tasks"
              :column="column"
              :model="model"
            />

            <BaseButton
                v-if="column.type === 'button' && model[redirectToField] !== null && (column.metadata.method !== 'printInvoice' || model['invoice_url'] !== null)"
                :color="column.metadata.type"
                :icon="column.metadata.icon"
                :is-outline="column.metadata.outline"
                :title="column.metadata?.title"
                @click.stop="$emit(column.metadata.method,model[redirectToField])"
            />

            <BaseLabel
              v-if="column.type === 'label'"
              :icon="column.metadata.icon"
              :content="model[column.metadata.value]"
              :color="model[column.metadata.color]"
            />

            <ContentTableCell
              v-else
              :model="model"
              :column="column"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// eslint-disable camelcase
import ContentTableCell from "./ContentTableCell";
import BaseSearchInput from "../../forms/inputs/BaseSearchInput";
import BaseThComponent from "./BaseThComponent";
import BaseLabel from "../Label/BaseLabel";
import DashboardTaskComponent from "./DashboardTaskComponent"
import BaseButton from "../../BaseButton";

export default {
  name: 'BaseContentTable',
  components:{
    DashboardTaskComponent,
    BaseThComponent,
    ContentTableCell,
    BaseSearchInput,
    BaseLabel,
    BaseButton
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    rowClick:{
      type: String,
      required: false,
      default: ''
    },
    redirectToField:{
      type: String,
      required: false,
      default: 'id'
    },
    search:{
      type: Boolean,
      default: false
    },
    refreshButton:{
      type: Boolean,
      default: false
    },
    rows:{ // je pense que cette props n'est pas utilisée
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    modelList:{
      type: Array,
      required: true
    },
    isBordered: {
      type: Boolean,
      required: false,
      default: false
    },
    isHoverable: {
      type: Boolean,
      required: false,
      default: false
    },
    isSmallTable: {
      type: Boolean,
      required: false,
      default: false
    },
    isResponsive: {
      type: Boolean,
      required: false,
      default: false
    },
    isStriped: {
      type: Boolean,
      required: false,
      default: false
    },
    theadRowClasses: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      searchTerms: '',
      sortDirection: null,
      sortColumn: null,
      sortType: null,
      currentSort: '',
    }
  },
  computed: {
    tdClasses() {
      return this.isBordered ? 'border' : 'border-b'
    },
    thClasses() {
      return this.isBordered ? 'border' : ''
    },
    trClasses() {
      return this.isHoverable ? 'hover:bg-gray-200 dark:hover:bg-gray-600' : ''
    },
    tableClasses() {
      return this.isSmallTable ? 'table--sm' : ''
    },

    divClasses() {
      return this.isResponsive ? 'overflow-x-auto' : ''
    },

    filteredModels() {

      let copyModelList = this.modelList;

      if (this.searchTerms.length >= 1) {
        copyModelList = copyModelList.filter((model) => {
          let search = this.searchTerms.length ? this.searchTerms.toLowerCase() : '';
          let values = this.filtrableColumns;
          let flag = false
          values.forEach((val) => {
            if (model[val] && model[val].toLowerCase().indexOf(search.toLowerCase()) > -1) {
              flag = true;
              return;
            }
          })
          if (flag) return model
        });
      }

      if( this.sortDirection && this.sortColumn ){
        copyModelList = copyModelList.sort((a,b) => {
          let fa, fb
          if( this.sortType && this.sortType === 'date' ){
            fa = a[this.sortColumn] ? a[this.sortColumn]['sort_value'] : ( this.sortDirection === 'up' ? '9999999999999999' : ( this.sortDirection === 'down' ? '00000000000000000' : '' ) )
            fb = b[this.sortColumn] ? b[this.sortColumn]['sort_value'] : ( this.sortDirection === 'up' ? '9999999999999999' : ( this.sortDirection === 'down' ? '00000000000000000' : '' ) )
          } else {
            fa = a[this.sortColumn].toLowerCase()
            fb = b[this.sortColumn].toLowerCase()
          }
          if (fa < fb) {
            return this.sortDirection ==='up' ? -1 : 1
          }
          if (fa > fb) {
            return this.sortDirection ==='up' ? 1 : -1
          }
          return 0
        })
      }
      return copyModelList;

    },

    filtrableColumns() {
      return  this.columns/*.filter(function (column){
        return _.has(column,'searchable')
      }).map(obj => {
        return obj.field
      })*/
    },



  },
  methods: {
    generateStripedClass(index) {
      return this.isStriped && index % 2 !== 0 ? 'bg-gray-200 dark:bg-dark-1' : ''
    },
    mRowClick(val){
      if(this.rowClick !== ''){
        this.$emit(this.rowClick,val)
      }
    },
    filterList(terms) {
      this.searchTerms = terms
    },
    handleSortColumn({column,direction, type}){

      this.currentSort = column
      this.sortColumn = column
      this.sortDirection = direction
      this.sortType = type

    },
  }
}
</script>

