<template>
  <template v-if="!registeredConfirmation">
    <div class="bg-white border rounded p-5 sm:my-0 gap-8 flex flex-col">
      <div>
        <img
          class="mx-auto w-auto"
          src="@/assets/images/home_del_logo_300_rgb.png"
          alt="MyHomeDelivery"
        />
      </div>
      <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-x-3">
        <div>
          <BaseShowEditRadioGroup
            v-if="FAKEMetadata.customerType"
            v-model="form.customer_type"
            :label="$t('customerType')"
            name="customer_type"
            :options="FAKEMetadata.customerType"
            :required="true"
            :errors="errors.customer_type"
            :translatable="true"
            track-by="id"
            :edition-mode="true"
            tabindex="1"
          />
        </div>
        <div v-if="form.customer_type === 'business'" class="col-start-1">
          <BaseShowEditInput
            v-model="form.company_name"
            :label="$t('companyName')"
            :required="form.customer_type === 'business'"
            :edition-mode="true"
            :errors="errors?.company_name"
            tabindex="2"
          />
        </div>
        <div v-if="form.customer_type === 'business'">
          <BaseShowEditInput
            v-model="form.tva"
            :label="$t('TVA')"
            :required="form.customer_type === 'business'"
            :edition-mode="true"
            :errors="errors?.tva"
            tabindex="3"
          />
        </div>
        <div :class="form.customer_type !== 'business' ? 'col-start-1' : ''">
          <BaseShowEditInput
            v-model="form.last_name"
            :label="$t('lastName')"
            :required="true"
            :edition-mode="true"
            :errors="errors?.last_name"
            tabindex="4"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.first_name"
            :label="$t('firstName')"
            :required="true"
            :edition-mode="true"
            :errors="errors?.first_name"
            tabindex="5"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.user_name"
            :label="$t('userName')"
            :required="true"
            :edition-mode="true"
            :errors="errors?.user_name"
            tabindex="6"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.email"
            :label="$t('eMail')"
            :required="true"
            :edition-mode="true"
            :errors="errors?.email"
            tabindex="7"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.phone"
            :label="$t('phone')"
            :edition-mode="true"
            :errors="errors?.phone"
            tabindex="8"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.mobile"
            :label="$t('mobile')"
            :required="true"
            :edition-mode="true"
            :errors="errors?.mobile"
            tabindex="9"
          />
        </div>
        <div>
          <BaseShowEditSelectSimple
            v-model="form.langue"
            :label="$t('language')"
            name="langue"
            :edition-mode="true"
            :required="true"
            :options="FAKEMetadata.language"
            :errors="errors?.langue"
            tabindex="10"
          />
        </div>
        <div class="col-start-1">
          <BaseShowEditInput
            v-model="form.password"
            :label="$t('password')"
            type="password"
            :required="true"
            :edition-mode="true"
            :errors="errors?.password"
            tabindex="11"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.password_confirmation"
            :label="$t('confirmPassword')"
            type="password"
            :required="true"
            :edition-mode="true"
            tabindex="12"
          />
        </div>
        <div class="col-span-2">
          <BaseShowEditInput
            v-model="form.street_nb"
            :label="$t('street')"
            :edition-mode="true"
            name="street"
            :errors="errors?.street_nb"
            :required="true"
            tabindex="13"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.postCode"
            :label="$t('postCode')"
            :edition-mode="true"
            name="postCode"
            :errors="errors?.postCode"
            :required="true"
            tabindex="14"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="form.city"
            :label="$t('city')"
            :edition-mode="true"
            name="city"
            :errors="errors?.city"
            :required="true"
            tabindex="15"
          />
        </div>
        <div>
          <BaseShowEditSelectSimple
            v-model="form.country"
            :label="$t('country')"
            name="country_receiver"
            :edition-mode="true"
            :options="[{ id: 'BE', name: 'Belgique' }]"
            :required="true"
            :errors="errors?.country"
            tabindex="16"
          />
        </div>
      </div>
      <div>
        <div>
          <BaseButton
            :title="$t('signUp')"
            scoped-classes="w-full"
            @click="attemptRegister"
          />
        </div>
      </div>

      <div class="flex flex-row text-sm gap-2">
        <div class="flex gap-2">
          <span>{{ $t('already_an_account') }}</span>
          <router-link
            :to="{ name: 'auth_login_route' }"
            class="font-medium text-green-800 hover:text-green-600"
          >
            {{ $t('signIn') }}
          </router-link>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <h1>
      {{ $t('pleaseConfirmYourMail') }}
    </h1>
  </template>
</template>

<script>
/* eslint-disable camelcase */
import { mapActions, mapGetters } from 'vuex'

import BaseButton from '../../components/BaseButton'
import BaseShowEditInput from '../../components/forms/inputs/BaseShowEditInput'
import BaseShowEditSelectSimple from '../../components/forms/selects/simple/BaseShowEditSelectSimple'
import BaseShowEditRadioGroup from '../../components/forms/radio/BaseShowEditRadioGroup'

export default {
  name: 'TheRegisterPage',
  components: {
    BaseButton,
    BaseShowEditInput,
    BaseShowEditSelectSimple,
    BaseShowEditRadioGroup
  },
  data() {
    return {
      FAKEMetadata: {
        language: [
          { name: 'fr', id: 'fr_BE' },
          { name: 'nl', id: 'nl_BE' },
          { name: 'en', id: 'en_UK' }
        ],
        customerType: [
          { name: 'private', id: 'private' },
          { name: 'business', id: 'business' }
        ]
      },
      form: {
        last_name: null,
        first_name: null,
        user_name: null,
        phone: null,
        mobile: null,
        email: null,
        langue: null,
        password: null,
        password_confirmation: null,
        customer_type: null,
        company_name: null,
        TVA: null,
        street_nb: null,
        postCode: null,
        city: null,
        country: 'BE'
      },
      registeredConfirmation: false
    }
  },
  computed: {
    ...mapGetters({ errors: 'auth/getErrors' })
  },
  methods: {
    ...mapActions({ processRegisterAction: 'auth/register' }),
    attemptRegister() {
      let payload_form = { ...this.form }
      this.processRegisterAction({
        form: payload_form,
        successCallback: this.showConfirmationMessage
      })
    },
    showConfirmationMessage() {
      this.registeredConfirmation = true
    }
  }
}
</script>

<style scoped></style>
