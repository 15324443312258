<template>
  <div
    class="flex flex-col gap-4"
  >
    <div>
      <h3 class="text-xl leading-6 font-medium text-theme-secondary capitalize-first">
        {{ $t('packageQuantity') }}
      </h3>
      <p>
        {{ $t('howManyPackage') }}
      </p>
    </div>
  
    <!--#region error -->
    <div
      v-if="errors.total_weight || errors.total_volume"
      class="rounded-md bg-red-50 p-4"
    >
      <div class="flex">
        <div class="flex-shrink-0 text-red-700">
          <font-awesome-icon :icon="['fas','exclamation-circle']" />
        </div>
        <div
          v-for="(reason, index) in errors.total_weight"
          :key="index"
          class="ml-3"
        >
          <p class="text-sm font-medium text-red-800">
            {{ reason }}
          </p>
        </div>
        <div
          v-for="(reason, index) in errors.total_volume"
          :key="index"
          class="ml-3"
        >
          <p class="text-sm font-medium text-red-800">
            {{ reason }}
          </p>
        </div>
      </div>
    </div>
    <!--#endregion -->
    <div>
      <span class="flex flex-wrap overflow-hidden">
        <button
          v-for="(num) in packageNumber"
          :key="num"
          type="button"
          class="w-10 h-10 flex items-center justify-center border border-r-0"
          :class="[ isActive(num) ? 'border-green-600 bg-green-600 text-white' : '', num === 1 ? 'rounded-l-md' : '' ]"
          @click="getPackageNumber(num)"
        >{{ num }}</button>
        <button
          type="button"
          class="w-10 h-10 flex items-center justify-center rounded-r-md border"
          @click="AddPackageNumber"
        > + </button>
      </span>
    </div>
  
  
    <!--    ParcelsCards-->
    <div
      v-if="shipment.parcel && shipment.nb_parcels"
      class="border rounded-md divide-y"
    >
      <ParcelCard
        v-for="(item, index) in shipment.parcel"
        :key="index"
        :package-number="index"
        :parcel="item"
        @deletePackage="deletePackage"
      />
    </div>
    <div class="grid md:grid-cols-5 sm:grid-cols-1">
      <BaseButton
        icon="plus"
        :title="$t('addPackage')"
        @click="AddPackageNumber"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

import ParcelCard from '../../../../components/shipmentStepPackage/ParcelCard'
import BaseButton from "../../../../components/BaseButton";

export default {
  name: "ShipmentStepPackage",
  components: {BaseButton, ParcelCard},
  data() {
    return {
      packageNumber: 9,
      number: 0,
    }
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber',
      errors: 'auth/getErrors',
    }),
    userIsComingFromACorrectStep(){
      if(this.previousShipmentStepNumber !== null){
        if (this.previousShipmentStepNumber !== parseInt(this.$route.params.id)){

          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) - 1)){
            return true
          }

          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) + 1)){
            return true
          }

          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
      if(this.userIsComingFromACorrectStep){
        this.processSetPreviousShipmentStepNumberMutation(parseInt(this.$route.params.id))
      } else {
        if(this.previousShipmentStepNumber !== this.$route.params.id){

          this.previousShipmentStepNumber === null
            ? this.$router.push({name: 'create_shipment_route'})
            : this.$router.push({name: '', params: {id: this.previousShipmentStepNumber}})

        }
      }
  },
  methods: {
    ...mapMutations({
      processPushAParcelInShipmentParcelsMutation: 'shipment/pushAParcelInShipmentParcels',
      processPopAParcelInShipmentParcelsMutation: 'shipment/popAParcelInShipmentParcels',
      processSetPreviousShipmentStepNumberMutation: 'shipment/setPreviousShipmentStepNumber',
    }),
    getPackageNumber(number) {
      if (this.shipment.nb_parcels < 1 || !this.shipment.nb_parcels){
        for(let i = 0; i<number; i++){
          this.processPushAParcelInShipmentParcelsMutation()
        }
      } else {
        if(this.shipment.parcel.length > number){
          let iteration = this.shipment.parcel.length - number
          do {
            this.processPopAParcelInShipmentParcelsMutation()
            this.number = this.shipment.parcel.length
            iteration--
          }while (iteration > 0)
        } else if (this.shipment.parcel.length < number){
          let iteration = number - this.shipment.parcel.length
          do {
            this.processPushAParcelInShipmentParcelsMutation()
            this.number = this.shipment.parcel.length
            iteration--
          }while (iteration > 0)
        }
      }
    },
    AddPackageNumber() {
      if(this.shipment['nb_parcels'] === this.packageNumber) {
        ++this.packageNumber
      }
      this.processPushAParcelInShipmentParcelsMutation()
    },
    isActive(num) {
        return num === this.shipment['nb_parcels']
    },
    deletePackage(parcelIndex) {
       this.shipment?.parcel?.splice(parcelIndex, 1)
        --this.shipment['nb_parcels']
    },
  }
}
</script>

