export default {
    isAuthenticated(state, getters) {
        if (!state.auth || !state.token || !getters.isSessionStillValid) {
            return false
        }
        return state.auth && state.token && getters.isSessionStillValid
    },
    getToken(state){
        return state.token
    },
    getExpirationDate(state){
        return state.expirationDate
    },
    getAuth(state){
        return state.auth
    },
    getMetadata(state){
        return state.metadata
    },
    getErrors(state){
        return state.errors
    },
    isSessionStillValid(state) {
        return Date.now() < (new Date(state.expirationDate)).getTime()
    }
}
