<template>
  <div class="p-4 flex flex-col gap-4">
    <h3
      class="text-2xl leading-6 font-medium text-theme-secondary capitalize-first"
    >
      {{ $t('delivery') }} - {{ shipment.delivery_date }}
    </h3>
    <div class="flex flex-col gap-4">
      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
        <div>
          <BaseShowLabel
            :label="$t('name')"
            :model-value="shipment.name_receiver"
          />
        </div>
      </div>

      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
        <div class="col-start-1 sm:col-span-2">
          <BaseShowLabel
            :label="$t('street')"
            :model-value="shipment.street_receiver"
          />
        </div>
        <div class="col-start-1">
          <BaseShowLabel
            :label="$t('postCode')"
            :model-value="shipment.post_code_receiver"
          />
        </div>
        <div>
          <BaseShowLabel
            :label="$t('city')"
            :model-value="shipment.city_receiver"
          />
        </div>
        <div>
          <BaseShowLabel
            :label="$t('language')"
            :model-value="shipment.language_receiver"
          />
        </div>
        <div>
          <BaseShowLabel
            :label="$t('country')"
            :model-value="shipment.country_receiver"
          />
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
        <div class="col-start-1">
          <BaseShowLabel
            :label="$t('phone')"
            :model-value="shipment.phone_receiver"
          />
        </div>
        <div>
          <BaseShowLabel
            :label="$t('mobile')"
            :model-value="shipment.mobile_receiver"
          />
        </div>
        <div>
          <BaseShowLabel
            :label="$t('eMail')"
            :model-value="shipment.email_receiver"
          />
        </div>
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
        <div class="col-start-1">
          <BaseShowLabel
            :label="$t('number')"
            :model-value="shipment.internal_num"
          />
        </div>
        <div>
          <BaseShowLabel
            :label="$t('reference')"
            :model-value="shipment.customer_num_receiver"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseShowLabel from '../../components/forms/BaseShowLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'ShipmentDetailDeliveryCard',
  components: { BaseShowLabel },
  computed: {
    ...mapGetters({ shipment: 'shipment/getShipment' })
  }
}
</script>
