<template>
  <router-view />
</template>


<script>

export default {
  mounted() {
    console.log("%c✘ Env! " +process.env.NODE_ENV, "color: #fd3995");
    console.log("%c✘ App! " +process.env.VUE_APP_NAME, "color: #83CC14");
  }
}
</script>
