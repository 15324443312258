<template>
  <h3
    v-if="!!dates.one_day"
    class="mb-5 text-lg leading-6 font-medium text-theme-secondary capitalize-first"
  >
    {{ $t('pickupAndDeliveryDate') }}
  </h3>
  <div v-if="dates" class="grid md:grid-cols-2 sm:grid-cols-1 gap-x-5 gap-y-8">
    <div v-if="dates.one_day === true" class="col-start-1 md:col-span-2">
      <BaseShowEditRadioGroup
        v-model="dataIsSameDay"
        name="job_type"
        :options="daysOptions"
        track-by="id"
        attribute-label="name"
        :edition-mode="true"
        :is-bordered="true"
        @change="resetDeliveryDate"
      />

      <div v-if="dataIsSameDay === 'true'" class="mt-8">
        <BaseShowEditRadioGroup
          v-model="shipment.pickup_date"
          name="ah"
          :options="dates.pickup"
          :errors="errors.pickup_date"
          :translatable="true"
          track-by="date"
          attribute-label="date"
          :is-vertical="true"
          :edition-mode="true"
          @change="resetDeliveryDate"
        />
      </div>
      <div
        v-if="dataIsSameDay === 'false'"
        class="mt-8 grid md:grid-cols-2 sm:grid-cols-1 gap-x-5"
      >
        <div class="grid col-start-1">
          <BaseShowEditRadioGroup
            v-if="dates"
            v-model="shipment.pickup_date"
            :label="$t('pickupDate')"
            name="ah"
            :options="dates.pickup"
            :errors="errors.pickup_date"
            :translatable="true"
            track-by="date"
            attribute-label="date"
            :is-vertical="true"
            :edition-mode="true"
            @change="resetDeliveryDate"
          />
        </div>
        <div class="grid md:col-start-2 sm:col-start-1">
          <BaseShowEditRadioGroup
            v-model="shipment.delivery_date"
            :label="$t('deliveryDate')"
            name="oh"
            :options="cDeliveryOptions"
            :errors="errors.delivery_date"
            :translatable="true"
            track-by="date"
            attribute-label="date"
            :is-vertical="true"
            :edition-mode="true"
          />
        </div>
      </div>
    </div>

    <div v-else>
      <h3
        class="mb-5 text-lg leading-6 font-medium text-theme-secondary capitalize-first"
      >
        {{ $t('deliveryDifferentDay') }}
      </h3>

      <div class="mt-8 grid md:grid-cols-2 sm:grid-cols-1 gap-x-5">
        <div class="grid col-start-1">
          <BaseShowEditRadioGroup
            v-if="dates"
            v-model="shipment.pickup_date"
            :label="$t('pickupDate')"
            name="ah"
            :options="dates.pickup"
            :errors="errors.pickup_date"
            :translatable="true"
            track-by="date"
            attribute-label="date"
            :is-vertical="true"
            :edition-mode="true"
            @change="resetDeliveryDate"
          />
        </div>
        <div class="grid md:col-start-2 sm:col-start-1">
          <BaseShowEditRadioGroup
            v-model="shipment.delivery_date"
            :label="$t('deliveryDate')"
            name="oh"
            :options="cDeliveryOptions"
            :errors="errors.delivery_date"
            :translatable="true"
            track-by="date"
            attribute-label="date"
            :is-vertical="true"
            :edition-mode="true"
          />
        </div>
      </div>
    </div>
    <div v-if="metadata.options.length > 0" class="grid col-start-1 md:pt-8">
      <BaseShowEditCheckbox
        v-model:modelValue="shipment.options"
        :label="$t('options')"
        name="option"
        :options="metadata?.options"
        track-by="id"
        attribute-label="name"
        :edition-mode="true"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BaseShowEditRadioGroup from '../../../../components/forms/radio/BaseShowEditRadioGroup'
import BaseShowEditCheckbox from '../../../../components/forms/checkbox/BaseShowEditCheckbox'

export default {
  name: 'ShipmentStepDateOptionForm',
  components: { BaseShowEditRadioGroup, BaseShowEditCheckbox },
  data() {
    return {
      dataIsSameDay: null,
      daysOptions: [
        { id: 'true', name: this.$t('deliverySameDay') },
        { id: 'false', name: this.$t('deliveryDifferentDay') }
      ]
    }
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getMetadata',
      errors: 'auth/getErrors',
      dates: 'shipment/getDates',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber',
      isSameDay: 'shipment/getIsSameDay'
    }),
    cDeliveryOptions() {
      if (!this.dates) {
        return []
      } else {
        if (!this.shipment.pickup_date) {
          return []
        } else {
          return this.dates?.delivery['j+x'][this.shipment.pickup_date]
        }
      }
    },
    userIsComingFromACorrectStep() {
      if (this.previousShipmentStepNumber !== null) {
        if (
          this.previousShipmentStepNumber !== parseInt(this.$route.params.id)
        ) {
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) - 1
          ) {
            return true
          }
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) + 1
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  watch: {
    dataIsSameDay(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.processSetIsSameDayMutation(newValue)
      }
    }
  },
  mounted() {
    if (this.userIsComingFromACorrectStep) {
      this.dataIsSameDay = this.isSameDay
      this.processSetPreviousShipmentStepNumberMutation(
        parseInt(this.$route.params.id)
      )
      this.processFetchDatesAction({ shipment: this.shipment })
    } else {
      if (this.previousShipmentStepNumber !== this.$route.params.id) {
        this.previousShipmentStepNumber === null
          ? this.$router.push({ name: 'create_shipment_route' })
          : this.$router.push({
              name: '',
              params: { id: this.previousShipmentStepNumber }
            })
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchDatesAction: 'shipment/fetchDates'
    }),
    ...mapMutations({
      processSetPreviousShipmentStepNumberMutation:
        'shipment/setPreviousShipmentStepNumber',
      processSetIsSameDayMutation: 'shipment/setIsSameDay'
    }),
    resetDeliveryDate() {
      this.shipment.delivery_date = null
    }
  }
}
</script>
