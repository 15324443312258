<template>
  <BasePageFull>
    <template #cta>
      <div class="grid-cols-3" />
    </template>
    <template #body>
      <div class="home flex-1 flex flex-col min-h-0">
        <router-view class="overflow-visible" />
      </div>
    </template>
  </BasePageFull>
</template>

<script>
/* eslint-disable no-unused-vars */
import BasePageFull from '../../components/baseComponents/BasePageFull'
import store from '../../store/index'

export default {
  name: 'TheCreateShipmentPage',
  components: {
    BasePageFull
  },
  beforeRouteEnter(to, from, next) {
    let prev = store.getters['shipment/getPreviousShipmentStepNumber']
    if (
      from.name === 'shipment_item_route' &&
      to.name === 'create_shipment_step_route' &&
      parseInt(to.params.id) === 8
    ) {
      next()
    } else if (
      to.name === 'create_shipment_step_route' &&
      parseInt(to.params.id) !== prev
    ) {
      if (prev === 0) {
        next()
      }
      next({
        name: 'create_shipment_step_route',
        params: { id: prev },
        replace: true
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped></style>
