import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faSearch,
  faSync,
  faExclamationCircle,
  faCheckCircle,
  faArrowRight,
  faArrowLeft,
  faBiohazard,
  faCamera,
  faCar,
  faCheck,
  faCheckSquare,
  faChevronRight,
  faCircle,
  faCoffinCross,
  faDirections,
  faEnvelope,
  faExclamationSquare,
  faFileCsv,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faFileWord,
  faFilter,
  faFlower,
  faHourglass,
  faImage,
  faInfo,
  faMap,
  faPaperclip,
  faPen,
  faPhone,
  faPlus,
  faStream,
  faTimes,
  faUser,
  faUsers,
  faUserTag,
  faLongArrowLeft,
  faHandPointer
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCartShopping as faCartShoppingLight,
  faHouseUser as faHouseUserLight,
  faBedFront as faBedFrontLight,
  faArrowRightFromBracket as faArrowRightFromBracketLight
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faSearch,
  faHandPointer,
  faExclamationCircle,
  faLongArrowLeft,
  faArrowRight,
  faArrowLeft,
  faBiohazard,
  faCamera,
  faCar,
  faCheck,
  faCheckSquare,
  faChevronRight,
  faCircle,
  faCoffinCross,
  faDirections,
  faEnvelope,
  faExclamationSquare,
  faFileCsv,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faFileWord,
  faFilter,
  faFlower,
  faHourglass,
  faImage,
  faInfo,
  faMap,
  faPaperclip,
  faPen,
  faPhone,
  faPlus,
  faStream,
  faTimes,
  faUser,
  faUsers,
  faUserTag,
  faSync,
  faCheckCircle,
  faCartShoppingLight,
  faHouseUserLight,
  faBedFrontLight,
  faArrowRightFromBracketLight
)

export default (app) => {
  // eslint-disable-next-line vue/component-definition-name-casing
  app.component('font-awesome-icon', FontAwesomeIcon)
}
