<template>
  <div
    class="p-4 bg-white overflow-hidden shadow rounded-lg flex flex-col gap-4"
  >
    <div class="flex-shrink-0 flex flex-row">
      <h3
        class="flex-1 text-2xl leading-6 font-medium text-theme-secondary capitalize-first"
      >
        {{ $t('deliveryServices') }}
      </h3>
      <div class="flex-shrink-0">
        <BaseButton
          :title="$t('back')"
          icon="arrow-left"
          color="dark"
          @click="$router.push({ name: 'homepage_route' })"
        />
      </div>
    </div>

    <div class="grid md:grid-cols-4 grid-cols-2 gap-3">
      <div class="col-span-2 sm:col-span-2">
        <BaseShowLabel
          :label="$t('service')"
          :model-value="shipment.service_title"
        />
      </div>

      <div class="col-span-2">
        <BaseShowLabel :label="$t('price')" :model-value="shipment.price" />
      </div>

      <div class="col-start-1 col-span-2 sm:col-span-4">
        <BaseShowLabel
          :label="$t('instructions')"
          :model-value="shipment.delivery_instructions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BaseShowLabel from '../../components/forms/BaseShowLabel'
import { mapGetters } from 'vuex'
import BaseButton from '../../components/BaseButton'

export default {
  name: 'ShipmentDetailServiceCard',
  components: { BaseButton, BaseShowLabel },
  computed: {
    ...mapGetters({ shipment: 'shipment/getShipment' })
  }
}
</script>
