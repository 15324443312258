import apiClient from '../../../utils/apiClient'

export default {

    async CreatingPaymentIntent({commit}, payload){
        console.log('action CreatingPaymentIntent called')
        await apiClient.post(`api/c2c/create-payment-intent`, payload)
            .then(async response => {
                await console.log('setPayment', response.data.payment_intent)
               await commit('setPayment',response.data.payment)
            })
            .catch(async reason => {
                await console.error('purchase', reason)
            })
    } ,
    // eslint-disable-next-line no-unused-vars
    async createPaymentIntentForPaymentReattempt({commit}, payload) {
        console.log('createPaymentIntentForPaymentReattempt payload', payload)
        await apiClient.get(`api/c2c/shipment/payment-reattempt/${payload.slug}`)
            .then(async response => {
                console.log('createPaymentIntentForPaymentReattempt', response)
                if (response.status === 200) {
                    await commit('payment/setPayment', response.data.payment_intent, {root: true})
                }
            })
            .catch(async reason => {
                await console.error('createPaymentIntentForPaymentReattempt', reason)
            })
    },
    async purchase({commit}, payload){
        console.log('action purchase called')
        await apiClient.post(`api/c2c/purchase`, payload)
            .then(async response => {
                await console.log('purchase', response)
                commit('setErrors', 'wow', {root: true})
            })
            .catch(async reason => {
                await console.error('purchase', reason)
            })
    }
}
