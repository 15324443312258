<template>
  <label v-if="label">
    <p :class="cLabelClass" class="truncate">
      {{ label }}
      <span v-if="required" class="text-red-500 capitalize-first">*</span>
    </p>
  </label>
</template>
<script>
export default {
  name: 'BaseEditLabel',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    cLabelClass() {
      return this.labelClass === ''
        ? 'pl-1 text-sm text-gray-600 capitalize-first'
        : this.labelClass
    }
  }
}
</script>
