import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            token:null,
            expirationDate:null,
            auth: {},
            metadata: {},
            errors: {},
        }
    },
    actions,
    mutations,
    getters,
}
