<template>
  <div class="p-4 bg-white flex flex-col gap-4">
    <h3
      class="text-2xl leading-6 font-medium text-theme-secondary capitalize-first"
    >
      {{ $t('collecte') }} - {{ shipment.pickup_date }}
    </h3>
    <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
      <div>
        <BaseShowLabel
          :label="$t('name')"
          :model-value="shipment.pickup_name"
        />
      </div>
    </div>
    <div class="grid sm:grid-cols-2 grid-cols-1 gap-3">
      <div class="col-start-1 sm:col-span-2">
        <BaseShowLabel
          :label="$t('street')"
          :model-value="shipment.pickup_street"
        />
      </div>

      <div>
        <BaseShowLabel
          :label="$t('postCode')"
          :model-value="shipment.pickup_post_code"
        />
      </div>

      <div>
        <BaseShowLabel :label="$t('country')" :model-value="countryValue" />
      </div>
    </div>
  </div>
</template>
<script>
import BaseShowLabel from '../../components/forms/BaseShowLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'ShipmentDetailPickupCard',
  components: { BaseShowLabel },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getMetadata'
    }),
    countryValue() {
      return (
        this.metadata?.countries_pickup?.find(
          (country) => country.id === this.shipment?.pickup_country
        )?.name || null
      )
    }
  }
}
</script>
