<template>
  <div class="h-full flex flex-col gap-8 overflow-scroll">
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">WIE IS MY HOME DELIVERY ?</h2>
      <p class="text-sm">
        My Home Delivery is een bedrijf gespecialiseerd in het bezorgen van
        zware en/of omvangrijke pakketten aan particulieren.<br />
        In 2022 besloot My Home Delivery om deze dienst open te stellen voor
        particulieren, d.w.z. dat iedereen zijn goederen in de winkel of bij een
        particulier kan ophalen en laten bezorgen.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        HOE REGISTREER IK OP MY HOME DELIVERY ?
      </h2>
      <p class="text-sm">
        Ga naar
        <a href="https://particulieren.my-home-delivery.com/" target="_blank"
          >particuliers.my-home-delivery.com</a
        >
        en volg de instructies om te registreren.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">HOE EEN PAKKET VERZENDEN?</h2>
      <p class="text-sm">
        Volg na registratie volgende stappen :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;&nbsp;Ophaaladres<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;Afleveradres<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;&nbsp;Aantal , gewicht &
        afmetingen van pakketten<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;&nbsp;Datum van ophaling<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;&nbsp;Datum van levering<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.&nbsp;&nbsp;Ga verder met de betaling<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.&nbsp;&nbsp;Prima! U kunt de etiket(ten)
        afdrukken en de verpakking(en) voorbereiden. Denk eraan om (+ link naar
        veelgestelde vragen over verpakkingen) uw pakket correct in te pakken
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">WAT ZIJN DE VERZENDKOSTEN?</h2>
      <p class="text-sm">
        De prijs wordt bepaald door :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;De gekozen bezorgservice (aan
        de deur of in de gewenste kamer)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;De dag en afstand van
        afhaal-bezorging: Levering dezelfde dag binnen 30 km is goedkoper
      </p>
      <div>
        <table class="bg-white w-full border-gray-500 rounded table-auto">
          <tr>
            <th class="border border-gray-200 p-2" colspan="2">
              Type bezorgdienst
            </th>
            <th class="border border-gray-200 p-2" colspan="2">België</th>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2"></td>
            <td class="border border-gray-200 p-2">max. verzendgewicht</td>
            <td class="border border-gray-200 p-2">minder dan 30km</td>
            <td class="border border-gray-200 p-2">meer dan 30 km</td>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2">aan de deur</td>
            <td class="border border-gray-200 p-2">0-100kg</td>
            <td class="border border-gray-200 p-2 text-right">55 €</td>
            <td class="border border-gray-200 p-2 text-right">75 €</td>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2">in de gewenste kamer</td>
            <td class="border border-gray-200 p-2">0-100kg</td>
            <td class="border border-gray-200 p-2 text-right">65 €</td>
            <td class="border border-gray-200 p-2 text-right">85 €</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WELKE SOORTEN ARTIKELEN KUNNEN BEHANDELD WORDEN DOOR MY HOME DELIVERY ?
      </h2>
      <p class="text-sm">
        Dit zijn <u>gewone goederen</u>: voorbeelden: huishoudelijke apparaten,
        beddengoed, binnen- en buitenmeubels, fietsen, tuingereedschap,
        decoratieve artikelen, enz. <br />Wij leveren bijvoorbeeld geen piano en
        wij zijn ook geen verhuizers (wij leveren niet met lift; als er een lift
        nodig is, dient u daar zelf voor te zorgen). <br />Let op, sommige
        artikelen zijn ook verboden, zie onze Algemene Voorwaarden (+ link) voor
        meer details. Een vraag, een twijfel? Neem contact met ons op (+
        e-maillink) om er zeker van te zijn voordat u uw levering codeert.<br />
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WAT ZIJN DE TOEGESTANE GEWICHTEN EN AFMETINGEN ?
      </h2>
      <p class="text-sm">
        Aanvaarde leveringen moeten aan de volgende criteria voldoen :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Totaal maximaal gewicht: 100
        kg<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Maximale lengte per pakket:
        350 cm<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Totaal maximaal volume: 2 m³
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        HOE MIJN PAKKET VERPAKKEN ? <br />ZIJN ER INSTRUCTIES OM HET PAKKET TE
        VERZENDEN ?
      </h2>
      <p class="text-sm">
        My Home Delivery zorgt niet voor het verpakken van de pakketten die je
        via onze diensten wilt afhalen/bezorgen.<br />
        De afzender (+ info) is verantwoordelijk voor het verpakken van het/de
        af te halen/bezorgen pakket(ten).<br /><br />
        LET OP ! Het is verplicht om de goederen correct in te pakken (zie
        aanbevelingen) en het etiket(ten) aan te brengen: 1 etiket per pakket.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WELKE DIENSTEN WORDEN AANGEBODEN DOOR MY HOME DELIVERY ?
      </h2>
      <p class="text-sm">
        My Home Delivery biedt 2 bezorgformules aan:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Levering "Doorstep"
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Levering op de gewenste
        kamer<br />
        WAARSCHUWING; de afzender moet ervoor zorgen dat de goederen verenigbaar
        zijn met het gebruik waarvoor zij bestemd zijn, dat zij veilig bij de
        ontvanger kunnen worden afgeleverd en dat de toegangen breed genoeg zijn
        en voldoende hoogte bieden om de levering normaal en naar behoren te
        laten plaatsvinden. Oplevering zonder lift: maximaal 3e verdieping.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        TOT WELKE VERDIEPING LEVERT U?
      </h2>
      <p class="text-sm">
        Als de pakket(ten) de lift ingaan, is er geen vloerlimiet.<br />
        Anders leveren we tot maximaal de <u>3e verdieping</u> zonder lift, met
        een <u>maximum van 2 pakketten en maximaal 60 kg per pakket</u>. En dit,
        zolang de toegangen breed genoeg zijn en voldoende hoogte bieden om de
        levering normaal en goed te laten plaatsvinden.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WELKE LANDEN WORDEN AANGEBODEN DOOR MY HOME DELIVERY ?
      </h2>
      <p class="text-sm">
        Onze bezorgdiensten zijn voorbehouden voor afhalingen en leveringen in
        België.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WELKE WIJZE VAN BETALING BIJ MY HOME DELIVERY ?
      </h2>
      <p class="text-sm">Bancontact, Visa, MasterCard</p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        MIJN PAKKET IS GELEVERD MAAR HET IS BESCHADIGD. WAT NU ?
      </h2>
      <p class="text-sm">
        Er zijn 2 soorten procesvoeringen die worden ondersteund door (My) Home
        Delivery :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Niet-ontdekt pakket
        (verloren)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Pakket beschadigd tijdens de
        bezorging<br /><br />
        Elk van deze gevallen moet, zo snel mogelijk en binnen de 24 uur (1
        werkdag) na levering aan de ontvanger, het voorwerp uitmaken van een
        procesvoering via ons Klantendienst.<br /><br />
        Voor elk geval is de compensatieprocedure als volgt :<br />
        <b>• Niet-ontdekt pakket (verloren)</b><br />
        Ondersteuning van de procesvoering na een onderzoek van 15 werkdagen<br /><br />

        <b>• Pakket beschadigd tijdens de bezorging</b><br />
        Voor de behandeling van de procesvoering is het noodzakelijk dat de
        ontvanger op het moment van levering reserveringen heeft vermeld op het
        ontvangstbewijs (afleveringsbewijs of aanvraag van de
        bestuurder/bezorger).<br />Deze reserveringen moeten nauwkeurig en
        concreet zijn, idealiter met ondersteunende foto’s.<br /><br />

        <u>Onbeperkte lijst van niet-aanvaarde procesvoeringen </u><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Beschadigde producten die
        verpakt maar niet zichtbaar zijn bij ontvangst of levering (verpakking
        intact buitenaf) worden niet ondersteund.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Wanneer de reservering
        vermeldt “onder voorbehoud van uitpakken”<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Wanneer de verpakking of het
        type vervoerd product niet voldoet aan specificities die zijn
        ondertekend in het contract voor het verlenen van diensten.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;…<br /><br />

        <u>Vergoedingsbedrag :</u><br />
        Het bedrag dat wordt vergoed is beperkt tot vijf (5) euros per kg
        beschadigd of verloren brutogewicht, met een maximum van vijftig (50)
        euros per gebeurtenis of per reeks van gebeurtenissen met één enkele
        schadeoorzaak.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqNl'
}
</script>

<style scoped></style>
