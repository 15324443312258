<template>
  <div :class="cGroupClass">
    <template v-if="editionMode">
      <BaseEditLabel :label="label" :required="required" />
      <textarea
        :id="id"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="isDisabled"
        class="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
        :tabindex="tabindex"
        @input="updateInput"
      />
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="mt-1 text-red-600 text-xs font-light"
        v-html="error"
      />
    </template>

    <template v-else>
      <BaseShowLabel v-if="!isHtml" :label="label" :model-value="modelValue" />

      <template v-else>
        <label v-if="label">
          <p class="text-sm font-medium text-gray-400 capitalize-first">
            {{ label }}
          </p>
        </label>
        <dd class="mt-1 text-sm text-gray-900" v-html="modelValue" />
      </template>
    </template>
  </div>
</template>

<script>
import BaseShowLabel from '../BaseShowLabel'
import BaseEditLabel from '../BaseEditLabel'

export default {
  name: 'BaseShowEditTextArea',
  components: { BaseShowLabel, BaseEditLabel },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: -1
    }
  },
  emits: ['update:modelValue'],
  computed: {
    cGroupClass() {
      return this.groupClass === '' ? 'mt-3 w-full' : this.groupClass
    },
    isDisabled() {
      return this.disabled
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
