<!-- eslint-disable -->
<template>
  <h3
    class="mb-5 text-lg leading-6 font-medium text-theme-secondary capitalize-first"
  >
    {{ $t('processPayment') }}
  </h3>
  <!--#region errors.error -->
  <div v-if="errors.message" class="rounded-md bg-red-50 p-4 mb-8">
    <div class="flex">
      <div class="flex-shrink-0 text-red-700">
        <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          {{ errors.message }}
        </h3>
      </div>
    </div>
  </div>
  <!--#endregion -->

  <div class="grid md:grid-cols-5 sm:grid-cols-1">
    <div
      class="col-start-2 col-span-3 border rounded-md p-5 flex flex-col gap-3"
    >
      <div>
        <div id="card-element" class="pt-0" />
      </div>
      <div class="flex-shrink-0 flex flex-row justify-end">
        <BaseButton
          v-if="!clicked"
          :title="buttonTitle"
          @click="processPayment"
        />
        <button
          type="button"
          class="w-full flex text-white justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none justify-center items-center gap-x-3 bg-theme-primary"
          disabled
          v-else
        >
          <div class="flex flex-row">
            <span> Processing...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable */
import { loadStripe } from '@stripe/stripe-js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import BaseButton from '../../../../components/BaseButton'
import BaseEditLabel from '../../../../components/forms/BaseEditLabel'
import BaseShowLabel from '../../../../components/forms/BaseShowLabel'
import parcelsTable from '../../../../components/shipmentOrderRecap/ParcelsTable'

export default {
  name: 'ShipmentStepPayment',
  components: {
    BaseShowLabel,
    BaseEditLabel,
    BaseButton,
    parcelsTable
  },
  data() {
    return {
      clicked: false,
      stripe: {},
      elements: {},
      cardElement: {}
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      errors: 'auth/getErrors',
      shipment: 'shipment/getShipment',
      purchase: 'payment/getPurchase',
      payment: 'payment/getPayment',
      paymentReattempt: 'shipment/getPaymentReattempt'
    }),
    receiverAddress() {
      return `${this.shipment.street_receiver}, ${this.shipment.post_code_receiver} ${this.shipment.city_receiver}`
    },
    returnUrl() {
      let url = window.location.href
      return url.slice(0, -1) + '9'
    },
    buttonTitle() {
      return this.$t('payAmount', { amount: this.shipment.price })
    }
  },
  async beforeMount() {
    if (this.paymentReattempt) {
      this.processCreatePaymentIntentForPaymentReattemptAction({
        slug: this.shipment.slug
      }).then(async () => {
        await this.mountStripe()
      })
    }
  },
  async mounted() {
    if (!this.paymentReattempt) {
      await this.mountStripe()
    }
  },
  methods: {
    ...mapActions({
      processPurchaseAction: 'payment/purchase',
      processCreatingPaymentIntentAction: 'payment/CreatingPaymentIntent',
      processCreatePaymentIntentForPaymentReattemptAction:
        'payment/createPaymentIntentForPaymentReattempt'
    }),
    ...mapMutations({
      processSetErrorsMutation: 'auth/setErrors',
      processResetErrorsMutation: 'auth/resetErrors',
      processSetPaymentMethodIdMutation: 'payment/setPaymentMethodId',
      processSetAmountMutation: 'payment/setAmount',
      processSetCartMutation: 'payment/setCart',
      processSetUserIdMutation: 'payment/setUserId',
      processSetPreviousShipmentStepNumberMutation:
        'shipment/setPreviousShipmentStepNumber'
    }),
    async mountStripe() {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_SECRET)
      const appearance = {
        theme: 'stripe'
      }
      if (this.payment) {
        this.elements = this.stripe.elements({
          clientSecret: this.payment.client_secret,
          appearance
        })
        this.cardElement = this.elements.create('payment', {
          business: {
            name: 'My Home Delivery'
          }
          // terms: {
          //   card: 'never',
          //   bancontact: 'never',
          //
          // },
        })
        this.cardElement.mount('#card-element')
      }
    },
    async processPayment() {
      this.clicked = true
      this.processSetPreviousShipmentStepNumberMutation(9)
      // this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_SECRET)
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // return_url: 'https://example.com',
          return_url: this.returnUrl
          // shipping: {
          //   address: this.receiverAddress,
          //   name: this.shipment.name_receiver,
          //   carrier: 'MyHomeDelivery',
          //   phone: this.shipment.mobile_receiver || this.shipment.phone_receiver,
          // }
        }
      })

      console.log('error', error)
    }
  }
}
</script>

<style>
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 10px auto;
  position: relative;
  /*text-indent: -9999em;*/
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
</style>
