<template>
  <nav class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex h-16">
        <div class="flex w-full">
          <div
            class="flex-shrink-0 flex items-center cursor-pointer"
            @click="$router.push({ name: 'homepage_route' })"
          >
            <img
              class="block w-auto"
              src="@/assets/images/home_del_logo_300_rgb.png"
              alt="logo"
              style="max-height: 80px; margin-top: -5px"
            />
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <router-link
              v-for="(item, index) in menuItems"
              :key="index"
              :to="{ name: item.routeName }"
              :class="
                $route.name === item.routeName
                  ? 'border-green-700 text-theme-primary inline-flex items-center px-1 pt-1 border-b-2  font-medium capitalize '
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 font-medium capitalize'
              "
            >
              {{ $t(item.title) }}
            </router-link>
          </div>
          <div
            class="hidden sm:ml-6 sm:flex sm:space-x-8 w-full justify-end items-center"
          >
            <div>
              <div
                class="border rounded overflow-hidden flex flex-row divide-x text-sm"
              >
                <button
                  class="px-2 py-1"
                  :class="[$i18n.locale === 'fr_BE' ? 'bg-theme-primary' : '']"
                  @click="$i18n.locale = 'fr_BE'"
                >
                  FR
                </button>
                <button
                  class="px-2 py-1"
                  :class="[$i18n.locale === 'nl_BE' ? 'bg-theme-primary' : '']"
                  @click="$i18n.locale = 'nl_BE'"
                >
                  NL
                </button>
                <button
                  class="px-2 py-1"
                  :class="[$i18n.locale === 'en_UK' ? 'bg-theme-primary' : '']"
                  @click="$i18n.locale = 'en_UK'"
                >
                  EN
                </button>
              </div>
            </div>
            <div class="">
              <button
                class="text-sm border rounded px-2 py-1 flex flex-row items-center gap-2 hover:shadow"
                @click="logout"
              >
                <FontAwesomeIcon
                  :icon="['fal', 'arrow-right-from-bracket']"
                  size="lg"
                />
                <span>
                  {{ $t('logout') }}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-700"
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click="open = !open"
          >
            <span class="sr-only">Open main menu</span>

            <!--
              Icon when menu is closed.

              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!--
              Icon when menu is open.

              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div v-if="open" id="mobile-menu" class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <div
          v-for="(item, index) in menuItems"
          :key="index"
          :class="
            $route.name === item.routeName
              ? 'bg-white border-theme-secondary text-theme-primary block pl-3 pr-4 py-2 border-l-4 text-base font-medium capitalize'
              : 'border-transparent text-gray-500 hover:bg-gray-200 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium capitalize'
          "
          @click="redirectAndClose(item.routeName)"
        >
          {{ $t(item.title) }}
        </div>
      </div>
      <div class="my-4 w-full">
        <div class="w-3/4 pb-4 mx-auto">
          <button @click="logout">
            <FontAwesomeIcon :icon="['fal', 'arrow-right-from-bracket']" />
          </button>
          <BaseButton
            :title="$t('logout')"
            icon="times"
            color="danger"
            @click="logout"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import { BellIcon, MenuIcon, XIcon } from '@vue-hero-icons/outline'

import BaseButton from '../../components/BaseButton'
import { mapActions, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
  name: 'TheNavBar',
  components: { FontAwesomeIcon, BaseButton },
  data() {
    return {
      open: false,
      menuItems: [
        {
          title: 'shipments',
          routeName: 'homepage_route'
        },
        {
          title: 'addresses',
          routeName: 'addresses_route'
        },
        {
          title: 'profile',
          routeName: 'profile_route'
        },
        {
          title: 'FAQ',
          routeName: 'faq_route'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth'
    })
  },
  methods: {
    ...mapActions({
      processLogoutAction: 'profile/logout'
    }),
    redirectAndClose(routeName) {
      this.$router.push({ name: routeName })
      this.open = false
    },
    logout() {
      this.processLogoutAction({
        profile: this.auth,
        successCallback: () => {
          this.$router.push({ name: 'auth_login_route' })
        }
      })
    }
  }
}
</script>
