<template>
  <div class="grid md:grid-cols-5 sm:grid-cols-1">
    <div class="col-start-2 col-span-3 gap-3 flex flex-wrap">
      <h3
        v-if="$route.params.id !== '7'"
        class="mb-3 text-xl leading-6 font-medium text-theme-secondary capitalize-first border-b-2 border-theme-secondary"
      >
        {{ $t('pickupAddress') }}
      </h3>
    </div>
  </div>
  <template
    v-if="$route.params.id !== '7' && metadata?.user_adresses?.length > 0"
  >
    <div class="grid md:grid-cols-5 sm:grid-cols-1">
      <div class="col-start-2 col-span-3 gap-3">
        <div class="grid grid-cols-5 gap-x-2 mb-6">
          <div class="col-start-1 col-span-4">
            <BaseShowEditSelectSimple
              v-model="shipment.pickup_address_id"
              :label="$t('useYourAddresses')"
              name="user_pickup_adresses"
              :edition-mode="true"
              attribute-label="full_name_address"
              :options="metadata.user_adresses"
              :errors="errors?.addressId"
              @change="onSelectUserAddress"
            />
          </div>
          <div
            v-if="isPersonalAddressSelected"
            class="col-start-5 place-self-end md:w-20 sm:w-12"
          >
            <BaseButton icon="times" color="danger" @click="resetForm" />
          </div>
        </div>
      </div>
    </div>
    <hr />
  </template>
  <div
    class="grid md:grid-cols-2 sm:grid-cols-1 mt-5 md:w-3/5 container mx-auto gap-x-3"
  >
    <!--    <div class="md:col-start-2 md:col-span-3 sm:col-start-1 grid md:grid-cols-2 sm:grid-cols-1  gap-x-3">-->
    <div class="order-1 md:order-1 lg:order-1 xl:order-1">
      <BaseShowEditInput
        v-model="shipment.pickup_name"
        :label="$t('fullname')"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors?.pickup_name"
        :tabindex="1"
      />
    </div>
    <div class="order-5 md:order-2 lg:order-2 xl:order-2">
      <BaseShowEditInput
        v-model="shipment.pickup_street"
        :label="$t('street')"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors?.pickup_street"
        :tabindex="5"
      />
    </div>
    <div class="order-4 md:order-3 lg:order-3 xl:order-3">
      <BaseShowEditInput
        v-model="shipment.pickup_phone"
        :label="$t('phone')"
        :edition-mode="editionMode"
        :errors="errors?.pickup_phone"
        :tabindex="2"
      />
    </div>
    <div class="order-6 md:order-4 lg:order-4 xl:order-4">
      <BaseCityShowEditSelectAjax
        v-if="$route.params.id !== '7'"
        :key="pickupCityBaseValue"
        v-model="shipment.pickup_city_id"
        :edition-mode="editionMode"
        url="api/c2c/select2/ville"
        :label="$t('city')"
        name="pickup_city_id"
        track-by="id"
        :required="true"
        :tabindex="6"
        attribute-label="text"
        :query-country="shipment.pickup_country || 'BE'"
        :default-ajax="pickupCityBaseValue"
        :errors="cityErrors"
      />
      <BaseShowEditInput
        v-else
        v-model="shipment.pickup_city"
        :label="$t('city')"
        :edition-mode="editionMode"
        :errors="errors?.pickup_city"
        :tabindex="6"
      />
    </div>
    <div class="order-4 md:order-5 lg:order-5 xl:order-5">
      <BaseShowEditInput
        v-model="shipment.pickup_mobile"
        :required="true"
        :label="$t('mobile')"
        :edition-mode="editionMode"
        :errors="errors?.pickup_mobile"
        :tabindex="3"
      />
    </div>
    <div class="order-7 md:order-6 lg:order-6 xl:order-6">
      <BaseShowEditSelectSimple
        v-model="shipment.pickup_country"
        :label="$t('country')"
        name="country_receiver"
        :required="true"
        :edition-mode="editionMode"
        :options="metadata.countries_pickup.filter((item) => item.id === 'BE')"
        :errors="errors?.pickup_country"
        :tabindex="7"
      />
    </div>
    <div class="order-3 md:order-7 lg:order-7 xl:order-7">
      <BaseShowEditInput
        v-model="shipment.pickup_email"
        :label="$t('eMail')"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors?.pickup_email"
        :tabindex="4"
      />
    </div>
    <div class="order-last md:order-last lg:order-last xl:order-last">
      <BaseShowEditSelectSimple
        v-model="shipment.pickup_language"
        :label="$t('language')"
        name="pickup_language"
        :edition-mode="editionMode"
        :required="true"
        :tabindex="8"
        :options="metadata.languages"
        :errors="errors?.pickup_language"
      />
    </div>
  </div>
  <!--  </div>-->
  <div class="grid md:grid-cols-5 sm:grid-cols-1">
    <div
      class="grid md:col-start-2 md:col-span-3 md:grid-cols-2 sm:grid-cols-1 mt-5 md:gap-x-3"
    >
      <!--      <div>-->
      <div>
        <BaseShowEditTextArea
          v-model="shipment.pickup_simple_instructions"
          :label="$t('pickupInstruction')"
          :errors="errors.pickup_simple_instructions"
          :edition-mode="editionMode"
          :tabindex="10"
        />
      </div>
      <div v-if="false">
        <BaseFileInput
          v-if="$route.params.id !== '7'"
          v-model="pickupFile"
          :label="$t('joinAFile')"
          @change="setFileToStore"
        />
      </div>
      <div v-if="$route.params.id === '7'">
        <BaseShowLabel
          :label="$t('pickupDate')"
          :model-value="shipment.pickup_date"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapMutations } from 'vuex'

import BaseShowEditInput from '../../../../components/forms/inputs/BaseShowEditInput'
import BaseShowEditSelectSimple from '../../../../components/forms/selects/simple/BaseShowEditSelectSimple'
import BaseShowEditTextArea from '../../../../components/forms/inputs/BaseShowEditTextArea'
import BaseCityShowEditSelectAjax from '../../../../components/forms/selects/ajax/BaseCityShowEditSelectAjax'
import BaseButton from '../../../../components/BaseButton'
import BaseShowLabel from '../../../../components/forms/BaseShowLabel'
import BaseFileInput from '../../../../components/forms/inputs/BaseFileInput'

export default {
  name: 'ShipmentStepOtherPickupForm',
  components: {
    BaseShowEditInput,
    BaseShowEditSelectSimple,
    BaseShowEditTextArea,
    BaseCityShowEditSelectAjax,
    BaseButton,
    BaseFileInput,
    BaseShowLabel
  },
  props: {
    editionMode: { type: Boolean, required: false, default: true },
    isUsedAsMainDisplayedComponent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPersonalAddressSelected: false,
      city: null
    }
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getMetadata',
      errors: 'auth/getErrors',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber',
      pickupFile: 'shipment/getPickupFile'
    }),
    cityErrors() {
      return {
        ...this.errors.pickup_city,
        ...this.errors.pickup_city_id
      }
    },
    pickupCityBaseValue() {
      if (this.city) {
        return { id: this.city, text: `${this.city}` }
      } else if (this.shipment.pickup_city?.length) {
        return {
          id: this.shipment.pickup_city_id,
          text: `${this.shipment.pickup_city}`
        }
      } else {
        return {}
      }
    },
    userIsComingFromACorrectStep() {
      if (this.previousShipmentStepNumber !== null) {
        if (
          this.previousShipmentStepNumber !== parseInt(this.$route.params.id)
        ) {
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) - 1
          ) {
            return true
          }
          return (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) + 1
          )
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if (this.isUsedAsMainDisplayedComponent) {
      if (this.userIsComingFromACorrectStep) {
        this.processSetPreviousShipmentStepNumberMutation(
          parseInt(this.$route.params.id)
        )
      } else {
        if (this.previousShipmentStepNumber !== this.$route.params.id) {
          this.previousShipmentStepNumber === null
            ? this.$router.push({ name: 'create_shipment_route' })
            : this.$router.push({
                name: '',
                params: { id: this.previousShipmentStepNumber }
              })
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      processSetPreviousShipmentStepNumberMutation:
        'shipment/setPreviousShipmentStepNumber',
      processSetPickupFileMutation: 'shipment/setPickupFile',
      processFillPickupFormMutation: 'shipment/fillPickupForm',
      processResetPickupFormMutation: 'shipment/resetPickupForm',
      processSetPickupAddressIdMutation: 'shipment/setPickupAddressId',
      processResetPickupAddressIdMutation: 'shipment/resetPickupAddressId'
    }),
    onSelectUserAddress(payload) {
      console.log('payload', payload)
      if (!payload) {
        this.resetForm()
        return
      }
      this.isPersonalAddressSelected = true
      this.processSetPickupAddressIdMutation(this.shipment.pickup_address_id)
      this.processFillPickupFormMutation(payload)
      this.city = payload.city
      this.shipment.pickup_city = payload.city
    },
    resetForm() {
      this.isPersonalAddressSelected = false
      this.shipment.pickup_address_id = null
      this.city = null

      this.processResetPickupAddressIdMutation()
      this.processResetPickupFormMutation()
    },
    setFileToStore(event) {
      this.processSetPickupFileMutation(event.target.files[0])
    }
  }
}
</script>
