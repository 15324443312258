<template>
  <BasePageFull>
    <template #title />
    <template #body>
      <div class="flex flex-col gap-4">
        <div
          v-if="['Attente paiement'].includes(shipment.status)"
          class="bg-white w-full flex md:flex-row sm:flex-col md:justify-between gap-x-3 items-center p-4 rounded-md"
        >
          <div class="flex md:flex-row sm:flex-col gap-x-3 items-center">
            <div>
              <span
                :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-xl font-medium bg-theme-${shipment.status_color} text-white`"
              >
                {{ shipment.status }}
              </span>
            </div>
            <div>
              <BaseButton
                :title="$t('payAmount', { amount: shipment.price })"
                @click="redirectToPaymentStep"
              />
            </div>
          </div>
        </div>

        <ShipmentDetailServiceCard />

        <div
          class="flex flex-col sm:flex-row gap-4 bg-white rounded-lg overflow-hidden"
        >
          <div class="flex-1">
            <ShipmentDetailPickupCard />
          </div>
          <div class="flex-1">
            <ShipmentDetailDeliveryCard />
          </div>
        </div>
        <ShipmentDetailParcelCard />
      </div>
    </template>
  </BasePageFull>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import BasePageFull from '../../components/baseComponents/BasePageFull'
import BaseButton from '../../components/BaseButton'
import ShipmentDetailServiceCard from './ShipmentDetailServiceCard'
import ShipmentDetailPickupCard from './ShipmentDetailPickupCard'
import ShipmentDetailParcelCard from './ShipmentDetailParcelCard'
import ShipmentDetailDeliveryCard from './ShipmentDetailDeliveryCard'

export default {
  name: 'TheShipmentDetailPage',
  components: {
    ShipmentDetailDeliveryCard,
    ShipmentDetailParcelCard,
    ShipmentDetailPickupCard,
    ShipmentDetailServiceCard,
    BasePageFull,
    BaseButton
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment'
    })
  },
  mounted() {
    this.processFetchShipmentBySlugAction({ slug: this.$route.params.slug })
  },
  methods: {
    ...mapActions({
      processFetchShipmentBySlugAction: 'shipment/fetchShipmentBySlug'
    }),
    ...mapMutations({
      processpreparePaymentReattemptMutation: 'shipment/preparePaymentReattempt'
    }),
    redirectToPaymentStep() {
      console.log('redirectToPaymentStep')
      this.processpreparePaymentReattemptMutation()
      console.log({ name: 'create_shipment_step_route', params: { id: 8 } })
      this.$router.push({
        name: 'create_shipment_step_route',
        params: { id: 8 }
      })
    }
  }
}
</script>
