<!-- eslint-disable -->
<template>
  <div v-if="editionMode" class="mt-3">
    <BaseEditLabel :label="label" :required="required" />
    <multiselect
      class="form-control"
      :options="options"
      :placeholder="placeholder"
      :model-value="cDefautlValue"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :disabled="disabled"
      :required="required"
      :tabindex="tabindex"
      :selectLabel="''"
      :deselectLabel="''"
      :selectedLabel="$t('selected')"
      @update:modelValue="updateValueAction"
    />
    <template v-if="errors">
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="mt-1 text-red-600 text-xs font-light"
        v-html="error"
      />
    </template>
  </div>

  <div v-else class="mt-3">
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>
<script>
import multiselect from 'vue-multiselect'
import BaseEditLabel from '../../BaseEditLabel'
import BaseShowLabel from '../../BaseShowLabel'

export default {
  name: 'BaseShowEditSelectSimple',
  components: {
    multiselect,
    BaseEditLabel,
    BaseShowLabel
  },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    selectClass: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: -1
    }
  },
  emits: ['update:modelValue', 'change', 'reset'],
  computed: {
    cDisplayedValueWhenNotEditionMode() {
      if (this.cDefautlValue) {
        if (this.attributeLabel) {
          return this.cDefautlValue[this.attributeLabel]
        } else {
          return this.cDefautlValue
        }
      } else {
        return '-'
      }
    },
    data() {
      return {
        brand: {}
      }
    },
    cDefautlValue() {
      return this.options?.find(
        (item) => item[this.trackBy] === this.modelValue
      )
    }
  },
  methods: {
    updateValueAction(newValue) {
      if (newValue) {
        this.$emit('update:modelValue', newValue[this.trackBy])
        this.$emit('change', newValue)
      } else {
        this.$emit('update:modelValue', {})
        this.$emit('change', newValue)
      }
    }
  }
}
</script>
