<template>
  <div class="grid md:grid-cols-5 sm:grid-cols-1 mt-5">
    <div class="md:col-start-2 md:col-span-3 sm:col-start-1 grid md:grid-cols-2 sm:grid-cols-1 md gap-3">
      <div>
        <BaseShowLabel
          :label="$t('name')"
          :model-value="shipment.pickup_name"
        />
      </div>
      <div>
        <BaseShowLabel
          :label="$t('street')"
          :model-value="shipment.pickup_street"
        />
      </div>
      <div>
        <BaseShowLabel
          :label="$t('postCode')"
          :model-value="shipment.pickup_post_code"
        />
      </div>
      <div>
        <BaseShowLabel
          :label="$t('city')"
          :model-value="shipment.pickup_city"
        />
      </div>
      <div>
        <BaseShowLabel
          :label="$t('country')"
          :model-value="countryDisplayedValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import BaseShowLabel from "../forms/BaseShowLabel";

export default {
  name: "PickupRecap",
  components: { BaseShowLabel },
  props:{
    editionMode: { type: Boolean, required: false, default: false },
  },
  computed:{
    ...mapGetters({shipment: 'shipment/getShipment', metadata: 'shipment/getMetadata', errors: 'auth/getErrors'}),
    countryDisplayedValue(){
      return this.metadata?.countries_pickup?.find( country => country.id === this.shipment?.pickup_country)?.name
    }
  },
}
</script>
