export default {
  site: {
    global: {
      jeMInscris: 'Register'
    },
    section1: {
      livraisonParticulierAParticulier: 'Person-to-person delivery',
      mhdSpecialiste:
        '(My) Home Delivery is your specialist<br />in the <span className="font-bold">delivery of parcels between individuals heavy and/or bulky.</span>'
    },
    section2: {
      commentCaFonctionne: 'How it works ?',
      explication:
        'You have <span class="text-theme-primary">bought or sold a piece of furniture, a bed, a wardrobe,</span>...<br />And you don\'t know how to deliver your purchase/sale?<br /><span class="text-theme-primary">(My) Home Delivery takes care of the collection of your goods and their delivery.</span>'
    },
    section3: {
      title:
        '(My) Home Delivery your specialist in the delivery between private individuals of heavy and/or bulky parcels.'
    },
    section5: {
      title: 'Type of deliveries and day ',
      subTitle:
        'Choose your type of delivery or collection and the desired date',
      type: {
        pasDePorte: 'Doorstep delivery',
        pieceHabitation: 'Delivery in the room of choice',
        jourEtDistance: 'Day and distance',
        paiementsSecurises: 'secure payments'
      },
      footer: 'Same day delivery within 30km is possible and cheaper!'
    },
    section6: {
      title: 'How to ship a package?',
      subTitle:
        '<span class="text-theme-primary">It\'s quick and easy</span><br />Complete the requested information',
      list: {
        1: 'Pick-up address',
        2: 'Delivery address',
        3: 'Number of packages with weights and dimensions',
        4: 'Date of collection',
        5: 'Date of delivery',
        6: 'Secure payment'
      }
    },
    footer: {
      title: 'A question ?',
      subTitle: 'For any questions<br>do not hesitate to contact us'
    }
  },
  createdtAt: 'date',
  forget: 'Forgot your password? ?',
  logout: 'logout',
  addPackage: 'Add a parcel',
  address: 'address',
  Addresses: 'Addresses',
  afterOrderInstruction:
    'collection and delivery information has been emailed to you',
  afterOrderInstructionTwo:
    'a text message and an email containing the two-hour time slot will be sent to you the day before collection and delivery.',
  agency: 'agency',
  back: 'return',
  backToIndex: 'back to home',
  brand: 'partner',
  business: 'professional',
  cancel: 'cancel',
  cancelEditPassword: 'undo password changes',
  city: 'city',
  collection: 'collection',
  collectionDate: 'collection date',
  companyName: 'company name',
  confirmAndSend: 'confirm and send',
  confirmPassword: 'password confirmation',
  country: 'country',
  customerType: 'customer type',
  delivery: 'Delivery',
  deliveryAddress: 'delivery address',
  deliveryDate: 'delivery date',
  deliveryDifferentDay: 'collection and delivery on different dates',
  deliveryInstruction: 'delivery instruction',
  deliverySameDay: 'same day collection and delivery',
  deliveryServices: 'Delivery services',
  deliveryType: 'type of delivery',
  edit: 'modify',
  editPassword: 'change the password',
  eMail: 'e-mail',
  firstName: 'first name',
  height: 'height',
  howManyPackage: 'how many parcels ?',
  joinAFile: 'join a file',
  language: 'language',
  lastName: 'last name',
  length: 'length',
  login: 'login',
  mobile: 'mobile',
  name: 'name',
  fullname: 'firstname and lastname',
  needEmailVerification:
    "It looks like you haven't confirmed your email address, please do so by clicking the link in the email you received when creating your account.",
  newExpedition: 'new expedition',
  createExpedition: 'create an expedition',
  next: 'next',
  noShipmentsYet: "You don't have any shipments in your history yet.",
  number: 'number',
  ourPartners: 'our partners',
  package: 'parcel',
  packageQuantity: 'number of parcels',
  parcel: 'parcel',
  partner: 'partner',
  password: 'password',
  phone: 'phone',
  pickup: 'pickup',
  pickupAddress: 'collection address',
  pickupAndDeliveryDate: 'collection and delivery date',
  pickupDate: 'pickup date',
  pickupInstruction: 'pickup instruction',
  postCode: 'postcode',
  price: 'price',
  private: 'private',
  profile: 'profile',
  reference: 'reference',
  resendVerifiedMessage: 'The confirmation message has been sent to you again.',
  service: 'service',
  shipments: 'shipments',
  signIn: 'Sign In',
  signUp: 'register',
  status: 'status',
  stepXOnY: 'step {x} on {y}',
  street: 'street and number',
  thanksForYourOrder: 'Thank you, your order has been registered!',
  toACustomAddress: 'to another address',
  toAPartner: 'at one of our partners',
  unavailableForTheMoment: 'unavailable at the moment',
  totalToPay: 'total to pay:',
  TVA: 'VAT',
  totalVolume: 'total volume:',
  totalWeight: 'total weight',
  userName: 'username',
  value: 'value',
  verrifySpam: 'Please also check your junk mail (spam).',
  volumeTotal: 'total volume (m³)',
  weight: 'weight',
  weightTotal: 'weightTotal',
  whatDeliveryType: 'what type of delivery do you want?',
  whereToCollectPackage: 'where should we collect your package(s)?',
  width: 'width',
  yourAddresses: 'your addresses',
  useYourAddresses: 'use one of your addresses',
  yourInfos: 'your informations',
  yourOrder: 'your order',
  yourExpeditions: 'your shipments',
  sendMailAgain: 'receive this email again',
  pleaseConfirmYourMail:
    'Your request has been sent, please confirm the creation of your account by clicking on the link in the email you received.',
  cgAcceptation: 'I have read and accept the general conditions.',
  addresseType: {
    0: 'pickup',
    1: 'delivery'
  },
  not_yet_an_account: 'Don’t have an account yet?',
  already_an_account: 'I already have an account?',
  click_here: 'click-here',
  selected: 'selected'
}
