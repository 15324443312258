import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      shipmentsIndex: [],
      shipment: {},
      indexMetadata: {},
      metadata: {},
      dates: {},
      pickupType: null,
      serviceCode: null,
      previousShipmentStepNumber: null,
      isSameDay: null,
      pickupFile: null,
      deliveryFile: null,
      pickupAddressId: null,
      deliveryAddressId: null,
      paymentReattempt: false,
      cgReaded: false,
    };
  },
  actions,
  mutations,
  getters,
};
