<template>
  <div class="fixed z-50 top-3 right-5">
    <div>
      <div
        class="bg-white border rounded overflow-hidden flex flex-row divide-x text-sm sm:text-base"
      >
        <button
          class="px-3 py-1"
          :class="[$i18n.locale === 'fr_BE' ? 'bg-theme-primary' : '']"
          @click="$i18n.locale = 'fr_BE'"
        >
          FR
        </button>
        <button
          class="px-3 py-1"
          :class="[$i18n.locale === 'nl_BE' ? 'bg-theme-primary' : '']"
          @click="$i18n.locale = 'nl_BE'"
        >
          NL
        </button>
        <button
          class="px-3 py-1"
          :class="[$i18n.locale === 'en_UK' ? 'bg-theme-primary' : '']"
          @click="$i18n.locale = 'en_UK'"
        >
          EN
        </button>
      </div>
    </div>
  </div>

  <div class="w-screen h-screen flex flex-col">
    <!-- HERO -->
    <div
      class="flex-shrink-0 h-screen object-contain items-center justify-center"
      :style="{
        backgroundImage: `url(${require('@/assets/images/hero-1.jpg')})`,
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'right'
      }"
    >
      <div
        class="w-full h-full flex justify-center items-center bg-white bg-opacity-30"
      >
        <div class="flex flex-col gap-2 md:gap-3 lg:gap-5 lg:-ml-32">
          <h1
            class="text-xl md:text-3xl lg:text-6xl font-bold leading-10"
            v-html="$t('site.section1.livraisonParticulierAParticulier')"
          />
          <p
            class="text-base md:text-xl lg:text-3xl"
            v-html="$t('site.section1.mhdSpecialiste')"
          />
        </div>
      </div>
    </div>

    <!-- COMMENT CA SE PASSE -->
    <div class="flex-shrink-0 min-h-screen bg-white">
      <div class="container mx-auto h-full flex flex-col py-24">
        <div
          class="px-6 sm:px-0 flex-1 flex flex-col gap-16 sm:flex-row sm:gap-0"
        >
          <div class="sm:w-2/3 lg:flex-1 flex flex-col justify-center">
            <div class="flex flex-col gap-10">
              <h2
                class="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold"
                v-html="$t('site.section2.commentCaFonctionne')"
              />
              <p
                class="text-base sm:text-xl lg:text-3xl"
                v-html="$t('site.section2.explication')"
              />
              <div>
                <button
                  class="bg-theme-primary px-3 py-2 rounded text-white"
                  @click="scrollTo('login-form')"
                  v-html="$t('site.global.jeMInscris')"
                />
              </div>
            </div>
          </div>
          <div
            class="sm:w-1/3 lg:flex-1 flex flex-row justify-center sm:justify-end sm:items-center"
          >
            <div class="w-1/2 sm:w-3/4 relative">
              <img
                :src="require('@/assets/images/comment-ca-se-passe.jpg')"
                class="object-cover border-theme-secondary border-b-8"
                style="aspect-ratio: 1"
              />
              <div
                class="absolute -left-6 -bottom-6 bg-theme-primary opacity-50 w-1/2 h-1/2"
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PICTO -->

    <div class="bg-gray-400 text-white py-10">
      <div class="px-6 sm:px-0 container mx-auto flex flex-col gap-5">
        <div
          class="text-xl lg:text-3xl flex flex-row text-center"
          v-html="$t('site.section3.title')"
        />
        <div class="flex flex-row gap-3 sm:gap-6 md:gap-10 lg:gap-20">
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/bed.png')"
            />
          </div>
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/closet.png')"
            />
          </div>
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/mattress.png')"
            />
          </div>
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/meeting-room.png')"
            />
          </div>
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/bike.png')"
            />
          </div>
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/washing-machine.png')"
            />
          </div>
          <div class="flex-1">
            <img
              class="filter invert"
              :src="require('@/assets/images/lawn-mower.png')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- LOGIN FORM -->
    <div id="login-form" class="flex flex-row">
      <div
        class="flex-1 grid grid-cols-5 gap-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div
          class="col-span-5 sm:col-span-2 flex flex-col sm:flex-row items-center"
          :class="[
            $route.name === 'auth_register_route'
              ? 'sm:col-span-3'
              : 'sm:col-span-2'
          ]"
        >
          <div class="flex-1 flex flex-col">
            <router-view />
          </div>
        </div>
        <div
          class="h-screen col-span-3 flex items-center my-20 overflow-y-scroll"
          :class="[
            $route.name === 'auth_register_route'
              ? 'sm:col-span-2'
              : 'sm:col-span-3'
          ]"
        >
          <FaqNl v-if="$i18n.locale === 'nl_BE'" />
          <FaqNl v-else-if="$i18n.locale === 'en_UK'" />
          <FaqFr v-else />
        </div>
      </div>
    </div>

    <!-- TYPE DE LIVRAISON -->
    <div class="flex-shrink-0 min-h-screen flex flex-col bg-white py-10">
      <div
        class="px-6 sm:px-0 flex flex-col h-full container mx-auto justify-around"
      >
        <div
          class="text-4xl font-bold text-green-800 text-center"
          v-html="$t('site.section5.title')"
        />
        <div
          class="text-xl text-theme-primary text-center"
          v-html="$t('site.section5.subTitle')"
        />
        <div class="flex flex-wrap sm:flex-row sm:gap-20 sm:px-20">
          <div class="w-1/2 sm:flex-1 flex flex-row justify-center">
            <div class="w-1/2 flex flex-col items-center gap-3">
              <img
                alt="pas de porte"
                class="w-full"
                :src="require('@/assets/images/pasdeporte.svg')"
              />
              <p
                class="text-theme-primary text-center"
                v-html="$t('site.section5.type.pasDePorte')"
              />
            </div>
          </div>
          <div class="w-1/2 sm:flex-1 flex flex-row justify-center">
            <div class="w-1/2 flex flex-col items-center gap-3">
              <img
                alt="room"
                class="w-full"
                :src="require('@/assets/images/room.svg')"
              />
              <p
                class="text-theme-primary text-center"
                v-html="$t('site.section5.type.pieceHabitation')"
              />
            </div>
          </div>
          <div class="w-1/2 sm:flex-1 flex flex-row justify-center">
            <div class="w-1/2 flex flex-col items-center gap-3">
              <img
                alt="livraison-rapide"
                class="w-full"
                :src="require('@/assets/images/livraison-rapide.svg')"
              />
              <p
                class="text-theme-primary text-center"
                v-html="$t('site.section5.type.jourEtDistance')"
              />
            </div>
          </div>
          <div class="w-1/2 sm:flex-1 flex flex-row justify-center">
            <div class="w-1/2 flex flex-col items-center gap-3">
              <img
                alt="carte-de-credit"
                class="w-full"
                :src="require('@/assets/images/carte-de-credit.svg')"
              />
              <p
                class="text-theme-primary text-center"
                v-html="$t('site.section5.type.paiementsSecurises')"
              />
            </div>
          </div>
        </div>
        <div
          class="text-xl text-gray-700 text-center"
          v-html="$t('site.section5.footer')"
        />
      </div>
    </div>

    <div class="flex-shrink-0 sm:py-0 py-6 sm:h-screen bg-gray-100">
      <div class="container mx-auto h-full flex flex-col px-6 sm:px-0">
        <div class="flex-1 flex flex-col sm:flex-row gap-6 sm:gap-0">
          <div
            class="sm:w-2/3 lg:flex-1 flex flex-col justify-center sm:-mt-20"
          >
            <div class="flex flex-col gap-10">
              <h2
                class="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold"
                v-html="$t('site.section6.title')"
              />
              <p
                class="text-base sm:text-xl lg:text-3xl"
                v-html="$t('site.section6.subTitle')"
              />
              <div>
                <div
                  class="grid grid-cols-12 gap-x-2 gap-y-1 md:gap-x-5 md:gap-y-2"
                >
                  <div class="col-start-1 text-theme-primary text-right">
                    1.
                  </div>
                  <div
                    class="col-span-11"
                    v-html="$t('site.section6.list.1')"
                  />
                  <div class="col-start-1 text-theme-primary text-right">
                    2.
                  </div>
                  <div
                    class="col-span-11"
                    v-html="$t('site.section6.list.2')"
                  />
                  <div class="col-start-1 text-theme-primary text-right">
                    3.
                  </div>
                  <div
                    class="col-span-11"
                    v-html="$t('site.section6.list.3')"
                  />
                  <div class="col-start-1 text-theme-primary text-right">
                    4.
                  </div>
                  <div
                    class="col-span-11"
                    v-html="$t('site.section6.list.4')"
                  />
                  <div class="col-start-1 text-theme-primary text-right">
                    5.
                  </div>
                  <div
                    class="col-span-11"
                    v-html="$t('site.section6.list.5')"
                  />
                  <div class="col-start-1 text-theme-primary text-right">
                    6.
                  </div>
                  <div
                    class="col-span-11"
                    v-html="$t('site.section6.list.6')"
                  />
                </div>
                <div class="sm:mt-6">
                  <button
                    class="bg-theme-primary px-3 py-2 rounded text-white"
                    @click="scrollTo('login-form')"
                    v-html="$t('site.global.jeMInscris')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="hidden sm:w-1/3 lg:flex-1 sm:flex flex-row justify-center sm:justify-end items-center"
          >
            <div class="w-1/2 sm:w-3/4 relative">
              <div
                class="z-0 absolute -left-6 -bottom-6 bg-theme-primary opacity-50 w-1/2 h-1/2"
              >
                &nbsp;
              </div>
              <img
                alt="optimisation"
                :src="require('@/assets/images/optimisation.jpg')"
                class="object-cover border-4 z-20"
                style="aspect-ratio: 1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="py-6 sm:py-0 sm:h-screen flex-shrink-0 bg-white">
      <div
        class="container h-full mx-auto flex flex-col py-24 sm:py-48 gap-6 sm:justify-between"
      >
        <h2
          class="text-6xl text-center font-bold text-green-800"
          v-html="$t('site.footer.title')"
        />
        <p class="text-xl text-center" v-html="$t('site.footer.subTitle')" />
        <p class="text-theme-primary text-center">+32 (0) 64 22 32 38</p>
        <p class="text-theme-primary text-center">info@my-home-delivery.com</p>
      </div>
    </div>

    <div class="py-4 sm:py-24 bg-green-800 flex flex-row justify-center">
      <img
        alt="home_del_logo_300_white"
        class="w-56"
        :src="require('@/assets/images/home_del_logo_300_white.png')"
      />
    </div>
  </div>
</template>

<script>
import FaqFr from '@/views/faq/FaqFr.vue'
import FaqNl from '@/views/faq/FaqNl.vue'

export default {
  name: 'TheAuthLayout',
  components: { FaqNl, FaqFr },
  methods: {
    scrollTo(id) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
