// import i18n from '../../../i18n'
export default {
    setToken(state, token){
        state.token = token
    },
    setExpirationDate(state, expirationDate){
        state.expirationDate = expirationDate
    },
    setAuth(state, payload){
        if(payload.auth  === {}){
            localStorage.removeItem('vuex');
        }

       // i18n.locale = payload.auth.language
        state.auth = payload.auth
    },
    setMetadata(state, metadata){
        state.metadata = metadata
    },
    setErrors(state, errors){
        state.errors = errors
    },
    resetErrors(state){
        state.errors = {}
    }
}
