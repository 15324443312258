<template>
  <main
    class="flex-1 flex flex-col py-6"
  >
    <slot />
  </main>
</template>

<script>
export default {
  name: 'BasePageBody',
}
</script>

<style scoped>

</style>
