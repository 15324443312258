import apiClient from '../../../utils/apiClient'
export default {
    //region fetch
    async fetchAddressesIndex({commit}){
        await apiClient.get(`api/c2c/address`)
            .then(async response => {
                if(response.status === 200){
                    await commit('setAddressesIndex', response.data.addresses)
                    await commit('setMetadata', response.data.metadata )

                }
        })
            .catch(async reason => {
                await console.error('fetchAddressesIndex', reason)
        })
    },
    //endregion

    //region delete
    async deleteAddress({commit}, payload) {
        await apiClient.put(`api/c2c/address-delete/${payload}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setAddressesIndex', response.data.addresses)

                }
            })
            .catch(async reason => {
                console.error('deleteAddress', reason)
            })
    },
    //endregion

}
