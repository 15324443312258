<template>
  <BasePageFull>
    <template #cta>
      <div class="grid grid-cols-3 h-9" />
    </template>
    <template #body>
      <div
        class="pt-8 bg-white flex flex-col max-h-full h-full shadow rounded-lg"
      >
        <div
          class="flex-shrink-0 max-w-7xl flex flex-row justify-between mx-auto px-5"
        >
          <h1
            class="text-3xl font-bold leading-tight text-theme-secondary capitalize-first"
          >
            {{ $t('yourInfos') }}
          </h1>
          <!--          <div-->
          <!--            class="w-1/7"-->
          <!--          >-->
          <!--            <BaseButton-->
          <!--              icon="times"-->
          <!--              color="danger "-->
          <!--              :title="$t('logout')"-->
          <!--              @click="logout"-->
          <!--            />-->
          <!--          </div>-->
        </div>
        <div
          class="flex-1 min-h-0 overflow-y-scroll px-4 py-5 sm:px-6 md:w-2/3 container mx-auto mt-7 flex flex-col gap-4"
        >
          <div class="grid md:grid-cols-2 sm:grid-cols-1 md:gap-x-3">
            <div>
              <BaseShowEditInput
                v-if="auth.customer.customer_type === 'business'"
                v-model="auth.customer.company_name"
                :label="$t('companyName')"
                :placeholder="$t('companyName')"
                name="mobile"
                :required="true"
                :errors="errors?.phone"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-if="auth.customer.customer_type === 'business'"
                v-model="auth.customer.tva"
                :label="$t('TVA')"
                :placeholder="$t('tva')"
                name="tva"
                :required="true"
                :errors="errors?.tva"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.last_name"
                :label="$t('lastName')"
                :placeholder="$t('lastName')"
                :required="true"
                :errors="errors?.last_name"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.first_name"
                :label="$t('firstName')"
                :placeholder="$t('firstName')"
                :required="true"
                :errors="errors?.first_name"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.user_name"
                :label="$t('userName')"
                :errors="errors?.user_name"
                :placeholder="$t('userName')"
                :required="true"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.email"
                :label="$t('email')"
                :errors="errors?.email"
                :placeholder="$t('email')"
                :required="true"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.phone"
                :label="$t('phone')"
                :placeholder="$t('phone')"
                :edition-mode="true"
                name="phone"
                :errors="errors?.phone"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.mobile"
                :label="$t('mobile')"
                :placeholder="$t('mobile')"
                :edition-mode="true"
                name="mobile"
                :errors="errors?.mobile"
                :required="true"
              />
            </div>
          </div>
          <div class="grid md:grid-cols-2 sm:grid-cols-1 md:gap-x-3">
            <div>
              <BaseLanguageShowEditSelectSimple
                v-model="auth.langue"
                :label="$t('language')"
                name="langue"
                :options="metadata.languages"
                :errors="errors.language"
              />
            </div>
          </div>
          <!--endregion-->

          <!--region editPassword-->
          <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-x-2">
            <div class="col-span-2">
              <BaseShowEditInput
                v-model="auth.street_nb"
                :label="$t('street')"
                :edition-mode="true"
                name="street"
                :errors="errors?.street_nb"
                :required="true"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.postCode"
                :label="$t('postCode')"
                :edition-mode="true"
                name="postCode"
                :errors="errors?.postCode"
                :required="true"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.city"
                :label="$t('city')"
                :edition-mode="true"
                name="city"
                :errors="errors?.city"
                :required="true"
              />
            </div>
            <div>
              <BaseShowEditSelectSimple
                v-model="auth.country"
                :label="$t('country')"
                name="country_receiver"
                :edition-mode="true"
                :options="[{ id: 'BE', name: 'Belgique' }]"
                :required="true"
                :errors="errors?.country"
              />
            </div>
          </div>
          <!--endregion-->

          <!--region editPassword-->
          <div class="grid sm:grid-cols-1 gap-2">
            <div class="col-span-1 mt-8">
              <BaseButton
                color="success"
                :title="
                  editPassword ? $t('cancelEditPassword') : $t('editPassword')
                "
                @click="editPassword = !editPassword"
              />
            </div>
          </div>
          <div
            v-if="editPassword"
            class="grid md:grid-cols-2 sm:grid-cols-1 gap-5"
          >
            <div>
              <BaseShowEditInput
                v-model="auth.password"
                :label="$t('password')"
                :errors="errors?.password"
                :placeholder="$t('password')"
                :required="true"
              />
            </div>
            <div>
              <BaseShowEditInput
                v-model="auth.password_confirmation"
                :label="$t('confirmPassword')"
                :errors="errors?.password_confirmation"
                :placeholder="$t('confirmPassword')"
                :required="true"
              />
            </div>
          </div>
          <!--endregion-->
        </div>
        <!--region submit-->
        <!--        class="grid md:grid-cols-8 sm:grid-cols-1 mb-5 mx-4"-->
        <!--        class="mt-3 md:col-start-7 sm:mx-5"-->
        <div
          class="flex-shrink-0 grid md:grid-cols-7 sm:grid-cols-1 md:mr-12 mx-4 mb-5"
        >
          <div class="mt-3 md:col-start-6 mx-0 md:mx-3">
            <BaseButton
              icon=""
              color="primary"
              :title="$t('edit')"
              @click="updateProfile"
            />
          </div>
        </div>
        <!--endregion-->
      </div>
    </template>
  </BasePageFull>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

import BaseButton from '../../components/BaseButton'
import BasePageFull from '../../components/baseComponents/BasePageFull'
import BaseShowEditInput from '../../components/forms/inputs/BaseShowEditInput'
import BaseLanguageShowEditSelectSimple from '../../components/forms/selects/simple/BaseShowEditSelectSimple'
import BaseShowEditRadioGroup from '../../components/forms/radio/BaseShowEditRadioGroup'
import BaseShowEditSelectSimple from '@/components/forms/selects/simple/BaseShowEditSelectSimple.vue'

export default {
  name: 'TheProfilePage',
  components: {
    BaseShowEditSelectSimple,
    BaseButton,
    BasePageFull,
    BaseShowEditInput,
    BaseLanguageShowEditSelectSimple,
    BaseShowEditRadioGroup
  },
  data() {
    return {
      editPassword: false
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      metadata: 'shipment/getMetadata',
      errors: 'auth/getErrors'
    })
  },
  watch: {
    editPassword(newValue, oldValue) {
      if (newValue !== null && newValue !== oldValue) {
        if (newValue === false) {
          this.auth.password = null
          this.auth.password_confirmation = null
        }
      }
    }
  },
  methods: {
    ...mapActions({
      processUpdateUserAction: 'profile/updateUser',
      processLogoutAction: 'profile/logout'
    }),
    updateProfile() {
      this.processUpdateUserAction({
        profile: {
          first_name: this.auth.first_name,
          last_name: this.auth.last_name,
          user_name: this.auth.user_name,
          phone: this.auth.phone,
          mobile: this.auth.mobile,
          email: this.auth.email,
          customer_type: this.auth.customer.customer_type,
          company_name: this.auth.customer.company_name,
          tva: this.auth.customer.tva,
          langue: this.auth.langue,
          street_nb: this.auth.street_nb,
          postCode: this.auth.postCode,
          city: this.auth.city,
          country: this.auth.country,
          password: this.auth.password ? this.auth.password : null,
          password_confirmation: this.auth.password_confirmation
            ? this.auth.password_confirmation
            : null
        },
        successCallback: () => {
          this.$root.$i18n.locale = this.auth.langue
          this.$router.push({ name: 'homepage_route' })
        }
      })
    }
    // logout() {
    //   this.processLogoutAction(
    //     {
    //       profile: this.auth,
    //       successCallback: () => {
    //         this.$router.push({name: 'auth_login_route'})
    //       }
    //     })
    // },
  }
}
</script>
