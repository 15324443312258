<template>
  <div class="h-full flex flex-col gap-8 overflow-scroll">
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">WHO IS MY HOME DELIVERY?</h2>
      <p class="text-sm">
        My Home Delivery is a company specializing in the delivery of heavy
        and/or bulky parcels to individuals. <br />In 2023, My Home Delivery
        decides to open this service to individuals, i.e. allow anyone to
        collect their goods either in store or from an individual and have it
        delivered.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        HOW TO REGISTER ON MY HOME DELIVERY?
      </h2>
      <p class="text-sm">
        Log on to https://particuliers.my-home-delivery.com/ and follow the
        instructions to register.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">HOW TO SHIP A PACKAGE?</h2>
      <p class="text-sm">
        After being registered, follow the steps :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;&nbsp;Pickup / collection
        address<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;Delivery address<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;&nbsp;Number of packages + weight
        & dimensions<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;&nbsp;Pickup / collection date<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;&nbsp;Delivery date<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.&nbsp;&nbsp;Proceed to payment<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.&nbsp;&nbsp;It’s done! You can print the
        label(s) and prepare the package(s). Remember to pack (+ link to
        packaging FAQ) your package correctly
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">WHAT IS THE SHIPPING COST?</h2>
      <p class="text-sm">
        The price is determined by:<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;The chosen delivery service
        (doorstep or in the room)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;The day and distance during
        collection-delivery: Same day delivery within 30 km will be cheaper
      </p>
      <div>
        <table class="bg-white w-full border-gray-500 rounded table-auto">
          <tr>
            <th class="border border-gray-200 p-2" colspan="2">
              Type of delivery service
            </th>
            <th class="border border-gray-200 p-2" colspan="2">Belgium</th>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2"></td>
            <td class="border border-gray-200 p-2">max shipping weight</td>
            <td class="border border-gray-200 p-2">within 30km</td>
            <td class="border border-gray-200 p-2">outside 30km</td>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2">Doorstep Delivery</td>
            <td class="border border-gray-200 p-2">0-100kg</td>
            <td class="border border-gray-200 p-2 text-right">55 €</td>
            <td class="border border-gray-200 p-2 text-right">75 €</td>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2">
              Delivery in the room of use
            </td>
            <td class="border border-gray-200 p-2">0-100kg</td>
            <td class="border border-gray-200 p-2 text-right">65 €</td>
            <td class="border border-gray-200 p-2 text-right">85 €</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WHAT TYPES OF ARTICLE CAN BE HANDLED BY MY HOME DELIVERY?
      </h2>
      <p class="text-sm">
        These are common goods: examples: household appliances, bedding, indoor
        and outdoor furniture, bicycles, garden utensils, decorative items,
        etc.<br />
        We do not deliver a piano for example and we are not movers either (we
        do not deliver with a lift; if a lift is needed, it is up to you to take
        care of it).<br />
        Please note, some items are also prohibited, see our General Conditions
        for more details.<br />
        A question, a doubt? Contact us to make sure before encoding your
        delivery.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WHAT ARE THE AUTHORIZED WEIGHTS AND DIMENSIONS?
      </h2>
      <p class="text-sm">
        Deliveries accepted must meet the following criteria:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Total maximum weight: 100
        kg<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Maximum length per package:
        350 cm<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Total maximum volume: 2 m³
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        HOW TO PACK MY PARCEL? ARE THERE ANY INSTRUCTIONS FOR PREPARING THE
        PACKAGE TO SEND?
      </h2>
      <p class="text-sm">
        My Home Delivery does not take care of the packaging of the parcels that
        you wish to collect / deliver via our services. The sender (+ glossary
        link) is responsible for packaging the package(s) to be
        collected/delivered.<br /><br />
        PLEASE NOTE: it is mandatory to pack the goods correctly (see
        recommendations) and to stick the label(s): 1 label per package to be
        collected / delivered.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WHAT SERVICES ARE OFFERED BY MY HOME DELIVERY?
      </h2>
      <p class="text-sm">
        My Home Delivery offers 2 delivery formulas:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;“Doorstep” delivery
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Delivery to the desired room
        kamer<br />
        ATTENTION ; the sender must ensure that the goods are compatible with
        the use for which they are intended and that they can be delivered
        safely to the recipient and that the accesses are wide enough and offer
        sufficient height for the delivery to take place. normally and properly.
        Delivery without lift: maximum 3rd floor.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        TO WHICH FLOOR DO YOU DELIVER? (OR PICKUP)
      </h2>
      <p class="text-sm">
        If the parcel(s) enter(s) the elevator, there is no floor limit.<br />
        Otherwise, we deliver up to the 3rd floor maximum without lift, with a
        maximum of 2 packages and a maximum of 60 kg per package. And this, as
        long as the accesses are wide enough and offer sufficient height for the
        delivery to take place normally and properly.<br />
        (My) Home Delivery reserves the right to refuse delivery (or collection)
        in the event of non-compliance with access standards
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WHICH COUNTRIES ARE OFFERED BY MY HOME DELIVERY?
      </h2>
      <p class="text-sm">
        Our delivery services are reserved for collections and deliveries in
        Belgium.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        WHAT WAY OF PAYMENT ON MY HOME DELIVERY?
      </h2>
      <p class="text-sm">Bancontact, Visa, MasterCard</p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        MY PACKAGE HAS BEEN DELIVERED BUT IT IS DAMAGED. HOW TO DO ?
      </h2>
      <p class="text-sm">
        There are 2 types of disputes that (My) Home Delivery handles:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Parcel not traced (lost)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Parcel damaged following a
        delivery<br /><br />
        Each case must be the subject of a declaration of the dispute via our
        Customer Service within 24 hours (1 working day) after delivery to the
        recipient.
        <br /><br />
        In each case, the compensation procedure will be as follows:
        <br />
        <b>• Package not traced (lost)</b><br />
        Support for the dispute after an investigation of 15 days (working
        days)<br /><br />

        <b>• Package damaged following a delivery</b><br />
        For the dispute to be taken care of, it is imperative that, during
        delivery, the recipient has mentioned remarks / reservations via the
        application of the driver / delivery person. These remarks/reservations
        must be precise and concrete, with supporting photos.
        <br /><br />

        <u>Non-exhaustive list of disputes not covered</u><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Products damaged in packaging
        but not visible upon receipt or delivery (packaging intact from the
        outside) will not be covered (= hidden defects)
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;When the reserve mentions
        "subject to unpacking".<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;When the packaging or the
        type of product transported does not comply (see packaging
        recommendations).<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;…<br /><br />

        <u>Amount reimbursed :</u><br />
        The amount reimbursed is limited to €5 per kg of damaged or lost gross
        weight, with a maximum of €50 per event or per series of events having a
        single cause of damage.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqEn'
}
</script>

<style scoped></style>
