<template>
  <div
    class="p-4 bg-white overflow-hidden shadow rounded-lg flex flex-col gap-4"
  >
    <h3
      class="flex-1 text-2xl leading-6 font-medium text-theme-secondary capitalize-first"
    >
      {{ $t('package') }}
    </h3>
    <div class="grid md:grid-cols-4 grid-cols-2 gap-3">
      <div class="col-span-2 sm:col-span-1">
        <BaseShowLabel
          :label="$t('weightTotal')"
          :model-value="shipment.weight_total"
        />
      </div>
      <div class="col-span-2 sm:col-span-1">
        <BaseShowLabel
          :label="$t('volumeTotal')"
          :model-value="shipment.volume_total"
        />
      </div>
    </div>
    <ParcelsTable :parcel="shipment.parcel" />
  </div>
</template>
<script>
import BaseShowLabel from '../../components/forms/BaseShowLabel'
import ParcelsTable from '../../components/shipmentOrderRecap/ParcelsTable'
import { mapGetters } from 'vuex'

export default {
  name: 'ShipmentDetailParcelCard',
  components: { BaseShowLabel, ParcelsTable },
  computed: {
    ...mapGetters({ shipment: 'shipment/getShipment' })
  }
}
</script>
