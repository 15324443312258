<template>
  <BaseButton
    v-if="typeof c_this_work_task !== 'undefined'"
    :color=" c_color"
    :icon="c_icon"
    :is-outline="column.metadata.outline"
    :title="column.metadata.title"
    @click.stop="updateStatus"
  />
</template>
<script>
/* eslint-disable */
import {mapActions} from "vuex";
import BaseButton from "@/components/BaseButton";
export default {
  name: 'DashboardTaskComponent',
  components:{BaseButton},
  props: {

    column: {},
    model: {}
  },
  computed: {
    c_this_work_task(){
      return this.model.work_tasks.find(item => item.task_id === this.column.metadata.task_id)
    } ,
    c_color() {
      if( typeof this.c_this_work_task !== "undefined"){
        return this.c_this_work_task['status_color']
      }
    },
    c_icon() {
      if( typeof this.c_this_work_task !== "undefined"){
        return this.c_this_work_task['status_icon']
      }
    },
  },
    methods: {
    ...mapActions(['updateWorkTaskStatus']),
      updateStatus() {
        this.updateWorkTaskStatus({
          id:this.c_this_work_task.id,
          successCallback: () => {
            this.$h.toastify(this.$t('global.asset_updated'), { className: 'toastify-content success' })
          }
        })
      }
    },

}
</script>
