<!-- eslint-disable -->
<template>
  <div v-if="editionMode">
    <div v-if="defaultAjax" :class="cGroupClass">
      <BaseEditLabel :label="label" :required="required" />
      <multiselect
        :id="name"
        :key="defaultValue"
        v-model="defaultValue"
        :label="attributeLabel"
        :track-by="trackBy"
        placeholder="Type to search"
        open-direction="bottom"
        :options="options"
        :value="defaultAjax"
        :multiple="false"
        :searchable="true"
        :loading="loading"
        :internal-search="true"
        :clear-on-select="true"
        :close-on-select="true"
        :options-limit="300"
        :limit="10"
        :limit-text="2"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :tabindex="tabindex"
        @search-change="fetchOption"
      >
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="custom__tag"
            ><span>{{ option.name }}</span
            ><span class="custom__remove" @click="remove(option)"
              >❌</span
            ></span
          >
        </template>
      </multiselect>
    </div>
    <template v-if="errors">
      <p
        v-for="(error, index) in errors"
        :key="index"
        class="mt-1 text-red-600 text-xs font-light"
        v-html="error"
      />
    </template>
  </div>

  <div v-else class="mt-3 w-full">
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from '../../../../utils/apiClient'
import multiselect from 'vue-multiselect'
import BaseEditLabel from '../../BaseEditLabel'
import BaseShowLabel from '../../BaseShowLabel'

export default {
  name: 'BaseCityShowEditSelectAjax',
  components: { multiselect, BaseEditLabel, BaseShowLabel },
  props: {
    editionMode: { type: Boolean, required: true },
    required: { type: Boolean, required: false, default: false },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    modelValue: { type: Object, required: false, default: null },
    defaultAjax: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    url: { type: String, required: false, default: null },
    selectLabel: { type: String, required: false, default: null },
    name: { type: String, required: false, default: null },
    isRequired: { type: Boolean, required: false, default: false },
    isDisabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: null },
    groupClass: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    fullModelResponse: { type: Boolean, required: false, default: false },
    queryCountry: { type: String, required: true },
    errors: { type: Object, required: false },
    tabindex: {
      type: Number,
      required: false,
      default: -1
    }
  },
  emits: ['update:modelValue', 'workSelect', 'selected:value'],
  data() {
    return {
      options: [],
      loading: false,
      defaultValue: this.defaultAjax
    }
  },
  computed: {
    cDisplayedValueWhenNotEditionMode() {
      return this.defaultValue.title ? this.defaultValue.title : '-'
    },
    cIsDisabled() {
      return this.isDisabled
    },
    cIsRequired() {
      return this.isRequired
    },
    cGroupClass() {
      return this.groupClass === '' ? 'mt-3 w-full' : this.groupClass
    },
    cLabelClass() {
      return this.labelClass === '' ? 'form-label' : this.labelClass
    }
  },
  watch: {
    defaultValue(newValue) {
      if (newValue != null && newValue !== '') {
        if (this.fullModelResponse) {
          this.$emit('update:modelValue', newValue)
          this.$emit('workSelect', newValue)
        } else {
          this.$emit('update:modelValue', newValue[this.trackBy])
          this.$emit('selected:value', newValue[this.trackBy])
          this.$emit('workSelect', newValue)
        }
      }
    }
  },
  methods: {
    async fetchOption(keyword) {
      if (keyword.length > 2) {
        this.loading = true
        await apiClient
          .get(`${this.url}?q=${keyword}&country_receiver=${this.queryCountry}`)
          .then(async (response) => {
            this.loading = false
            this.options = response.data.results
          })
      }
    }
  }
}
</script>

<style>
.v-select {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74 85 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'/></svg>");
  background-size: 18px;
  background-position: center right 0.6rem;
}

.v-select .vs__dropdown-toggle {
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #cbd5e0;
  background-repeat: no-repeat;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.75rem;
}

.v-select .vs__open-indicator {
  display: none;
}

.v-select .vs__search {
  margin-block: 5px;
}

.v-select .vs__search {
  padding: 0;
}
</style>
