import axios from 'axios'
import store from '../store'
let baseURL
if(process.env.NODE_ENV === 'development'){
    baseURL = 'http://localhost:8080/'
}else{
    baseURL = 'https://api-c2c.my-home-delivery.com/'
}


const apiClient = axios.create({
    baseURL,
    headers:{
        Accept:'application/json'
    }
    // withCredentials: true
})

apiClient.interceptors.request.use(function (config) {

    let token = store.getters['auth/getToken'];
    config.headers.common['Authorization'] = `Bearer ${token}`

    return config
})

apiClient.interceptors.response.use(function (response) {
    return response
}, function (error) {
    // Bugsnag.notify(error)
    if (error.response) {
        switch (error.response.status) {
            case 401:
                // case 419:
                window.location.href = '/connect'
                break;
            case 422:
                // helper.toastify('des erreurs sont présentes dans le formulaire', {className: 'toastify-content warning'})
                store.commit('auth/setErrors', error.response.data.error, {root: true})
                return Promise.reject(error)
            case 404:
                // helper.toastify('la resource que vous rechercher est inexistante ', {className: 'toastify-content danger'})
                break
            case 500:
                // helper.toastify('erreur serveur', {className: 'toastify-content danger'})
                return Promise.reject(error)
            default:
                // alert(error.response.messageerror)
                return Promise.reject(error)
        }
    } else {
        // helper.toastify('erreur serveur', {className: 'toastify-content danger'})
        return Promise.reject(error)
    }
})


export default apiClient
