<template>
  <div class="h-full flex flex-col gap-8 overflow-scroll">
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">Qui est My Home Delivery ?</h2>
      <p class="text-sm">
        My Home Delivery est une société spécialisée dans la livraison aux
        particuliers de colis lourds et/ou encombrants.<br />
        En 2023, My Home Delivery décide d’ouvrir ce service aux particuliers,
        c’est-à-dire permettre à toute personne de faire l’enlèvement de sa
        marchandise soit en magasin, soit chez un particulier et de (se) le
        faire livrer.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Comment s'inscrire My Home Delivery ?
      </h2>
      <p class="text-sm">
        Connectez-vous sur
        <a href="https://particuliers.my-home-delivery.com/" target="_blank"
          >particuliers.my-home-delivery.com</a
        >
        et suivez les instructions pour vous inscrire.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">Comment expédier un colis ?</h2>
      <p class="text-sm">
        Après voir être inscrit, suivez les étapes :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;&nbsp;Adresse d’enlèvement<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;Adresse de livraison<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;&nbsp;Nombre de colis + poids &
        dimensions<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;&nbsp;Date de collecte<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;&nbsp;Date de livraison<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.&nbsp;&nbsp;Procédez au paiement<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.&nbsp;&nbsp;C’est en ordre ! Vous pouvez
        imprimer les étiquette(s) et préparer le(s) colis. Pensez à emballer (+
        lien vers FAQ emballage) correctement votre colis<br />
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">Quel est le coût d'envoi ?</h2>
      <p class="text-sm">
        Le prix est déterminé par :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Le service de livraison
        choisi (au pas de la porte ou dans la pièce)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Le jour et la distance lors
        de la collecte-livraison : Une livraison le jour même à moins de 30 km
        sera moins cher
      </p>
      <div>
        <table class="bg-white w-full border-gray-500 rounded table-auto">
          <tr>
            <th class="border border-gray-200 p-2" colspan="2">
              Type de livraison
            </th>
            <th class="border border-gray-200 p-2" colspan="2">Belgique</th>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2"></td>
            <td class="border border-gray-200 p-2">Poids max</td>
            <td class="border border-gray-200 p-2">moins de 30km</td>
            <td class="border border-gray-200 p-2">plus de 30km</td>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2">Livraison "pas de porte"</td>
            <td class="border border-gray-200 p-2">0-100kg</td>
            <td class="border border-gray-200 p-2 text-right">55 €</td>
            <td class="border border-gray-200 p-2 text-right">75 €</td>
          </tr>
          <tr>
            <td class="border border-gray-200 p-2">Livraison dans la pièce</td>
            <td class="border border-gray-200 p-2">0-100kg</td>
            <td class="border border-gray-200 p-2 text-right">65 €</td>
            <td class="border border-gray-200 p-2 text-right">85 €</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Quels types d’article peuvent etre pris en charge par My Home Delivery ?
        ?
      </h2>
      <p class="text-sm">
        Il s’agit de <u>biens courants</u> : exemples : électroménager, literie,
        mobilier intérieur et extérieur, vélo, ustensile de jardin, article de
        décoration,…<br />
        Nous ne livrons pas de piano par exemple et nous ne sommes pas non plus
        des déménageurs (nous ne livrons pas avec un lift ; s’il faut un lift,
        c’est à vous à vous en occuper).<br />
        Attention, certains articles sont également interdits, consultez nos
        Conditions Générales (+ lien) pour plus de précision.<br />
        Une question, un doute ?
        <a href="mailto:info@my-home-delivery.com">Contactez-nous</a> pour
        s’assurer avant d’encoder votre livraison.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Quels sont le poids et les dimensions autorisés ?
      </h2>
      <p class="text-sm">
        Les livraisons acceptées doivent respecter les critères suivants :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Poids maximal total : 100
        kg<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Longueur maximale par colis :
        350 cm<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Volume maximal total : 2 m³
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Comment emballer mon colis ? <br />
        Il y a-t-il des consignes pour la préparation du colis à envoyer ?
      </h2>
      <p class="text-sm">
        My Home Delivery ne s’occupe pas de l’emballage des colis que vous
        souhaitez collecter / livrer via nos services. L’expéditeur (+ lien
        glossaire) est responsable de l’emballage du ou des colis à
        collecter/livrer.<br /><br />
        <u>ATTENTION</u> : il est <u>obligatoire</u> d’emballer la marchandise
        correctement (voir cahier des recommandations) et d’y apposer le(s)
        étiquettes(s) : 1 étiquette par colis à collecter / livrer.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Quels services sont proposés par my home delivery
      </h2>
      <p class="text-sm">
        My home delivery propose 2 formules de livraison :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;La livraison « pas de porte »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;La livraison dans la pièce
        souhaitée <br />
        Attention ; l’expéditeur doit s’assurer que la marchandise soit
        compatible avec l’usage auquel il est destiné et qu’elle puisse être
        livrée en toute sécurité chez le destinataire et que les accès soient
        suffisamment larges et offrent une hauteur suffisante pour que la
        livraison s’effectue normalement et convenablement. Livraison sans
        ascenseur : maximum 3ème étage.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Jusque quel etage livrez-vous ? (ou collectez-vous)
      </h2>
      <p class="text-sm">
        Si le(s) colis entre(nt) dans l’ascenseur, il n’y a pas de limite
        d’étage.<br />
        Dans le cas contraire, nous livrons jusqu’au
        <u>3ème étage maximum sans ascenseur</u>, avec un
        <u>maximum de 2 colis et un maximum de 60 kg par colis</u>. Et ce, pour
        autant que les accès soient suffisamment larges et offrent une hauteur
        suffisante pour que la livraison s’effectue normalement et
        convenablement.<br />
        (my) home delivery se réserve le droit de refuser une livraison (ou
        collecte) en cas de non-respect des normes d’accès
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Quels pays sont propose par my home delivery
      </h2>
      <p class="text-sm">
        Nos services de livraison sont réservés pour des collectes et livraisons
        en belgique.
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Quels moyen de paiement sur my home delivery ?
      </h2>
      <p class="text-sm">Bancontact, visa, mastercard</p>
    </div>
    <div class="flex flex-col gap-3">
      <h2 class="text-xl font-bold uppercase">
        Mon colis a été livré mais il est endommagé. Comment faire ?
      </h2>
      <p class="text-sm">
        Il y a 2 types de litiges que (my) home delivery prend en charge :<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Colis non retracé (égaré)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Colis endommagé à la suite
        d’une livraison<br /><br />
        Chaque cas doit faire l’objet d’une déclaration du litige via notre
        service clients endéans les 24 heures (1 jour ouvrable) après la
        livraison au destinataire.<br /><br />
        Pour chaque cas, la procédure d’indemnisation sera la suivante :<br />
        <b>• Colis non retracé (égaré)</b><br />
        Prise en charge du litige après une enquête de 15 jours (ouvrables)<br /><br />

        <b>• Colis endommagé à la suite d’une livraison</b><br />
        Pour que le litige soit pris en charge, il faut impérativement que, lors
        de la livraison, le destinataire ait mentionné des remarques / réserves
        via l’application du chauffeur/livreur.<br />
        Ces remarques/réserves doivent être précises et concrètes, avec photos à
        l’appui.<br /><br />

        <u>Liste non exhaustive de litiges non pris en charge</u><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Les produits endommagés sous
        emballage mais non visible lors de la réception ou de la livraison
        (emballage intact de l’extérieur) ne seront pas pris en charge (= vices
        cachés)<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Lorsque la réserve mentionne
        « sous réserve de déballage ».<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;Lorsque l’emballage ou le
        type de produit transporté n’est pas conforme (voir recommandations
        emballages).<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;…<br /><br />

        <u>Montant remboursé</u><br />
        Le montant remboursé est est limité à 5 € par kg de poids brut avariée
        ou perdu, avec un maximum de 50 € par événement ou par série
        d’événements ayant une seule et même cause de dégâts.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqFr'
}
</script>

<style scoped></style>
