import apiClient from '../../../utils/apiClient'
import axios from 'axios'
import store from "@/store";

let baseURL

if(process.env.NODE_ENV === 'development'){
    baseURL = 'http://localhost:8080/'
}else{
    baseURL = 'https://api-c2c.my-home-delivery.com/'
}

export default {
    async logIn({commit}, payload) {
        commit('resetErrors')
        await axios.post(`${baseURL}api/auth/login?username=${payload.username}&password=${payload.password}`)
            .then(async response => {
                if (response.status === 200) {
                    commit('setToken', response.data.token)
                    commit('setExpirationDate', response.data.expires_at)
                }
            }).then(async () => {
                await apiClient.get('/api/c2c/getAuth').then(async (response) => {
                    if (response.status === 200) {
                        commit('setAuth', {
                            auth: response.data.auth,
                        })
                        if (payload.successCallback) {
                            payload.successCallback()
                        }
                    }
                })
            }).catch(async reason => {
                if (reason.response && reason.response.status === 422) {
                    store.commit('auth/setErrors', reason.response.data.error)
                } else if(reason.response && reason.response.status === 403){
                    if (payload.unVerifiedCallback) {
                        payload.unVerifiedCallback()
                    }
                } else if (reason?.response?.status === 401){
                   commit('auth/setErrors', reason.response.data, {root: true})
                }
            else {
                    await console.error('register', reason)
                }
            })
    },

    // eslint-disable-next-line no-unused-vars
    async register({commit}, payload) {
        await axios.post(`${baseURL}api/auth/register_c2c`, payload.form)
            .then(async response => {
                if (response.status === 200) {
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            }).catch(async reason => {
                if (reason.response && reason.response.status === 422) {
                    store.commit('auth/setErrors', reason.response.data.error)
                } else {
                    await console.error('register', reason)
                }
            })
    },

    // eslint-disable-next-line no-unused-vars
    async resendVerificationEmail({commit}, payload){
        await axios.post(`${baseURL}api/auth/resend_verification?username=${payload.username}`)
            .then(async response => {
                if (response.status === 200) {
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            }).catch(async reason => {
                    await console.error('register', reason)
            })
    }

}
