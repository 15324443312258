/* eslint-disable camelcase */
export default {
  setShipmentsIndex(state, shipmentsIndex) {
    state.shipmentsIndex = shipmentsIndex;
  },
  setShipment(state, shipment) {
    state.shipment = shipment;
  },
  setMetadata(state, metadata) {
    state.metadata = metadata;
  },
  setIndexMetadata(state, indexMetadata) {
    state.indexMetadata = indexMetadata;
  },
  setDates(state, dates) {
    state.dates = dates;
  },
  setShipmentPickupType(state, pickupType) {
    state.shipment.pickupType = pickupType;
  },
  setPickupType(state, pickupType) {
    state.pickupType = pickupType;
  },
  setSenderId(state, sender_id) {
    state.shipment.sender_id = sender_id;
  },
  setShipmentServiceCode(state, service_code) {
    state.shipment.service_code = service_code;
  },
  setServiceCode(state, service_code) {
    state.serviceCode = service_code;
  },
  setShipmentParcel(state) {
    state.shipment.parcel = [];
  },
  pushAParcelInShipmentParcels(state) {
    state.shipment.nb_parcels++;
    state.shipment.parcel.push({
      value: 0,
      weight: null,
      length: null,
      width: null,
      height: null,
    });
  },
  popAParcelInShipmentParcels(state) {
    state.shipment.parcel.pop();
    state.shipment.nb_parcels--;
  },
  setPreviousShipmentStepNumber(state, previouShipmentStepNumber) {
    state.previousShipmentStepNumber = previouShipmentStepNumber;
  },
  resetPaymentReattempt(state) {
    state.paymentReattempt = false;
  },
  preparePaymentReattempt(state) {
    state.previousShipmentStepNumber = 8;
    state.paymentReattempt = true;
  },
  setIsSameDay(state, isSameDay) {
    state.isSameDay = isSameDay;
  },
  fillPickupForm(state, payload) {
    state.shipment.pickup_name = payload.name;
    state.shipment.pickup_street = payload.street_nb;
    state.shipment.pickup_post_code = payload.postCode;
    state.shipment.pickup_phone = payload.phone;
    state.shipment.pickup_city = payload.city;
    state.shipment.pickup_city_id = payload.city_id;
    state.shipment.pickup_mobile = payload.mobile;
    state.shipment.pickup_country = payload.country;
    state.shipment.pickup_email = payload.email;
    state.shipment.pickup_language = payload.language;
  },
  resetPickupForm(state) {
    state.shipment.pickup_name = null;
    state.shipment.pickup_street = null;
    state.shipment.pickup_post_code = null;
    state.shipment.pickup_phone = null;
    state.shipment.pickup_city = null;
    state.shipment.pickup_city_id = null;
    state.shipment.pickup_mobile = null;
    state.shipment.pickup_country = null;
    state.shipment.pickup_email = null;
    state.shipment.pickup_language = null;
    state.shipment.pickup_address_id = null;
  },
  fillDeliveryForm(state, payload) {
    state.shipment.name_receiver = payload.name;
    state.shipment.street_receiver = payload.street_nb;
    state.shipment.post_code_receiver = payload.postCode;
    state.shipment.phone_receiver = payload.phone;
    state.shipment.city_receiver = payload.city;
    state.shipment.city_receiver_id = payload.city_id;
    state.shipment.mobile_receiver = payload.mobile;
    state.shipment.country_receiver = payload.country;
    state.shipment.email_receiver = payload.email;
    state.shipment.language_receiver = payload.language;
  },
  resetDeliveryForm(state) {
    state.shipment.name_receiver = null;
    state.shipment.street_receiver = null;
    state.shipment.post_code_receiver = null;
    state.shipment.phone_receiver = null;
    state.shipment.city_receiver = null;
    state.shipment.city_receiver_id = null;
    state.shipment.mobile_receiver = null;
    state.shipment.country_receiver = null;
    state.shipment.email_receiver = null;
    state.shipment.language_receiver = null;
    state.shipment.delivery_address_id = null;
  },
  setPickupFile(state, pickupFile) {
    state.pickupFile = pickupFile;
  },
  setDeliveryFile(state, deliveryFile) {
    state.deliveryFile = deliveryFile;
  },
  setPickupAddressId(state, pickupAddressId) {
    state.pickupAddressId = pickupAddressId;
  },
  setDeliveryAddressId(state, deliveryAddressId) {
    state.deliveryAddressId = deliveryAddressId;
  },
  setCGReaded(state, cgReaded) {
    state.cgReaded = cgReaded;
  },
  resetPickupAddressId(state) {
    state.pickupAddressId = null;
  },
  resetDeliveryAddressId(state) {
    state.deliveryAddressId = null;
  },
};
