<template>
  <BasePageFull>
    <!--#region Body -->
    <template #body>
      <div class="flex-1 p-6 bg-white shadow rounded-lg flex flex-col gap-6">
        <div class="flex-shrink-0 flex flex-col sm:flex-row sm:items-center">
          <h1
            class="flex-1 text-2xl font-semibold text-theme-secondary capitalize-first"
          >
            {{ $t('yourExpeditions') }}
          </h1>
          <div class="flex-shrink-0">
            <BaseButton
              :title="$t('createExpedition')"
              type="success"
              icon="plus"
              class="col-start-3"
              @click="redirectToNewShipmentPage"
            />
          </div>
        </div>
        <template v-if="shipmentsIndex.length !== 0">
          <div class="hidden sm:block flex-1">
            <BaseContentTable
              v-if="metadata.columns"
              :columns="metadata.columns"
              :model-list="shipmentsIndex"
              redirect-to-field="slug"
              :is-hoverable="true"
              :is-responsive="true"
              :is-bordered="false"
              @showDetail="handleShowDetail"
              @printInvoice="handlePrintInvoice"
            />
          </div>
          <div class="flex-1 sm:hidden flex flex-col gap-2">
            <template v-for="shipment in shipmentsIndex" :key="shipment.id">
              <div class="flex-1 border rounded flex flex-col gap-2 p-2">
                <template
                  v-for="column in metadata.columns.filter(
                    (column) =>
                      !column.type || ['label', 'button'].includes(column.type)
                  )"
                  :key="column.field"
                >
                  <template v-if="column.type === 'label'">
                    <div class="flex-shrink-0 flex flex-col">
                      <div class="text-gray-500 text-sm">
                        {{
                          column.translatable
                            ? $t(`${column.title}`)
                            : column.title
                        }}
                      </div>
                      <BaseLabel
                        :icon="column.metadata.icon"
                        :content="shipment[column.metadata.value]"
                        :color="shipment[column.metadata.color]"
                      />
                    </div>
                  </template>
                  <template v-if="column.type === 'button'">
                    <div
                      v-if="
                        shipment[redirectToField] !== null &&
                        (column.metadata.method !== 'printInvoice' ||
                          shipment['invoice_url'] !== null)
                      "
                      class="flex-shrink-0 flex"
                    >
                      <BaseButton
                        :color="column.metadata.type"
                        :icon="column.metadata.icon"
                        :is-outline="column.metadata.outline"
                        :title="column.metadata?.title"
                        @click.stop="handleShowDetail(shipment.slug)"
                      />
                    </div>
                  </template>
                  <div v-else>
                    <div
                      v-if="column.field && column.field.length"
                      class="flex-shrink-0 flex flex-col"
                    >
                      <div class="text-gray-500 text-sm">
                        {{
                          column.translatable
                            ? $t(`${column.title}`)
                            : column.title
                        }}
                      </div>
                      <div v-html="shipment[column.field]" />
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
        <div v-else class="flex-1 flex items-center justify-center">
          <div class="flex">
            <div class="flex-shrink-0 text-green-700">
              <font-awesome-icon
                :icon="['fas', 'exclamation-circle']"
                size="2xl"
              />
            </div>
            <div class="ml-3">
              <h3 class="text-2xl text-green-800 capitalize-first">
                {{ $t('noShipmentsYet') }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--#endregion -->
  </BasePageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import BasePageFull from '../../components/baseComponents/BasePageFull'
import BaseButton from '../../components/BaseButton'
import BaseContentTable from '../../components/baseComponents/table/BaseContentTable'
import printJS from 'print-js'
import BaseLabel from '@/components/baseComponents/Label/BaseLabel.vue'

export default {
  name: 'TheHomePage',
  components: { BaseLabel, BasePageFull, BaseButton, BaseContentTable },
  computed: {
    ...mapGetters({
      shipmentsIndex: 'shipment/getShipmentsIndex',
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getIndexMetadata'
    })
  },
  mounted() {
    this.processFetchShipmentsIndexAction()
    this.processFetchNewShipmentAction()
  },
  methods: {
    ...mapActions({
      processFetchShipmentsIndexAction: 'shipment/fetchShipmentsIndex',
      processFetchShipmentBySlug: 'shipment/fetchShipmentBySlug',
      processFetchNewShipmentAction: 'shipment/fetchNewShipment'
    }),
    redirectToNewShipmentPage() {
      this.$router.push({
        name: 'create_shipment_step_route',
        params: { id: 1 }
      })
    },
    handleShowDetail(val) {
      this.$router.push({ name: 'shipment_item_route', params: { slug: val } })
    },
    async handlePrintInvoice(val) {
      await this.processFetchShipmentBySlug({ slug: val })

      console.log('this.shipment?.invoice_url', this.shipment?.invoice_url)
      printJS(this.shipment?.invoice_url)
    }
  }
}
</script>
