export default {
  getShipmentsIndex(state) {
    return state.shipmentsIndex;
  },
  getShipment(state) {
    return state.shipment;
  },
  getMetadata(state) {
    return state.metadata;
  },
  getDates(state) {
    return state.dates;
  },
  getParcel(state) {
    return state.shipment.parcel;
  },
  getPickupType(state) {
    return state.pickupType;
  },
  getServiceCode(state) {
    return state.serviceCode;
  },
  getPreviousShipmentStepNumber(state) {
    return state.previousShipmentStepNumber;
  },
  getIndexMetadata(state) {
    return state.indexMetadata;
  },
  getIsSameDay(state) {
    return state.isSameDay;
  },
  getPickupFile(state) {
    return state.pickupFile;
  },
  getDeliveryFile(state) {
    return state.deliveryFile;
  },
  getPickupAddressId(state) {
    return state.pickupAddressId;
  },
  getDeliveryAddressId(state) {
    return state.deliveryAddressId;
  },
  getPaymentReattempt(state) {
    return state.paymentReattempt;
  },
  getCgReaded(state) {
    return state.cgReaded;
  },
};
