<template>
  <div class="mt-3 w-full">
    <BaseEditLabel
      :label="label"
      :required="required"
    />
    <input
      id="formFile"
      class="form-control
    block
    w-full
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-green-500 focus:outline-none"
      type="file"
      @change="updateInput"
      placeholder="quelque chose"
    >
  </div>
</template>

<script>
import BaseEditLabel from "../BaseEditLabel";

export default {
  name: "BaseFileInput",
  components: {BaseEditLabel},
  props: {
    label: {type: String, required: false, default: null},
    required: {type: Boolean, required: false, default: false},
    modelValue: {},
  },
  emits: ['update:modelValue', 'fileChange'],
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.files[0])
      this.$emit('fileChange', event.target.files[0])
    },
  }
}
</script>
