/* eslint-disable */
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import authModule from './modules/auth/index'
import shipmentModule from './modules/shipment/index'
import addressModule from './modules/address/index'
import profileModule from './modules/profile/index'
import paymentModule from './modules/payment/index'

const dataState = createPersistedState({
    paths: [
        'auth',
        'shipment',
    ]
})

const store = createStore({

    modules: {
        auth: authModule,
        shipment: shipmentModule,
        address: addressModule,
        profile: profileModule,
        payment: paymentModule,
    },
    plugins: [dataState]
})

export default store
