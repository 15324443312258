/* eslint-disable camelcase */
import { createI18n } from 'vue-i18n/index'

import fr_BE from './assets/translations/fr_BE'
import nl_BE from './assets/translations/nl_BE'
import en_UK from './assets/translations/en_UK'

import store from '@/store'

const messages = {
  fr_BE,
  nl_BE,
  en_UK
}

const user = store.getters['auth/getAuth']

const i18n = createI18n({
  locale: user?.langue ?? 'fr_BE',
  fallbackLocale: user?.langue ?? 'fr_BE',
  messages
})

export default i18n
