<!--eslint-disable-->
<template>
  <div class="flex flex-col p-3">
    <div class="flex flex-row">
      <div class="flex-1 capitalize-first">
        {{ $t('parcel') }} {{ packageNumber + 1 }}
      </div>
      <div class="flex-shrink-0">
        <div
          class="col-start-9 rounded-md shadow-md bg-red-500 items-center px-2 py-1 text-sm text-white cursor-pointer"
          @click="$emit('deletePackage', packageNumber)"
        >
          X
        </div>
      </div>
    </div>
    <div class="flex sm:space-x-3 flex-col sm:flex-row">
      <BaseShowEditButtonInput
        v-model.number="parcel.weight"
        class="sm:col-start-2"
        type="number"
        :label="$t('weight')"
        name="weight"
        secondary-text="kg"
        :errors="errors[`parcel.${packageNumber}.weight`]"
        :edition-mode="true"
      />

      <BaseShowEditButtonInput
        v-model.number="parcel.length"
        :label="$t('length')"
        name="length"
        type="number"
        max="400"
        secondary-text="cm"
        :errors="errors[`parcel.${packageNumber}.length`]"
        :edition-mode="true"
      />

      <BaseShowEditButtonInput
        v-model.number="parcel.height"
        :label="$t('height')"
        name="height"
        type="number"
        max="220"
        secondary-text="cm"
        :errors="errors[`parcel.${packageNumber}.height`]"
        :edition-mode="true"
      />
      <BaseShowEditButtonInput
        v-model.number="parcel.width"
        :label="$t('width')"
        name="width"
        type="number"
        max="400"
        secondary-text="cm"
        :errors="errors[`parcel.${packageNumber}.width`]"
        :edition-mode="true"
      />
    </div>
  </div>
</template>

<script>
import BaseShowEditButtonInput from '../forms/inputs/BaseShowEditButtonInput'
import { mapGetters } from 'vuex'

export default {
  name: 'ParcelCard',
  components: { BaseShowEditButtonInput },
  props: {
    packageNumber: {
      type: Number,
      required: true
    },
    parcel: {
      type: Object,
      required: true
    }
  },
  emits: ['deletePackage'],
  computed: {
    ...mapGetters({ errors: 'auth/getErrors' })
  }
}
</script>
