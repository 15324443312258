export default {
  site: {
    global: {
      jeMInscris: "Je m'inscris"
    },
    section1: {
      livraisonParticulierAParticulier:
        'Livraison de particulier<br />à particulier',
      mhdSpecialiste:
        '(My) Home Delivery est votre spécialiste<br />dans <span class="font-bold">la livraison entre particuliers de colis<br />lourds et/ou encombrants.</span>'
    },
    section2: {
      commentCaFonctionne: 'Comment ça fonctionne ?',
      explication:
        'Vous venez d\'<span class="text-theme-primary">acheter ou de vendre un meuble, un lit, une armoire,</span>...<br />Et vous ne savez pas comment faire pour livrer votre achat/vente?<br /><span class="text-theme-primary">(My) Home Delivery s\'occupe de l\'enlèvement de votre marchandise et sa livraison.</span>'
    },
    section3: {
      title:
        '(My) Home Delivery votre spécialiste dans la livraison entre particuliers de colis lourds et/ou encombrants.'
    },
    section5: {
      title: 'Type de livraisons et jour',
      subTitle:
        'Choisisez votre type de livraison ou de collecte et la date désirée',
      type: {
        pasDePorte: 'Livraison "pas de porte"',
        pieceHabitation: "Livraison dans une pièce d'habitation",
        jourEtDistance: 'jour et distance',
        paiementsSecurises: 'paiements sécurisés'
      },
      footer:
        'Une livraison le jour même à moins de 30km est possible et moins cher !'
    },
    section6: {
      title: 'Comment expédier un colis ?',
      subTitle:
        '<span class="text-theme-primary">C\'est simple et rapide</span><br />Complètez les informations demandées',
      list: {
        1: "Adresse d'enlèvement",
        2: 'Adresse de livraison',
        3: 'Nombre de colis avec les poids et dimensions',
        4: 'Date de collecte',
        5: 'Date de livraison',
        6: 'Paiement sécurisé'
      }
    },
    footer: {
      title: 'Une question ?',
      subTitle: "Pour toutes questions, <br />n'hésitez pas à nous contacter"
    }
  },
  createdAt: 'date',
  forget: 'Vous avez oublié votre mot de passe ?',
  logout: 'se déconnecter',
  addPackage: 'Ajouter un colis',
  Addresses: 'Adresses',
  afterOrderInstruction:
    "les informations concernant l'enlèvement et la livraison vous ont été envoyées par email",
  afterOrderInstructionTwo:
    "un sms et un email contenant le créneau de deux heures vous seront envoyé la veille de l'enlèvement et de la livraison",
  agency: 'agence',
  back: 'retour',
  backToIndex: "retour vers l'accueil",
  brand: 'partenaire',
  business: 'professionnel',
  cancel: 'annuler',
  cancelEditPassword: 'annuler les modifications du mot de passe',
  city: 'ville',
  collection: 'Enlèvement',
  collectionDate: "date d'enlèvement",
  companyName: 'nom de la société',
  confirmAndSend: 'confirmer la commande',
  confirmPassword: 'confirmation du mot de passe',
  country: 'pays',
  customerType: 'type de client',
  delivery: 'Livraison',
  deliveryAddress: 'adresse de livraison',
  deliveryDate: 'date de livraison',
  deliveryDifferentDay: 'enlèvement et livraison à des dates différentes',
  deliveryInstruction: 'instruction de livraison',
  deliverySameDay: 'enlèvement et livraison le même jour',
  deliveryServices: 'Services de livraison',
  deliveryType: 'type de livraison',
  edit: 'modifier',
  editPassword: 'modifier le mot de passe',
  eMail: 'e-mail',
  firstName: 'prénom',
  height: 'hauteur',
  howManyPackage: 'combien de colis ?',
  joinAFile: 'joindre un fichier',
  language: 'langue',
  lastName: 'nom de famille',
  length: 'longueur',
  login: 'identifiant',
  mobile: 'portable',
  name: 'nom',
  fullname: 'nom et prénom',
  needEmailVerification:
    "Il semble que vous n'ayez pas confirmé votre adresse email, veuillez le faire en cliquant sur le lien dans l'email que vous avez reçu lors de la création de votre compte.",
  newExpedition: 'nouvelle expédition',
  createExpedition: 'créer une expédition',
  next: 'suivant',
  noShipmentsYet: "Vous n'avez pas encore d'expédition dans votre historique.",
  number: 'numéro',
  ourPartners: 'nos partenaires',
  package: 'Colis',
  packageQuantity: 'nombre de colis',
  parcel: 'colis',
  partner: 'partenaire',
  password: 'mot de passe',
  phone: 'téléphone',
  pickup: 'enlèvement',
  pickupAddress: "adresse d'enlèvement",
  pickupAndDeliveryDate: "date d'enlèvement et livraison",
  pickupDate: "date d'enlèvement",
  postCode: 'Code postal',
  pickupInstruction: 'instruction de livraison',
  price: 'prix (€)',
  private: 'particulier',
  profile: 'profil',
  reference: 'reference',
  resendVerifiedMessage:
    'Le message de confirmation vous a été envoyé à nouveau.',
  service: 'service',
  shipments: 'expéditions',
  signIn: 'se connecter',
  signUp: 'inscrivez-vous',
  status: 'statut',
  stepXOnY: 'étape {x} sur {y}',
  street: 'rue et numéro',
  thanksForYourOrder: 'merci, votre commande a été enregistrée!',
  toACustomAddress: 'Chez un particulier',
  toAPartner: 'Dans un magasin-partenaire',
  unavailableForTheMoment: "Pas disponible pour l'instant",
  totalToPay: 'total à payer:',
  TVA: 'TVA',
  totalVolume: 'volume total:',
  totalWeight: 'poids total:',
  userName: "nom d'utilisateur",
  value: 'valeur',
  verrifySpam: 'Veuillez également vérifier vos courriers indésirables (spam).',
  volumeTotal: 'volume total (m³)',
  weight: 'poids',
  weightTotal: 'poids total (kg)',
  whatDeliveryType: 'quelle type de livraison souhaitez-vous ?',
  whereToCollectPackage: 'où doit on enlever votre (vos) colis ?',
  width: 'largeur',
  yourAddresses: 'vos adresses',
  useYourAddresses: 'réutiliser une de vos adresses',
  yourInfos: 'vos informations',
  yourOrder: 'votre commande',
  yourExpeditions: 'vos expéditions',
  sendMailAgain: 'recevoir à nouveau cet email',
  pleaseConfirmYourMail:
    'Votre demande a bien été envoyée, veuillez confirmer la création de votre compte en cliquant sur le lien dans le mail que vous avez reçu.',
  addresses: 'adresses',
  address: 'adresse',
  processPayment: ' paiement',
  payAmount: 'payer {amount} €',

  instructions: 'instructions',
  noAddressYet: "Vous n'avez pas encore d'adresses dans votre répertoire.",
  cgAcceptation: "J'ai lu et j'accepte les conditions générales",

  addresseType: {
    0: 'enlèvement',
    1: 'livraison'
  },
  not_yet_an_account: "Vous n'avez pas encore de compte ?",
  already_an_account: 'Vous avez déjà un compte ?',
  click_here: 'cliquez-ici',
  selected: 'selectionné'
}
