import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './i18n'
import moment from 'moment'
moment.locale('fr')
import fontawesome from "@/components/plugins/fontawesome";

import './assets/app.scss'

const app =  createApp(App)
    .use(store)
    .use(router)
    .use(i18n)

app.config.globalProperties.$moment = moment
fontawesome(app)
router.isReady().then(() => {
    app.mount('#app')
})
