<template>
  <div class="mt-2 relative rounded-md border border-gray-100 bg-gray-100 px-3 py-2 shadow-sm">
    <label
      v-if="label"
      class="capitalize-first absolute -top-2 left-2 -mt-px inline-block bg-white rounded px-1 text-xs font-medium text-gray-900"
    >{{ label }}</label>
    <div class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm">
      {{ modelValue === null || modelValue === '' ? '-' : modelValue }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'BaseShowLabel',
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    ddClass: {
      type: String,
      required: false,
      default: ''
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    cLabelClass() {
      return this.labelClass === '' ? '' : this.labelClass
    },
  }
}
</script>
