<template>
  <BasePageFull>
    <template #body>
      <div class="flex-1 p-6 bg-white overflow-hidden shadow rounded-lg flex flex-col gap-6">
        <template v-if="addressesList?.length !== 0">
          <div class="flex-shrink-0 flex flex-row items-center">
            <h1 class="flex-1 text-2xl font-semibold text-theme-secondary capitalize-first">
              {{ $t('yourAddresses') }}
            </h1>
          </div>
          <div class="flex-1">
            <BaseContentTable
              v-if="metadata?.columns"
              :columns="metadata.columns"
              :model-list="addressesList"
              redirect-to-field="id"
              :is-hoverable="true"
              :is-responsive="true"
              @deleteAddress="deleteModelById"
            />
          </div>
        </template>
        <template v-if="addressesList?.length === 0">
          <div class="mt-8 w-4/6 mx-auto rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0 text-green-700">
                <font-awesome-icon
                  :icon="['fas','exclamation-circle']"
                  size="2xl"
                />
              </div>
              <div class="ml-3">
                <h3 class="text-2xl text-green-800 capitalize-first">
                  {{ $t('noAddressYet') }}
                </h3>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </BasePageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import BasePageFull from "../../components/baseComponents/BasePageFull";
import BaseContentTable from "../../components/baseComponents/table/BaseContentTable";
export default {
  name: "TheAddressPage",
  components:{BasePageFull,BaseContentTable},
  computed: {
    ...mapGetters({
      addressesList: 'address/getAddressesIndex',
      metadata: 'address/getMetadata'
    })
  },

  beforeMount() {
    this.processFetchAddressesIndexAction()
  },
  methods: {
  ...mapActions({
    processFetchAddressesIndexAction: 'address/fetchAddressesIndex',
    processDeleteAddressAction: 'address/deleteAddress'
  }),
    deleteModelById(address) {
      this.processDeleteAddressAction(address)
    }
  },

}
</script>

