<template>
  <div class="relative w-screen h-screen flex flex-col">
    <div class="flex-shrink-0 sticky top-0 z-50">
      <TheNavBar />
    </div>
    <div class="flex-1 min-h-0 flex">
      <div
        class="flex-1 min-w-0 overflow-y-scroll max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import TheNavBar from '../navBar/TheNavBar'
export default {
  name: 'TheLayout',
  components: { TheNavBar }
}
</script>

<style scoped></style>
