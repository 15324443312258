<template>
  <span v-html="cellValue" />
</template>
<script>
/* eslint-disable */
export default {
  name: 'ContentTableCell',
  props: {
    column: {},
    model: {},
  },
  computed:{
   cellValue(){
     if( this.column.type === 'date' && this.model[this.column.field] ){
       return this.model[this.column.field]['label']
     }
     if(!this.column.type && [0,1].includes(this.model[this.column.field]) ){
       return this.$t(`addresseType.${this.model[this.column.field]}`)
     }
      return  this.model[this.column.field]
    }
  },
}
</script>
