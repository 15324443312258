import apiClient from '../../../utils/apiClient'

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    async updateUser({commit}, payload) {
        await apiClient.put('/api/c2c/profile', payload.profile)
            .then(async response => {
                if (response.status === 200) {
                    commit('auth/setAuth', {auth: response.data.auth,}, {root: true})
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                console.error("error updateUser", reason)
            })
    },
    async logout({commit}, payload) {
        await apiClient.post('/api/c2c/logout', payload.profile)
            .then(async response => {
                if (response.status === 200) {
                    commit('auth/setToken', null, {root: true})
                    commit('auth/setExpirationDate', null, {root: true})
                    commit('auth/setAuth', {}, {root: true})
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                console.error("error updateUser", reason)
            })
    },

}
