/* eslint-disable camelcase */
export default {
    setPaymentMethodId(state, payment_method_id){
        state.purchase.payment_method_id = payment_method_id
    },
    setPayment(state, payment){
        state.payment = payment
    },
    resetPayment(state){
        state.payment = {}
    },
    setCart(state, cart){
        state.purchase.cart = cart
    },
    setAmount(state, amount){
        state.purchase.amount = amount
    },
    setCustomerId(state, customer_id){
        state.purchase.customer_id = customer_id
    },
    setShipmentId(state, shipment_id){
        state.purchase.shipment_id = shipment_id
    },
    setIntent(state, intent){
        state.intent = intent
    },
}
