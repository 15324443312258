<template>
  <div v-if="metadata" class="px-4 py-5 sm:p-6 flex flex-col gap-6">
    <div>
      <h3
        class="text-xl leading-6 font-medium text-theme-secondary capitalize-first"
      >
        {{ $t('newExpedition') }}
      </h3>
      <p class="mb-12">
        {{ $t('whereToCollectPackage') }}
      </p>
    </div>
    <div class="flex flex-col gap-6 sm:gap-0 sm:flex-row justify-around">
      <div class="flex flex-col sm:w-1/3">
        <button
          v-if="metadata?.brands?.length > 0"
          class="border rounded px-6 py-4 flex flex-col items-center gap-3 bg-theme-primary"
          @click="selectPickupType('address')"
        >
          <FontAwesomeIcon :icon="['fal', 'house-user']" size="2x" />
          <span>{{ $t('toACustomAddress') }}</span>
        </button>
      </div>
      <div class="flex flex-col sm:w-1/3">
        <button
          v-if="metadata?.brands?.length > 0"
          disabled
          class="border rounded px-6 py-4 flex flex-col items-center gap-3 bg-theme-primary cursor-not-allowed"
          @click="selectPickupType('agency')"
        >
          <FontAwesomeIcon :icon="['fal', 'cart-shopping']" size="2x" />
          <span>{{ $t('toAPartner') }}</span>
        </button>
        <div class="text-sm text-gray-400 text-center">
          {{ $t('unavailableForTheMoment') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ShipmentStepPickupType',
  components: { FontAwesomeIcon },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getMetadata',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber'
    }),
    userIsComingFromACorrectStep() {
      if (this.previousShipmentStepNumber !== null) {
        if (
          this.previousShipmentStepNumber !== parseInt(this.$route.params.id)
        ) {
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) - 1
          ) {
            return true
          }
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) + 1
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if (this.userIsComingFromACorrectStep) {
      this.processSetPreviousShipmentStepNumberMutation(
        parseInt(this.$route.params.id)
      )
    } else {
      if (this.previousShipmentStepNumber !== this.$route.params.id) {
        this.previousShipmentStepNumber === null
          ? this.$router.push({ name: 'create_shipment_route' })
          : this.$router.push({
              name: '',
              params: { id: this.previousShipmentStepNumber }
            })
      }
    }
  },
  methods: {
    ...mapMutations({
      processSetShipmentPickupTypeMutation: 'shipment/setShipmentPickupType',
      processSetPickupTypeMutation: 'shipment/setPickupType',
      processSetPreviousShipmentStepNumberMutation:
        'shipment/setPreviousShipmentStepNumber'
    }),
    selectPickupType(pickupType) {
      this.processSetShipmentPickupTypeMutation(pickupType)
      this.processSetPickupTypeMutation(pickupType)
      this.$router.push({
        name: 'create_shipment_step_route',
        params: { id: 2 }
      })
    }
  }
}
</script>
