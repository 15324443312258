<template>
  <template v-if="editionMode">
    <div>
      <BaseEditLabel :label="label" :required="required" />
      <fieldset class="mt-4">
        <legend class="sr-only">
          {{ label }}
        </legend>
        <div class="space-y-2" :class="isVerticalClass">
          <div
            v-for="(val, key) in options"
            :key="key"
            class="flex items-center"
            :class="[isBordered ? 'border rounded-lg px-3 py-3' : '']"
          >
            <input
              :id="`${name}-${key}-${extraId}`"
              :value="trackBy === null ? val : val[trackBy]"
              :name="tempName"
              type="radio"
              :checked="modelValue === (trackBy === null ? val : val[trackBy])"
              class="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 mr-2"
              @change="updateInput"
            />
            <label
              class="form-check-label font-light capitalize-first cursor-pointer"
              :for="`${name}-${key}-${extraId}`"
            >
              <span class="capitalize-first">
                {{
                  translatable
                    ? $t(`${trackBy === null ? key : val[attributeLabel]}`)
                    : val[attributeLabel]
                }}</span
              >
            </label>
          </div>
        </div>
      </fieldset>
      <template v-if="errors">
        <div
          v-for="(error, index) in errors"
          :key="index"
          class="form-help text-red-600"
        >
          {{ $t(error, { attribute: $t('attributes.' + name) }) }}
        </div>
      </template>
    </div>
  </template>

  <template v-else>
    <BaseEditLabel :label="label" :required="required" /><br />
    <font-awesome-icon
      :icon="[`fas`, `check-square`]"
      class="mr-2 text-theme-secondary mt-2"
    />
    {{
      translatable
        ? $t(`${options?.find((t) => t.id == modelValue).name}`)
        : `${options?.find((t) => t.id == modelValue).name}`
    }}
  </template>
</template>

<script>
import BaseEditLabel from '../BaseEditLabel'

export default {
  name: 'BaseShowEditRadioGroup',
  components: { BaseEditLabel },
  props: {
    options: {
      type: [Array, Object],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    trackBy: {
      type: String,
      required: false,
      default: null
    },
    translatable: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    extraId: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    isNumber: {
      type: Boolean,
      default: false,
      required: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false
    },
    isBordered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      tempName: this.name + this.extraId
    }
  },
  computed: {
    isVerticalClass() {
      return this.isVertical
        ? ''
        : 'sm:flex sm:items-center sm:space-y-0 sm:space-x-5'
    }
  },
  methods: {
    updateInput(event) {
      let modelValue = this.isNumber
        ? Number(event.target.value)
        : event.target.value
      this.$emit('update:modelValue', modelValue)
      this.$emit('change')
    }
  }
}
</script>

<style>
.form-check-input[type='radio']:checked {
  border-color: #359937ff !important;
  background-color: #359937ff !important;
  /*box-shadow: #357699 !important;*/
}
.form-check-input[type='radio']:focus {
  /*border-color: #357699 !important;*/
  /*background-color: #357699 !important;*/
  box-shadow: #359937ff !important;
}
</style>
