<template>
  <div
    class="px-4 py-5 sm:p-6 flex flex-col gap-6"
  >
    <div>
      <h3 class="text-xl leading-6 font-medium text-theme-secondary capitalize-first">
        {{ $t('partner') }}
      </h3>
    </div>
    <div class="grid md:grid-cols-3 sm:grid-cols-1">
      <div class="md:col-start-2 sm:col-start-1 overflow-visible">
        <BaseShowEditSelectSimple
          v-model="shipment.brand_id"
          :label="$t('brand')"
          :placeholder="$t('brand')"
          name="brand"
          :edition-mode="true"
          :required="true"
          :options="metadata.brands"
          :errors="errors?.brand"
        />
      </div>
      <div class="md:col-start-2 sm:col-start-1">
        <BaseShowEditSelectSimple
          v-if="shipment.brand_id"
          v-model="shipment.pickup_agency_id"
          :label="$t('agency')"
          :placeholder="$t('agency')"
          name="brand"
          :edition-mode="true"
          :required="true"
          :options="agencyOptions"
          :errors="errors?.brand"
        />
      </div>
    </div>
    <div
      v-if="metadata?.brands.length > 0"
    >
      <h3 class="mb-5 mt-3 text-lg leading-6 font-medium text-theme-secondary capitalize-first">
        {{ $t('ourPartners') }}
      </h3>
      <div
        class="md:w-2/3 container mx-auto"
      >
        <Carousel
          v-if="metadata?.brands?.length"
          :items-to-show="3"
          :autoplay="3000"
          :wrap-around="true"
        >
          <slide
            v-for="(brand, index) in metadata?.brands"
            :key="index"
            class="mx-2"
          >
            <div
              class="mx-3"
            >
              <img
                :src="brand?.media_url"
                width="200"
                class="max-h-20 max-w-20"
                :alt="brand.name"
              >
            </div>
          </slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import BaseShowEditSelectSimple from "../../../../components/forms/selects/simple/BaseShowEditSelectSimple";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide,  } from 'vue3-carousel';

export default {
  name: "ShipmentStepPartnerPickUpForm",
  components: {
    BaseShowEditSelectSimple,
    Carousel,
    Slide,
    // Pagination,
    // Navigation,
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getMetadata',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber'
    }),
    brandModel(){
      if(!this.shipment.brand_id){
        return
      }
      return this.metadata?.brands?.find(brand => brand.id === this.shipment.brand_id)
    },
    agencyOptions() {
      if (!this.shipment.brand_id) {
        return
      }
      return this.metadata?.agencies?.filter(agency => agency.customer_id === this.brandModel?.customer_id)
    },
    userIsComingFromACorrectStep(){
      if(this.previousShipmentStepNumber !== null){
        if (this.previousShipmentStepNumber !== parseInt(this.$route.params.id)){
          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) - 1)){
            return true
          }
          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) + 1)){
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if(this.userIsComingFromACorrectStep){
      this.processSetPreviousShipmentStepNumberMutation(parseInt(this.$route.params.id))
    } else {
      if(this.previousShipmentStepNumber !== this.$route.params.id){
        this.previousShipmentStepNumber === null ? this.$router.push({name: 'create_shipment_route'}) : this.$router.push({name: '', params: {id: this.previousShipmentStepNumber}})
      }
    }
  },
  methods:{
    ...mapMutations({
      processSetPreviousShipmentStepNumberMutation: 'shipment/setPreviousShipmentStepNumber',
    }),
  }
}
</script>
