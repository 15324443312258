/* eslint-disable no-unsafe-negation,camelcase,no-unused-vars */
import apiClient from "../../../utils/apiClient";
import shipment from "@/store/modules/shipment/index";

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback();
  }
}
function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (typeof object[key] !== "object") formData.append(key, object[key]);
    else formData.append(key, JSON.stringify(object[key]));
  });
  return formData;
}
export default {
  async fetchShipmentsIndex({ commit }) {
    await apiClient
      .get(`api/c2c/shipment`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit("setShipmentsIndex", response.data.shipments);
          await commit("setIndexMetadata", response.data.metadata);
        }
      })
      .catch(async (reason) => {
        await console.error("fetchShipmentsIndex", reason);
      });
  },
  async fetchNewShipment({ commit }) {
    commit("auth/resetErrors", null, { root: true });
    commit("resetPickupAddressId");
    commit("resetDeliveryAddressId");
    await apiClient
      .get(`api/c2c/shipment/new`)
      .then(async (response) => {
        if (response.status === 200) {
          commit("setShipment", {
            ...response.data.shipment,
            pickup_country: "BE",
          });
          commit("setMetadata", response.data.metadata);
          commit("setShipmentParcel");
          commit("setPickupType", null);
          commit("setServiceCode", null);
          commit("setPreviousShipmentStepNumber", 0);
          commit("setIsSameDay", null);
          commit("resetPaymentReattempt");
          commit("payment/resetPayment", null, { root: true });
        }
      })
      .catch(async (reason) => {
        await console.error("fetchNewShipment", reason);
      });
  },
  async fetchShipmentBySlug({ commit }, payload) {
    commit("auth/resetErrors", null, { root: true });
    await apiClient
      .get(`api/c2c/shipment/${payload.slug}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit("setShipment", response.data.shipment);
        }
      })
      .catch(async (reason) => {
        await console.error("fetchNewShipment", reason);
      });
  },
  async fetchDates({ commit }, payload) {
    await apiClient
      .post(`api/c2c/shipment/get-dates`, payload.shipment)
      .then(async (response) => {
        if (response.status === 200) {
          commit("setDates", response.data.dates);
        }
      })
      .catch(async (reason) => {
        await console.error("fetchPickupDate", reason);
      });
  },
  async fetchShipmentResume({ commit, getters }, payload) {
    commit("setShipment", {});
    if (
      !"pickupType" in payload.shipment ||
      payload.shipment.pickupType == null
    ) {
      payload.shipment.pickupType = getters.getPickupType;
    }

    if (
      !"service_code" in payload.shipment ||
      payload.shipment.service_code == null
    ) {
      payload.shipment.service_code = getters.getServiceCode;
    }

    if (
      !"pickup_address_id" in payload.shipment ||
      payload.shipment.pickup_address_id == null
    ) {
      payload.shipment.pickup_address_id = getters.getPickupAddressId;
    }

    if (
      !"delivery_address_id" in payload.shipment ||
      payload.shipment.delivery_address_id == null
    ) {
      payload.shipment.delivery_address_id = getters.getDeliveryAddressId;
    }

    apiClient
      .post(`api/c2c/shipment/get-resume`, payload.shipment)
      .then(async (response) => {
        if (response.status === 201) {
          commit("setShipment", response.data.shipment);
        }
      })
      .catch(async (reason) => {
        await console.error("fetchShipmentResume", reason);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async postShipment({ commit, getters }, payload) {
    if (
      !"pickup_address_id" in payload.shipment ||
      payload.shipment.pickup_address_id == null
    ) {
      payload.shipment.pickup_address_id = getters.getPickupAddressId;
    }

    if (
      !"delivery_address_id" in payload.shipment ||
      payload.shipment.delivery_address_id == null
    ) {
      payload.shipment.delivery_address_id = getters.getDeliveryAddressId;
    }
    // commit('setShipment', {})
    console.log("postShipment", payload.shipment);

    // JSON.stringify(object[key])
    let formData = new FormData();
    // for ( var key in payload.shipment ) {
    //     formData.append(key, payload.shipment[key]);
    // }
    formData.append("delivery_file", getters.getDeliveryFile);
    // formData.append('pickup_file',getters.getPickupFile)
    formData.append("data", JSON.stringify(payload.shipment));
    await apiClient
      .post(`api/c2c/shipment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (response) => {
        console.log("in response");
        if (response.status === 201) {
          await commit("payment/setPayment", response.data.payment_intent, {
            root: true,
          });
          // await console.log('setPayment', response.data.payment_intent)
          processSuccessCallback(payload);
        }
      })
      .catch(async (reason) => {
        await console.error("postShipment", reason);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async postFormForValidation({ commit }, payload) {
    await apiClient
      .post(
        `api/c2c/shipment/validate-form?type=${payload.type}`,
        payload.shipment
      )
      .then(async (response) => {
        if (response.status === 200) {
          processSuccessCallback(payload);
        }
      })
      .catch(async (reason) => {
        await console.error("postFormForValidation: ", reason);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async postFile({ commit }, payload) {
    await apiClient
      .post(`api/`)
      .then(async (response) => {
        await console.log("postFile", response);
      })
      .catch(async (reason) => {
        await console.log("postFile", reason);
      });
  },

  async parcelValidation({ commit }, payload) {
    await apiClient
      .post(`api/c2c/shipment/validate-form?type=parcel`, payload.shipment)
      .then(async (response) => {
        await console.log("parcelValidation", response);
        if (response.status === 200) {
          processSuccessCallback(payload);
        }
      })
      .catch(async (reason) => {
        await console.error("parcelValidation", reason);
      });
  },
};
