export default {
  site: {
    global: {
      jeMInscris: 'Ik schrijf me in '
    },
    section1: {
      livraisonParticulierAParticulier:
        'Levering van particulier<br />naar particulier',
      mhdSpecialiste:
        '(My) Home Delivery is de specialist<br /> in het <span class="font-bold">bezorgen van zware en/of omvangrijke<br /> pakketten aan particulieren.</span>'
    },
    section2: {
      commentCaFonctionne: 'Hoe het werkt ?',
      explication:
        'U heeft net <span class="text-theme-primary">een meubel, een bed, een kast,</span> gekocht of verkocht...<br />En weet u niet hoe u uw aan-/verkoop moet bezorgen?<br /><span class="text-theme-primary">(My) Home Delivery zorgt voor het ophalen en afleveren van uw goederen.</span>'
    },
    section3: {
      title:
        '(My) Home Delivery uw specialist in de bezorging tussen particulieren van zware en/of omvangrijke pakketten.'
    },
    section5: {
      title: 'Soort leveringen en leveringsdag',
      subTitle: 'Kies uw type bezorging of afhaling en de gewenste datum',
      type: {
        pasDePorte: 'Levering aan de deur',
        pieceHabitation: 'Levering in de gebruikteruimte',
        jourEtDistance: 'dag en afstand',
        paiementsSecurises: 'veilige betalingen'
      },
      footer: 'Zelfde dag levering binnen 30km is mogelijk en goedkoper!'
    },
    section6: {
      title: 'Hoe een pakket verzenden?',
      subTitle:
        '<span class="text-theme-primary">Het is snel en gemakkelijk</span><br />Vul de gevraagde gegevens in',
      list: {
        1: 'Ophaaladres',
        2: 'Afleveradres',
        3: 'Aantal pakketten met gewichten en afmetingen',
        4: 'Datum van afhaling',
        5: 'Leveringsdatum',
        6: 'Veilig betalen'
      }
    },
    footer: {
      title: 'Een vraag ?',
      subTitle:
        'Voor alle vragen, <br />aarzel niet om contact met ons op te nemen'
    }
  },
  createdAt: 'datum',
  forget: 'Uw wachtwoord vergeten ?',
  logout: 'logout',
  addPackage: 'Een pakket toevoegen',
  address: 'adres',
  Addresses: 'Adressen',
  afterOrderInstruction:
    'de informatie met betrekking tot het ophalen en de levering worden per e-mail naar u verzonden',
  afterOrderInstructionTwo:
    'een sms en een e-mail met het tijdslot van twee uur worden de dag voor ophaling en levering naar u verzonden.',
  agency: 'agentschap',
  back: 'retour',
  backToIndex: 'terug naar hoofdmenu',
  brand: 'partner',
  business: 'professioneel',
  cancel: 'annuleren',
  cancelEditPassword: 'wachtwoordwijzigingen annuleren',
  city: 'stad',
  collection: 'ophaling',
  collectionDate: 'ophalingsdatum',
  companyName: 'bedrijfsnaam',
  confirmAndSend: 'bevestigen en verzenden',
  confirmPassword: 'wachtwoordbevestiging',
  country: 'land',
  customerType: 'klant type',
  delivery: 'Levering',
  deliveryAddress: 'Leveringsadres',
  deliveryDate: 'Leveringsdatum',
  deliveryDifferentDay: 'ophalen en bezorgen op verschillende datums',
  deliveryInstruction: 'leveringsinstructie',
  deliverySameDay: 'dezelfde dag ophalen en bezorgen',
  deliveryServices: 'Bezorgdiensten',
  deliveryType: 'soort levering',
  edit: 'bewerken',
  editPassword: 'verander het wachtwoord',
  eMail: 'e-mail',
  firstName: 'voornaam',
  height: 'hoogte',
  howManyPackage: 'hoeveel pakketten?',
  joinAFile: 'een bestand toevoegen',
  language: 'taal',
  lastName: 'Naam',
  length: 'lengte',
  login: 'login',
  mobile: 'mobiel',
  name: 'naam',
  fullname: 'naam en voornaam',
  needEmailVerification:
    'Het lijkt erop dat je je e-mailadres nog niet hebt bevestigd. Doe dit door op de link te klikken in de e-mail die je hebt ontvangen bij het aanmaken van je account.',
  newExpedition: 'nieuwe expeditie',
  createExpedition: 'maak een expeditie',
  next: 'volgende',
  noShipmentsYet: 'U heeft nog geen zendingen in uw geschiedenis.',
  number: 'nummer',
  ourPartners: 'onze partners',
  package: 'pakket',
  packageQuantity: 'aantal pakketten',
  parcel: 'pakket',
  partner: 'partner',
  password: 'wachtwoord',
  phone: 'telefoon',
  pickup: 'ophaling',
  pickupAddress: 'ophalingsadres',
  pickupAndDeliveryDate: 'ophaal- en leveringsdatum',
  pickupDate: 'ophalinsdatum',
  pickupInstruction: 'ophaal instructie',
  postCode: 'postcode',
  price: 'prijs',
  private: 'particulier',
  profile: 'profiel',
  reference: 'referentie',
  resendVerifiedMessage:
    'Het bevestigingsbericht werd opnieuw naar u verzonden.',
  service: 'dienst',
  shipments: 'expedities',
  signIn: 'Inloggen',
  signUp: 'registreren',
  status: 'toestand',
  stepXOnY: 'stap {x} op {y}',
  street: 'straat en nummer',
  thanksForYourOrder: 'Bedankt, je bestelling werd geregistreerd!',
  toACustomAddress: 'naar een ander adres',
  toAPartner: 'bij één van onze partners',
  unavailableForTheMoment: 'Momenteel niet beschikbaar',
  totalToPay: 'totaal te betalen:',
  TVA: 'BTW',
  totalVolume: 'totaal volume:',
  totalWeight: 'totaal gewicht',
  userName: 'gebruikersnaam',
  value: 'waarde',
  verrifySpam: 'Controleer ook uw ongewenste e-mail (spam).',
  volumeTotal: 'totaal volume (m³)',
  weight: 'gewicht',
  weightTotal: 'totaal gewicht (kg)',
  whatDeliveryType: 'wat voor soort levering wilt u?',
  whereToCollectPackage: 'waar moeten we uw pakket(ten) ophalen?',
  width: 'breedte',
  yourAddresses: 'uw adressen',
  useYourAddresses: 'een van uw adressen hergebruiken',
  yourInfos: 'uw informaties',
  yourOrder: 'uw bestelling',
  yourExpeditions: 'uw zendingen',
  sendMailAgain: 'ontvang opnieuw deze e-mail ',
  pleaseConfirmYourMail:
    'Uw aanvraag werd verzonden, bevestig het aanmaken van uw account door op de link in de ontvangen e-mail te klikken.',
  cgAcceptation: 'Ik heb de algemene voorwaarden gelezen en accepteer ze.',
  addresseType: {
    0: 'pickup',
    1: 'delivery'
  },
  not_yet_an_account: 'Heb je nog geen account ?',
  already_an_account: 'I heb een account ?',
  click_here: 'klik hier',
  selected: 'geselecteerd'
}
