<template>
  <th
    class="text-left border-b dark:border-dark-5 whitespace-nowrap capitalize-first text-theme-secondary"
    :class="thClasses"
    :width="thWidth"
    @click="sortColumn"
  >
    {{ c_title }}
    <font-awesome-icon
      v-if="column.sorter && sortMode !== '-'"
      :icon="['fas', sortIcon]"
      class="ml-2"
    />
  </th>
</template>
<script>
/* eslint-disable */
export default {
  name: 'BaseThComponent',
  props: {
    column: {},
    thClasses: {},
    currentSort: {},
    width: {default: ''}
  },
  data() {
    return {
      sortMode: '-'
    }
  },
  computed: {
    sortIcon() {
      if (this.sortMode !== '-') {
        return 'sort-' + this.sortMode
      }
    },
    thWidth() {
      if ('width' in this.column && this.column.width != null) {
        return this.column.width
      }
      return this.column.type === 'button' ? '2%' : ''
    },
    c_title() {
      if (!this.column.title  ) return '';
      if (this.column.translatable) return this.$t(`${this.column.title}`)
      return this.column.title
    }
  },
  watch: {
    currentSort(newValue, oldValue) {
      if (newValue !== this.column.field) {
        this.sortMode = '-'
      }
    }
  },
  methods: {
    emitSortColumn() {
      this.$emit('sort-column', {
        column: this.column.field,
        direction: this.sortMode,
        type: this.column.type
      })
    },
    sortColumn() {

      if (this.sortMode === 'down') {
        this.sortMode = 'up'
        this.emitSortColumn();
      } else if (this.sortMode === 'up') {
        this.sortMode = 'down'
        this.emitSortColumn();
      } else {
        this.sortMode = 'up'
        this.emitSortColumn();
      }
    }
  }
}
</script>
