<template>
  <div class="h-full flex">
    <BasePageHeader v-if="$slots.cta || $slots.title">
      <BasePageTitle v-if="$slots.title">
        <slot name="title" />
      </BasePageTitle>
      <BasePageHeaderActions v-if="$slots.cta">
        <slot name="cta" />
      </BasePageHeaderActions>
    </BasePageHeader>

    <BasePageBody>
      <slot name="body" />
    </BasePageBody>
  </div>
</template>
<script>
import BasePageBody from './page/body/BasePageBody'
import BasePageHeader from './page/header/BasePageHeader'
import BasePageHeaderActions from './page/header/BasePageHeaderActions'
import BasePageTitle from './page/header/BasePageTitle'

export default {
  name: 'BasePageFull',
  components: {
    BasePageBody,
    BasePageHeader,
    BasePageHeaderActions,
    BasePageTitle
  }
}
</script>
