<template>
  <h3 class="mb-5 text-2xl leading-6 font-medium text-theme-secondary capitalize-first">
    {{ $t('yourOrder') }}
  </h3>

  <div class="my-5 max-w-xl text-lg text-theme-primary capitalize-first">
    <p>{{ $t('pickup') }}</p>
  </div>

  <PickupRecap
    v-if="pickupType === 'agency'"
    :edition-mode="false"
  />

  <ShipmentStepOtherPickupForm
    v-else
    :edition-mode="false"
  />

  <div class="my-5 max-w-xl text-lg text-theme-primary capitalize-first">
    <p>{{ $t('delivery') }}</p>
  </div>

  <ShipmentStepDeliveryForm />

  <div class="my-5 max-w-xl text-lg text-theme-primary capitalize-first">
    <p>{{ $t('parcel') }}</p>
  </div>

  <ParcelsTable
    :parcel="shipment.parcel"
  />
  <div
    v-if="shipment.price"
    class="mb-3"
  >
    <div class="mt-5 max-w-xl text-lg text-theme-primary capitalize-first">
      <p>{{ $t('options') }}</p>
    </div>

    <BaseShowEditCheckbox
      v-model:modelValue="shipment.options"
      name="option"
      :options="metadata?.options"
      track-by="id"
      attribute-label="name"
      :edition-mode="false"
    />
  </div>

  <!--  <div class="mt-5 max-w-xl text-lg text-theme-primary capitalize-first">-->
  <!--    <p>{{ $t('instructions') }}</p>-->
  <!--  </div>-->

  <!--  <BaseShowLabel-->
  <!--    model-value="pam"-->
  <!--  />-->

  <div class="mt-5 max-w-xl text-lg text-theme-primary capitalize-first">
    {{ $t('deliveryType') }}
  </div>
  <div class="mt-5 text-sm text-gray-900">
    {{ displayedServiceValue }}
  </div>
  
  <div class="mt-5 flex justify-end">
    <div class="grid grid-cols-3 gap-y-2 text-right">
      <div class="col-span-2">
        <p
          v-if="shipment.volume_total"
          class="text-theme-secondary md:text-xl sm:text-xl font-bold capitalize-first"
        >
          {{ $t('totalVolume') }}
        </p>
        <p
          v-if="shipment.weight_total"
          class="text-theme-secondary md:text-xl sm:text-xl font-bold capitalize-first"
        >
          {{ $t('totalWeight') }}
        </p>
        <p class="text-theme-secondary md:text-3xl sm:text-xl font-bold capitalize-first">
          {{ $t('totalToPay') }}
        </p>
      </div>
      <div>
        <p
          v-if="shipment.volume_total"
          class="ml-2 text-theme-primary md:text-xl sm:text-xl font-bold"
        >
          {{ shipment.volume_total }} M³
        </p>
        <p
          v-if="shipment.weight_total"
          class="ml-2 text-theme-primary md:text-xl sm:text-xl font-bold"
        >
          {{ shipment.weight_total }} Kg
        </p>
        <p class="ml-2 text-theme-primary md:text-3xl sm:text-xl font-bold">
          {{ shipment.price }} €
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import {mapActions, mapGetters, mapMutations} from "vuex";

import ParcelsTable from "../../../../components/shipmentOrderRecap/ParcelsTable";
import PickupRecap from "../../../../components/shipmentOrderRecap/PickupRecap";
import ShipmentStepDeliveryForm from "./ShipmentStepDeliveryForm";
import BaseShowEditCheckbox from "../../../../components/forms/checkbox/BaseShowEditCheckbox";
import ShipmentStepOtherPickupForm from "./ShipmentStepOtherPickupForm";
// import BaseShowLabel from "../../../../components/forms/BaseShowLabel";

export default {
  name: "ShipmentStepOrderRecap",
  components: {
    // BaseShowLabel,
    ShipmentStepOtherPickupForm, ShipmentStepDeliveryForm, PickupRecap, ParcelsTable, BaseShowEditCheckbox },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata:'shipment/getMetadata',
      auth: 'auth/getAuth',
      pickupType: 'shipment/getPickupType',
      serviceCode: 'shipment/getServiceCode',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber',
    }),
    displayedServiceValue(){
      return this.metadata?.services_list?.find(option => option.id === this.serviceCode)?.name
    },
    userIsComingFromACorrectStep(){
      if(this.previousShipmentStepNumber !== null){
        if (this.previousShipmentStepNumber !== parseInt(this.$route.params.id)){
          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) - 1)){
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if(this.userIsComingFromACorrectStep){
      this.processFetchShipmentResumeAction({shipment: this.shipment})
      this.processSetPreviousShipmentStepNumberMutation(parseInt(this.$route.params.id))
    } else {
      if(this.previousShipmentStepNumber !== this.$route.params.id){
        this.previousShipmentStepNumber === null ? this.$router.push({name: 'create_shipment_route'}) : this.$router.push({name: '', params: {id: this.previousShipmentStepNumber}})
      }
    }
  },
  methods: {
    ...mapActions({processFetchShipmentResumeAction: 'shipment/fetchShipmentResume'}),
    ...mapMutations({processSetPreviousShipmentStepNumberMutation: 'shipment/setPreviousShipmentStepNumber'})
  }
}
</script>
