<template>
  <div class="grid md:grid-cols-5 sm:grid-cols-1">
    <div class="col-start-2 col-span-3 gap-3 flex flex-wrap">
      <h3
        v-if="$route.params.id !== '7'"
        class="mb-3 text-xl leading-6 font-medium text-theme-secondary capitalize-first border-b-2 border-theme-secondary"
      >
        {{ $t('deliveryAddress') }}
      </h3>
    </div>
  </div>
  <template v-if="$route.params.id !== '7' && thereIsAddressOptions">
    <div class="grid md:grid-cols-5 sm:grid-cols-1">
      <div class="col-start-2 col-span-3 gap-3">
        <div class="grid grid-cols-5 gap-x-2 mb-6">
          <div class="col-start-1 col-span-4">
            <BaseShowEditSelectSimple
              v-model="shipment.delivery_address_id"
              :label="$t('useYourAddresses')"
              name="user_delivery_adresses"
              :edition-mode="true"
              attribute-label="full_name_address"
              :options="deliveryOptions"
              :errors="errors?.addressId"
              @change="onSelectUserAddress"
            />
          </div>
          <div
            v-if="isPersonalAddressSelected"
            class="col-start-5 place-self-end md:w-20 sm:w-12"
          >
            <BaseButton icon="times" color="danger" @click="resetForm" />
          </div>
        </div>
      </div>
    </div>
    <hr />
  </template>
  <div
    class="grid md:grid-cols-2 sm:grid-cols-1 mt-5 md:w-3/5 container mx-auto gap-x-3"
  >
    <!--    <div class="md:col-start-2 md:col-span-3 sm:col-start-1 grid md:grid-cols-2 sm:grid-cols-1 md gap-x-3">-->
    <div class="order-1 md:order-1 lg:order-1 xl:order-1">
      <BaseShowEditInput
        v-model="shipment.name_receiver"
        :label="$t('fullname')"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors?.name_receiver"
        :tabindex="1"
      />
    </div>
    <div class="order-5 md:order-2 lg:order-2 xl:order-2">
      <BaseShowEditInput
        v-model="shipment.street_receiver"
        :label="$t('street')"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors?.street_receiver"
        :tabindex="5"
      />
    </div>
    <div class="order-4 md:order-3 lg:order-3 xl:order-3">
      <BaseShowEditInput
        v-model="shipment.phone_receiver"
        :label="$t('phone')"
        :edition-mode="editionMode"
        :errors="errors?.phone_receiver"
        :tabindex="2"
      />
    </div>
    <div class="order-6 md:order-4 lg:order-4 xl:order-4">
      <BaseCityShowEditSelectAjax
        v-if="$route.params.id === '3'"
        :key="cityReceiverBaseValue"
        v-model="shipment.city_receiver_id"
        :edition-mode="editionMode"
        url="api/c2c/select2/ville"
        :label="$t('city')"
        name="receiver_city_id"
        track-by="id"
        attribute-label="text"
        :query-country="shipment.country_receiver || 'BE'"
        :required="true"
        :default-ajax="cityReceiverBaseValue"
        :errors="cityErrors"
        :tabindex="6"
      />
      <BaseShowEditInput
        v-else
        v-model="shipment.city_receiver"
        :label="$t('city')"
        :edition-mode="editionMode"
        :errors="errors?.city_receiver"
        :tabindex="6"
      />
    </div>
    <div class="order-4 md:order-5 lg:order-5 xl:order-5">
      <BaseShowEditInput
        v-model="shipment.mobile_receiver"
        :label="$t('mobile')"
        :required="true"
        :edition-mode="editionMode"
        :errors="errors?.mobile_receiver"
        :tabindex="3"
      />
    </div>
    <div class="order-7 md:order-6 lg:order-6 xl:order-6">
      <BaseShowEditSelectSimple
        v-model="shipment.country_receiver"
        :label="$t('country')"
        name="country_receiver"
        :edition-mode="editionMode"
        :options="
          metadata.countries_delivery.filter((item) => item.id === 'BE')
        "
        :required="true"
        :errors="errors?.country_receiver"
        :tabindex="7"
      />
    </div>
    <div class="order-3 md:order-7 lg:order-7 xl:order-7">
      <BaseShowEditInput
        v-model="shipment.email_receiver"
        :label="$t('eMail')"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors?.email_receiver"
        :tabindex="4"
      />
    </div>
    <div class="order-last md:order-last lg:order-last xl:order-last">
      <BaseShowEditSelectSimple
        v-model="shipment.language_receiver"
        :label="$t('language')"
        name="language_receiver"
        :edition-mode="editionMode"
        :options="metadata.languages"
        :required="true"
        :errors="errors?.language_receiver"
        :tabindex="8"
      />
    </div>
  </div>
  <!--  </div>-->
  <div class="grid md:grid-cols-5 sm:grid-cols-1">
    <div
      class="grid md:col-start-2 md:col-span-3 md:grid-cols-2 sm:grid-cols-1 mt-5 md:gap-x-3"
    >
      <div>
        <BaseShowEditInput
          v-model="shipment.internal_ref"
          :label="$t('reference')"
          :edition-mode="editionMode"
          :errors="errors.internal_ref ? errors.internal_ref : null"
          :tabindex="9"
        />
      </div>
      <div>
        <BaseShowEditTextArea
          v-if="editionMode"
          v-model="shipment.delivery_simple_instructions"
          :label="$t('deliveryInstruction')"
          :errors="
            errors.delivery_simple_instructions
              ? errors.delivery_simple_instructions
              : null
          "
          :edition-mode="editionMode"
          :tabindex="10"
        />
        <BaseShowEditTextArea
          v-else
          v-model="shipment.delivery_instructions"
          :label="$t('deliveryInstruction')"
          :is-html="true"
          :errors="
            errors.delivery_instructions ? errors.delivery_instructions : null
          "
          :edition-mode="editionMode"
          :tabindex="10"
        />
      </div>
      <div v-if="false">
        <BaseFileInput
          v-if="$route.params.id !== '7'"
          v-model="deliveryFile"
          :label="$t('joinAFile')"
          @change="setFileToStore"
        />
      </div>
      <div v-if="$route.params.id === '7'">
        <BaseShowLabel
          :label="$t('deliveryDate')"
          :model-value="shipment.delivery_date"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations } from 'vuex'

import BaseCityShowEditSelectAjax from '../../../../components/forms/selects/ajax/BaseCityShowEditSelectAjax'
import BaseShowLabel from '../../../../components/forms/BaseShowLabel'
import BaseFileInput from '../../../../components/forms/inputs/BaseFileInput'
import BaseShowEditInput from '../../../../components/forms/inputs/BaseShowEditInput'
import BaseShowEditSelectSimple from '../../../../components/forms/selects/simple/BaseShowEditSelectSimple'
import BaseShowEditTextArea from '../../../../components/forms/inputs/BaseShowEditTextArea'
import BaseButton from '../../../../components/BaseButton'

export default {
  name: 'ShipmentStepDeliveryForm',
  components: {
    BaseButton,
    BaseCityShowEditSelectAjax,
    BaseFileInput,
    BaseShowEditInput,
    BaseShowEditSelectSimple,
    BaseShowEditTextArea,
    BaseShowLabel
  },
  props: {
    editionMode: { type: Boolean, required: false, default: false },
    isUsedAsMainDisplayedComponent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPersonalAddressSelected: false,
      city: null
    }
  },
  computed: {
    ...mapGetters({
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getMetadata',
      errors: 'auth/getErrors',
      previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber',
      deliveryFile: 'shipment/getDeliveryFile'
    }),
    cityErrors() {
      return {
        ...this.errors.city_receiver,
        ...this.errors.city_receiver_id
      }
    },
    thereIsAddressOptions() {
      return this.deliveryOptions.length > 0
    },
    deliveryOptions() {
      if (!this.metadata?.user_adresses?.length > 0) {
        return []
      }
      if (this.shipment?.pickup_address_id) {
        return this.metadata?.user_adresses?.filter(
          (address) => address.id !== this.shipment?.pickup_address_id
        )
      }
      return this.metadata?.user_adresses
    },
    cityReceiverBaseValue() {
      if (this.city) {
        return { id: this.city, text: `${this.city}` }
      } else if (this.shipment.city_receiver?.length) {
        return {
          id: this.shipment.city_receiver_id,
          text: `${this.shipment.city_receiver}`
        }
      } else {
        return {}
      }
    },
    userIsComingFromACorrectStep() {
      if (this.previousShipmentStepNumber !== null) {
        if (
          this.previousShipmentStepNumber !== parseInt(this.$route.params.id)
        ) {
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) - 1
          ) {
            return true
          }
          if (
            this.previousShipmentStepNumber ===
            parseInt(this.$route.params.id) + 1
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if (this.isUsedAsMainDisplayedComponent) {
      if (this.userIsComingFromACorrectStep) {
        this.processSetPreviousShipmentStepNumberMutation(
          parseInt(this.$route.params.id)
        )
      } else {
        if (this.previousShipmentStepNumber !== this.$route.params.id) {
          this.previousShipmentStepNumber === null
            ? this.$router.push({ name: 'create_shipment_route' })
            : this.$router.push({
                name: '',
                params: { id: this.previousShipmentStepNumber }
              })
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      processSetPreviousShipmentStepNumberMutation:
        'shipment/setPreviousShipmentStepNumber',
      processSetDeliveryFileMutation: 'shipment/setDeliveryFile',
      processResetDeliveryFormMutation: 'shipment/resetDeliveryForm',
      processFillDeliveryFormMutation: 'shipment/fillDeliveryForm',
      processSetDeliveryAddressIdMutation: 'shipment/setDeliveryAddressId',
      processResetDeliveryAddressIdMutation: 'shipment/resetDeliveryAddressId'
    }),
    onSelectUserAddress(payload) {
      if (!payload) {
        this.resetForm()
        return
      }
      this.isPersonalAddressSelected = true
      this.city = payload.city

      this.processSetDeliveryAddressIdMutation(
        this.shipment.delivery_address_id
      )
      this.processFillDeliveryFormMutation(payload)
    },
    resetForm() {
      this.isPersonalAddressSelected = false
      this.shipment.delivery_address_id = null
      this.city = null

      this.processResetDeliveryAddressIdMutation()
      this.processResetDeliveryFormMutation()
    },
    setFileToStore(event) {
      console.log(event.target.files[0])
      this.processSetDeliveryFileMutation(event.target.files[0])
    }
  }
}
</script>
