<!-- eslint-disable -->
<template>
  <form
    @submit.prevent="attemptLogin"
    class="bg-white border rounded p-5 shadow-xl flex flex-col gap-8 my-5 sm:my-0"
  >
    <div>
      <img
        class="mx-auto w-auto"
        src="@/assets/images/home_del_logo_300_rgb.png"
        alt="MyHomeDelivery"
      />
    </div>

    <!--#region unverifiedErrorMessage -->
    <div v-if="unverifiedErrorMessage" class="rounded-md bg-red-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0 text-red-700">
          <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            {{ $t('needEmailVerification') }}
          </h3>
          <h6 class="text-xs font-light text-red-800">
            {{ $t('verrifySpam') }}
          </h6>
          <a
            class="text-xs font-light text-blue-500 capitalize-first cursor-pointer"
            @click="handleResendEmail"
            >{{ $t('sendMailAgain') }}</a
          >
        </div>
      </div>
    </div>
    <!--#endregion -->

    <!--#region errors.error -->
    <div v-if="errors.error" class="rounded-md bg-red-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0 text-red-700">
          <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            {{ errors.error }}
          </h3>
        </div>
      </div>
    </div>
    <!--#endregion -->

    <!--#region resendVerifiedMessage -->
    <div v-if="resendVerifiedMessage" class="rounded-md bg-green-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0 text-green-700">
          <font-awesome-icon :icon="['fas', 'check-circle']" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            {{ $t('resendVerifiedMessage') }}
          </h3>
          <h6 class="text-xs font-light text-green-800">
            {{ $t('verrifySpam') }}
          </h6>
        </div>
      </div>
    </div>
    <!--#endregion -->
    <div>
      <div>
        <BaseShowEditInput
          v-model="username"
          :label="$t('userName')"
          :edition-mode="true"
        />
      </div>

      <div>
        <BaseShowEditInput
          v-model="password"
          :label="$t('password')"
          :edition-mode="true"
          type="password"
        />
      </div>
    </div>

    <div>
      <BaseButton :title="$t('signIn')" scoped-classes="w-full" />
    </div>

    <div class="flex flex-col text-sm gap-2">
      <div v-if="errors.error" class="flex gap-2">
        <span>{{ $t('forget') }}</span>
        <a
          :href="`${baseURL}password/reset`"
          class="text-muted"
          target="_blank"
        >
          {{ $t('reset') }}
        </a>
      </div>
      <div class="flex gap-2">
        <span>{{ $t('not_yet_an_account') }}</span>
        <router-link
          :to="{ name: 'auth_register_route' }"
          class="font-medium text-green-800 hover:text-green-600"
        >
          {{ $t('signUp') }}
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
/* eslint-disable */
import BaseButton from '../../components/BaseButton'
import BaseShowEditInput from '../../components/forms/inputs/BaseShowEditInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheLoginPage',
  components: { BaseButton, BaseShowEditInput },
  data() {
    return {
      username: null,
      password: null,
      unverifiedErrorMessage: false,
      resendVerifiedMessage: false
    }
  },
  computed: {
    ...mapGetters({ errors: 'auth/getErrors' }),
    baseURL() {
      if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8080/'
      } else {
        return 'https://api-c2c.my-home-delivery.com/'
      }
    }
  },
  methods: {
    ...mapActions({
      processLoginAction: 'auth/logIn',
      processResendVerificationEmailAction: 'auth/resendVerificationEmail'
    }),

    attemptLogin() {
      this.processLoginAction({
        username: this.username,
        password: this.password,
        successCallback: this.redirectToMainPage,
        unVerifiedCallback: this.showUnverifiedErrorMessage
      })
    },

    redirectToMainPage() {
      this.$router.push({ name: 'homepage_route' })
    },

    showUnverifiedErrorMessage() {
      this.unverifiedErrorMessage = true
    },

    handleResendEmail() {
      this.processResendVerificationEmailAction({
        username: this.username,
        successCallback: this.showResendVerifiedMessage
      })
    },
    showResendVerifiedMessage() {
      this.unverifiedErrorMessage = false
      this.resendVerifiedMessage = true
    }
  }
}
</script>
