<template>
  <BasePageFull>
    <!--#region Body -->
    <template #body>
      <div
        class="flex-1 p-6 bg-white overflow-hidden shadow rounded-lg flex flex-col gap-6"
      >
        <FaqNl v-if="$i18n.locale === 'nl_BE'" />
        <FaqEn v-if="$i18n.locale === 'en_UK'" />
        <FaqFr v-else />
      </div>
    </template>
    <!--#endregion -->
  </BasePageFull>
</template>

<script>
import { mapGetters } from 'vuex'

import BasePageFull from '../../components/baseComponents/BasePageFull'
import FaqFr from '@/views/faq/FaqFr.vue'
import FaqNl from '@/views/faq/FaqNl.vue'
import FaqEn from '@/views/faq/FaqEn.vue'

export default {
  name: 'TheHomePage',
  components: { FaqEn, FaqNl, FaqFr, BasePageFull },
  computed: {
    ...mapGetters({
      shipmentsIndex: 'shipment/getShipmentsIndex',
      shipment: 'shipment/getShipment',
      metadata: 'shipment/getIndexMetadata'
    })
  }
}
</script>
