<template>
  <div class="grid md:grid-cols-3 sm:grid-cols-1 my-10">
    <div class="md:col-start-2 sm:col-start-1">
      <h3 class="mb-10 text-xl leading-6 font-medium text-theme-secondary capitalize">
        {{ $t('thanksForYourOrder') }}
      </h3>
    </div>
    <div class="md:col-start-2 sm:col-start-1">
      <p class="mb-10 text-sm leading-6 font-medium text-gray-500">
        {{ $t('afterOrderInstruction') }}
      </p>
      <p class="mb-10 text-sm leading-6 font-medium text-gray-500">
        {{ $t('afterOrderInstructionTwo') }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

history.pushState(null, null, location.href);
window.onpopstate = function () {
  history.go(1);
};

export default {
  name: "ShipmentStepMessage",
  computed: {
    ...mapGetters({previousShipmentStepNumber: 'shipment/getPreviousShipmentStepNumber'}),
    userIsComingFromACorrectStep(){
      if(this.previousShipmentStepNumber !== null){
        if (this.previousShipmentStepNumber !== parseInt(this.$route.params.id)){
          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) - 1)){
            return true
          }
          if (this.previousShipmentStepNumber === (parseInt(this.$route.params.id) + 1)){
            return true
          }
          return false
        }
        return false
      }
      return false
    }
  },
  mounted() {
    if(this.userIsComingFromACorrectStep){
      this.processSetPreviousShipmentStepNumberMutation(parseInt(this.$route.params.id))
    } else {
      if(this.previousShipmentStepNumber !== this.$route.params.id){
        this.previousShipmentStepNumber === null ? this.$router.push({name: 'create_shipment_route'}) : this.$router.push({name: '', params: {id: this.previousShipmentStepNumber}})
      }
    }
  },
  methods: {
    ...mapMutations({processSetPreviousShipmentStepNumberMutation: 'shipment/setPreviousShipmentStepNumber'}),
  }
}
</script>
