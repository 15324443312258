<template>
  <div class="flex-1 flex flex-col min-h-0 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
    <div class="flex-shrink-0 px-4 py-5 sm:px-6">
      <slot name="header" />
      <!-- Content goes here -->
      <!-- We use less vertical padding on card headers on desktop than on body sections -->
    </div>
    <div class="flex-1 overflow-scroll px-4 py-5 sm:p-6">
      <!-- Content goes here -->
      <slot name="body" />
    </div>
    <div class="flex-shrink-0 px-4 py-4 sm:px-6">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'WizarFormStepComponent'
}
</script>
